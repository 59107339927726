import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import $ from "jquery";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../../common/common";
import Loader from "react-js-loader";
import '../../common/components/css/style.css';
import avatar1 from "./images/avatar1.png";
import { Dropdown } from 'flowbite-react';
import "../../index.css";
import { HiCheck } from "react-icons/hi";
import Snackbar from '@mui/material/Snackbar';
import ListItemIcon from '@mui/material/ListItemIcon';
import SuccessIcon from "./images/success.svg";
import ErrorIcon from "./images/error.svg";
import SplImg from "./images/spl.png";
import ImportComponent from "../Import/Import";
import cross1 from "./images/cross1.svg";
import leave1 from "./images/leave1.svg";
import upar from "./images/upar.svg";
import ques from "./images/ques.svg";
import de from "./images/de.svg";
import gift from "./images/gift.svg";
import { WindmillSpinner } from 'react-spinner-overlay';
import moment from 'moment';
import validator from "validator";
import { DatePicker } from "antd";
import dayjs from 'dayjs';
import editIcon from "./images/edit-icon.svg";
import deleteIcon from "./images/delete-icon.svg";
import rigar from "./images/rigar.svg";
import lefar from "./images/lefar.svg";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { Popover, Box, Zoom, Paper } from '@mui/material';
import Sidebar from "../../common/layouts/CommonSidebar.jsx";
import styled from 'styled-components';
import attpopup from "./images/attpopup.svg";
import { LogoComponent } from "../school/Common.jsx";
import CommonSidebarHeader from "../../common/layouts/CommonSidebarHeader.jsx";

export default function Food() {

    const navigate = useNavigate();

    const [programsData, setProgramsData] = useState({});
    const [programsLoading, setProgramsLoading] = useState(false);

    const fetchProgramsData = async () => {
        setProgramsLoading(false);
        try {
            const postData = {
                school_id: localStorage.getItem('schoolId')
                // school_id:24
            }
            const { data } = await axios.post(baseUrl + "/api/get_programs", postData, {
                headers: {
                    "Authorization": localStorage.getItem('access_token')
                }
            });
            setProgramsData(data.data);
            setProgramsLoading(true);
        } catch (error) {
            console.log(error)
        }
    }

    const [foodData, setFoodData] = useState({});
    const [dataHeader, setDataHeader] = useState([]);
    const [foodHeaderdata, setFoodHeaderData] = useState([]);
    const [foodLoading, setFoodLoading] = useState(false);

    const [filterValue, setFilterValue] = useState('');
    const handleInputChange = async (event) => {
        setFilterValue(event.target.value);
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/student_list_admin",
                data: {
                    school_id: localStorage.getItem('schoolId'),
                    programs_id: localStorage.getItem('programId'),
                    search: event.target.value.trim(),
                    list_status: 3,
                    previous_week: curruntWeek
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response['data']['status'] === true) {
                    setFoodData(response.data);
                    setDataHeader(response.data.dateresult);
                    setFoodHeaderData(response.data.data);
                    setFoodLoading(true);
                } else {
                    setFoodData(response.data);
                    setFoodLoading(true);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    /* 31-07-2024 */
    const clearInput = async () => {
        setFilterValue('');
        fetchFoodData(curruntWeek);
    };

    const [curruntWeek, setCurruntWeek] = useState(0);
    const fetchFoodData = (week) => {
        setFoodLoading(false);
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/student_list_admin",
                data: {
                    school_id: localStorage.getItem('schoolId'),
                    programs_id: localStorage.getItem('programId'),
                    search: "",
                    list_status: 3,
                    previous_week: week
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response['data']['status'] === true) {
                    setFoodData(response.data);
                    setDataHeader(response.data.dateresult);
                    setFoodHeaderData(response.data.data);
                    setFoodLoading(true);
                } else {
                    setFoodData(response.data);
                    setStudentCount(false);
                    setFoodLoading(true);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };


    const [addFoodPopupVisible, setAddFoodPopupVisible] = useState(false);
    const openAddFoodPopup = (foodData, event, index) => {
        // setAnchorEl(anchorEl ? null : event.currentTarget);
        setAnchorEl(event.currentTarget);
        setOpenPopoverIndex(index);
        // setAddFoodPopupVisible(true);
        setStudentId(foodData.student_id);
        setFoodDate(foodData.formattedDate);
        setFoodId(null);
        setFoodTimeError(null);
        setQuantityError(null);
        setFoodTypeError(null);
        setCategoryError(null);
        setDescriptionError(null);
        setBorderColor(initialBorderColor);
        setFoodDetails({
            foodTime: '',
            quantity: '',
            foodType: '',
            category: '',
            description: ''
        });
    }

    const closeAddFoodPopup = () => {
        // setAddFoodPopupVisible(false);
        setAnchorEl(null);
        setOpenPopoverIndex(null);
        // setAnchorEl(anchorEl ? null : event.currentTarget);
    }
    const openUpdateFoodPopup = (foodData, event, index) => {
        // setAnchorEl(anchorEl ? null : event.currentTarget);
        setAnchorEl(event.currentTarget);
        setOpenPopoverIndex(index);
        // setAddFoodPopupVisible(true)
        setFoodId(foodData.id)
        setStudentId(foodData.student_id)
        setFoodDate(foodData.formattedDate)
        console.log(foodData)
        setFoodDetails({
            foodTime: foodData.food_time_id,
            quantity: foodData.quantity_id,
            foodType: foodData.type_id,
            category: foodData.category_id,
            description: foodData.message
        })
        setFoodTimeError(null);
        setQuantityError(null);
        setFoodTypeError(null);
        setCategoryError(null);
        setDescriptionError(null);
        setBorderColor(initialBorderColor);
    }

    const [foodDetails, setFoodDetails] = useState({
        foodTime: '',
        quantity: '',
        foodType: '',
        category: '',
        description: 'You children finish the Lunch.'
    })

    const [foodId, setFoodId] = useState(null);
    const [studentId, setStudentId] = useState("");
    const [foodDate, setFoodDate] = useState("");
    // const [quantity, setQuantity] = useState("");
    // const [foodType, setFoodType] = useState("");    
    // const [category, setCategory] = useState("");    
    // const [description, setDescription] = useState("");

    const [foodTimeError, setFoodTimeError] = useState(null);
    const [quantityError, setQuantityError] = useState(null);
    const [foodTypeError, setFoodTypeError] = useState(null);
    const [categoryError, setCategoryError] = useState(null);
    const [descriptionError, setDescriptionError] = useState(null);

    const validateForm = () => {
        let valid = true;
        if (foodDetails.foodTime === "") {
            setFoodTimeError("Please Select Food Time");
            valid = false;
        } else {
            setFoodTimeError(null);
        }
        if (foodDetails.quantity === "") {
            setQuantityError("Please Select Quantity");
            valid = false;
        } else {
            setQuantityError(null);
        }
        if (foodDetails.foodType === "") {
            setFoodTypeError("Please Select Food Type");
            valid = false;
        } else {
            setFoodTypeError(null);
        }
        if (foodDetails.category === "") {
            setCategoryError("Please Select Category");
            valid = false;
        } else {
            setCategoryError(null);
        }
        // if (foodDetails.description === "") {
        //     setDescriptionError("Please Enter Description");
        //     valid = false;
        // } else {
        //     setDescriptionError(null);
        // }
        return valid;
    };

    const addFood = () => {
        if (validateForm()) {
            const postData = {
                school_id: localStorage.getItem('schoolId'),
                staff_id: localStorage.getItem('loginUserId'),
                food_date: foodDate,
                programs_id: localStorage.getItem('programId'),
                student_ids: [studentId],
                food_time_id: foodDetails.foodTime,
                type_id: foodDetails.foodType,
                quantity_id: foodDetails.quantity,
                category_id: foodDetails.category,
                message: foodDetails.description,
                created_by: localStorage.getItem('loginUserId')
            };
            console.log(postData);
            axios({
                method: "post",
                url: baseUrl + "/api/food_add",
                data: postData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    setSnackbarMessage(response.data.message);
                    setMessageType('success');
                    handleSnackbarOpen();
                    // setTimeout(function () {
                    //     window.location.reload();
                    // }, 500);
                    closeAddFoodPopup();
                    fetchFoodData(0);

                } else {
                    setSnackbarMessage(response.data.message);
                    setMessageType('error');
                    handleSnackbarOpen();
                }
            });
        }
    };

    const updateFood = () => {
        const postData = {
            id: foodId,
            school_id: localStorage.getItem('schoolId'),
            staff_id: localStorage.getItem('loginUserId'),
            food_date: foodDate,
            programs_id: localStorage.getItem('programId'),
            student_id: studentId,
            food_time_id: foodDetails.foodTime,
            type_id: foodDetails.foodType,
            quantity_id: foodDetails.quantity,
            category_id: foodDetails.category,
            message: foodDetails.description,
            updated_by: localStorage.getItem('loginUserId')
        }
        console.log(postData)
        axios({
            method: "post",
            url: baseUrl + "/api/food_edit",
            data: postData,
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setSnackbarMessage(response.data.message);
                setMessageType('success');
                handleSnackbarOpen();
                // setTimeout(function () {
                //     window.location.reload();
                // }, 500);
                closeAddFoodPopup();
                fetchFoodData(0);
            } else {
                setSnackbarMessage(response.data.message);
                setMessageType('error');
                handleSnackbarOpen();
            }
        });

    }

    const [quantityLoading, setQuantityLoading] = useState(false);
    const [quantityData, setQuantityData] = useState([]);
    const fetchQuantityData = async () => {
        setQuantityLoading(false);
        axios({
            method: "post",
            url: baseUrl + "/api/get_food_quantity",
            // data:{},
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setQuantityData(response.data.data);
                console.log(response);
                setQuantityLoading(true);
            }
        });
    }

    const [foodTimeLoading, setFoodTimeLoading] = useState(false);
    const [foodTimeData, setFoodTimeData] = useState([]);
    const fetchFoodTimeData = async () => {
        setFoodTimeLoading(false);
        axios({
            method: "post",
            url: baseUrl + "/api/get_time",
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setFoodTimeData(response.data.data);
                console.log(response);
                setFoodTimeLoading(true);
            }
        });
    }

    const [foodTypeLoading, setFoodTypeLoading] = useState(false);
    const [foodTypeData, setFoodTypeData] = useState([]);
    const fetchFoodTypeData = async () => {
        setFoodTypeLoading(false);
        axios({
            method: "post",
            url: baseUrl + "/api/get_food_type",
            // data:{},
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setFoodTypeData(response.data.data);
                console.log(response);
                setFoodTypeLoading(true);
            }
        });
    }

    const [foodCategoryLoading, setFoodCategoryLoading] = useState(false);
    const [foodCategoryData, setFoodCategoryData] = useState([]);
    const fetchFoodCategoryData = async () => {
        setFoodCategoryLoading(false);
        axios({
            method: "post",
            url: baseUrl + "/api/get_food_category",
            // data:{},
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setFoodCategoryData(response.data.data);
                console.log(response);
                setFoodCategoryLoading(true);
            }
        });
    }

    const selectedQuantity = quantityData.find(data => data.id === foodDetails.quantity);
    const selectedFoodTime = foodTimeData.find(data => data.id === foodDetails.foodTime);
    const selectedFoodType = foodTypeData.find(data => data.id === foodDetails.foodType);
    const selectedCategory = foodCategoryData.find(data => data.id === foodDetails.category);

    // ------11-06----------------
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [snackbarState, setSnackbarState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center'
    });
    const handleSnackbarClose = () => {
        setSnackbarState({ ...snackbarState, open: false });
    };
    const handleSnackbarOpen = () => {
        setSnackbarState({ ...snackbarState, open: true });
    };
    useEffect(() => { }, [snackbarMessage, messageType]);

    // ---------13-06----------------
    const [studentCount, setStudentCount] = useState(true);

    // -------------------------19-06-------------------------

    // const handleOpenNewProgramPopupVisible = () => {
    //     setProgramNameError(null);
    //     setProgramTeacherError(null);
    //     setTimeError(null);
    //     setTeacher("");
    //     setProgramStartTime("");
    //     setProgramEndTime("");
    //     setNewProgramPopupVisible(true);

    const [newProgramAddUpdateStatus, setNewProgramAddUpdateStatus] = useState("");
    const handleOpenNewProgramPopupVisible = (status) => {
        setNewProgramAddUpdateStatus(status);
        if (status === "add") {
            setProgramNameError(null);
            setProgramTeacherError(null);
            setTimeError(null);
            resetFeaturesAndAdditionalInfo();
            setTeacher("");
            setNewProgramName("");
            setProgramStartTime("");
            setProgramEndTime("");
            setEditProgramVisible(false);
            setNewProgramPopupVisible(true);
        } else {
            fetchEditProgramDetails();
        }

    }

    const fetchEditProgramDetails = async () => {
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/get_programs",
                data: {
                    school_id: localStorage.getItem("schoolId"),
                    id: localStorage.getItem('programId')
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    const programData = response.data.data[0];
                    setTeacher(response.data.teacher);
                    setNewProgramName(programData.program_name);
                    setProgramStartTime(programData.from_time_id);
                    setProgramEndTime(programData.to_time_id);
                    if (programData.features !== null && programData.additional_info !== null) {
                        // Set features
                        const featureIds = programData.features.split(',');
                        const updatedFeatures = { ...features };
                        featureIds.forEach(id => {
                            const featureKey = Object.keys(features).find(key => features[key].value.toString() === id);
                            if (featureKey) {
                                updatedFeatures[featureKey].status = true;
                            }
                        });
                        setFeatures(updatedFeatures);
                        // Set additional info
                        const additionalInfoIds = programData.additional_info.split(',');
                        const updatedAdditionalInfo = { ...additionalInfo };
                        additionalInfoIds.forEach(id => {
                            const infoKey = Object.keys(additionalInfo).find(key => additionalInfo[key].value.toString() === id);
                            if (infoKey) {
                                updatedAdditionalInfo[infoKey].status = true;
                            }
                        });
                        setAdditionalInfo(updatedAdditionalInfo);
                    }
                    setProgramNameError(null);
                    setProgramTeacherError(null);
                    setTimeError(null);
                    $('.overlay').addClass('visible');
                    $('.popup-program-container').addClass('visible');
                    setEditProgramVisible(false);
                    setNewProgramPopupVisible(true);
                }
            });
        } catch (error) {
            console.error('An error occurred while fetching program details:', error);
            // Optionally, you can set a general error message to state here
        }
    }

    // -------------------31-08--------------

    const [deleteProgramConfirmVisible, setDeleteProgramConfirmVisible] = useState(false);

    const handleDeleteProgramConfirmClose = () => {
        setDeleteProgramConfirmVisible(false);
    };

    const handleDeleteProgramConfirm = () => {
        // setCheckProgramChangeStatus(true);
        setDeleteProgramConfirmVisible(false);
        handleRecycleBin();
    };

    const [newProgramPopupVisible, setNewProgramPopupVisible] = useState(false);
    const [programStartTime, setProgramStartTime] = useState("");
    const [programEndTime, setProgramEndTime] = useState("");
    const [programTeacher, setTeacher] = useState("");
    const [timeData, setTimeData] = useState([]);
    const [teachersData, setTeachersData] = useState([]);
    const selectedStartTime = timeData.find(data => data.id === programStartTime);
    const selectedEndTime = timeData.find(data => data.id === programEndTime);
    const selectedTeachers = teachersData.find(data => data.id === programTeacher);

    const fetchProgramTimeData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/get_time",
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setTimeData(response.data.data);
                console.log(response);
            }
        });
    }

    const fetchTeachersData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/teacher_list",
            data: {
                school_id: localStorage.getItem("schoolId")
            },
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setTeachersData(response.data.data);
                console.log(response);
            }
        });
    }

    const [newProgramName, setNewProgramName] = useState('');
    const [features, setFeatures] = useState({
        qrCode: { status: true, name: 'QR Code', value: 2 },
        attendance: { status: true, name: 'Attendance', value: 5 },
        food: { status: true, name: 'Food', value: 3 },
        sleep: { status: true, name: 'Sleep', value: 4 },
        leaves: { status: true, name: 'Leaves', value: 6 },
        busTracking: { status: false, name: 'Bus Tracking', value: 7 },
        cctv: { status: false, name: 'CCTV', value: 8 },
        fees: { status: false, name: 'Fees', value: 9 },
    });
    const [additionalInfo, setAdditionalInfo] = useState({
        inviteParents: {
            status: true,
            name: 'Automatically Invite Parents as soon as student profile created',
            value: 1
        },
        // addParentChat: {
        //     status: true,
        //     name: 'Add Parent in chat as soon as student profile created',
        //     value: 2
        // },
        // createStudentID: {
        //     status: true,
        //     name: 'Automatically create Student ID as soon as profile created',
        //     value: 3
        // },
    });

    const resetFeaturesAndAdditionalInfo = () => {
        setFeatures({
            qrCode: { status: true, name: 'QR Code', value: 2 },
            attendance: { status: true, name: 'Attendance', value: 5 },
            food: { status: true, name: 'Food', value: 3 },
            sleep: { status: true, name: 'Sleep', value: 4 },
            leaves: { status: true, name: 'Leaves', value: 6 },
            busTracking: { status: false, name: 'Bus Tracking', value: 7 },
            cctv: { status: false, name: 'CCTV', value: 8 },
            fees: { status: false, name: 'Fees', value: 9 }
        });
        setAdditionalInfo({
            inviteParents: {
                status: true,
                name: 'Automatically Invite Parents as soon as student profile created',
                value: 1
            },
            // addParentChat: {
            //     status: true,
            //     name: 'Add Parent in chat as soon as student profile created',
            //     value: 2
            // },
            // createStudentID: {
            //     status: true,
            //     name: 'Automatically create Student ID as soon as profile created',
            //     value: 3
            // }
        })
    }

    const handleFeatureChange = (feature) => {
        setFeatures({
            ...features,
            [feature]: {
                ...features[feature],
                status: !features[feature].status,
            },
        });
    };
    const handleAdditionalInfoChange = (info) => {
        setAdditionalInfo({
            ...additionalInfo,
            [info]: {
                ...additionalInfo[info],
                status: !additionalInfo[info].status,
            },
        });
    };

    const [programeNameError, setProgramNameError] = useState(null);
    const [programeTeacherError, setProgramTeacherError] = useState(null);
    const [timeError, setTimeError] = useState(null);
    const validateProgramForm = () => {
        let valid = true;
        if (newProgramName === "") {
            setProgramNameError("Please enter program name");
            valid = false;
        } else {
            setProgramNameError(null);
        }
        if (programTeacher === "") {
            setProgramTeacherError("Please select program teacher");
            valid = false;
        } else {
            setProgramTeacherError(null);
        }
        // if (programStartTime === "" || programEndTime === "") {
        //   setTimeError("Please select start and end time");
        //   valid = false;
        // } else {
        //   setTimeError(null);
        // }
        return valid;
    };

    const handleCreateProgram = () => {
        const selectedFeatureValues = Object.keys(features)
            .filter((key) => features[key].status)
            .map((key) => features[key].value);

        const selectedAdditionalInfoValues = Object.keys(additionalInfo)
            .filter((key) => additionalInfo[key].status)
            .map((key) => additionalInfo[key].value);

        const programData = {
            school_id: localStorage.getItem("schoolId"),
            program_name: newProgramName,
            teacher_id: programTeacher,
            from_time_id: programStartTime,
            to_time_id: programEndTime,
            features: selectedFeatureValues.join(","),
            additional_info: selectedAdditionalInfoValues.join(","),
            status: 1,
            [newProgramAddUpdateStatus === "add" ? "created_by" : "updated_by"]: localStorage.getItem("loginUserId"),
            ...(newProgramAddUpdateStatus !== "add" && { id: localStorage.getItem("programId") })
        };
        console.log(programData);
        if (validateProgramForm()) {
            axios({
                method: "post",
                url: `${baseUrl}/api/programs_add_edit`,
                data: programData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response.data.status) {
                    $('.overlay').removeClass('visible');
                    $('.popup-program-container').removeClass('visible');
                    setNewProgramPopupVisible(false);
                    fetchProgramsData();
                    setSnackbarMessage(response.data.message);
                    setMessageType('success');
                    handleSnackbarOpen();
                    if (newProgramAddUpdateStatus === "edit") {
                        localStorage.setItem("programName", newProgramName);
                    }
                } else {
                    setSnackbarMessage(response.data.message);
                    setMessageType('error');
                    handleSnackbarOpen();
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    };
    const initialBorderColor = {
        timeBorder: "",
        quantityBorder: "",
        typeBorder: "",
        categoryBorder: ""
    };

    const [borderColor, setBorderColor] = useState(initialBorderColor);

    const handleFocus = (borderColor) => {
        if (borderColor === "timeBorder") {
            setBorderColor({ ...borderColor, timeBorder: "#4D6EE3" });
        } else if (borderColor === "quantityBorder") {
            setBorderColor({ ...borderColor, quantityBorder: "#4D6EE3" });
        } else if (borderColor === "typeBorder") {
            setBorderColor({ ...borderColor, typeBorder: "#4D6EE3" });
        } else if (borderColor === "categoryBorder") {
            setBorderColor({ ...borderColor, categoryBorder: "#4D6EE3" });
        }
    };

    const handleBlur = () => {
        setBorderColor(initialBorderColor);
    };
    const [progress, setProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [importstatus, setimportstatus] = useState(false);

    const importStudents1 = () => {
        setimportstatus(true);
    };

    const handleImportStatus = (status) => {
        if (status == true) {
            setimportstatus(false);
        }
    };
    const [isFocused, setIsFocused] = useState(false);
    // 
    const foodInputRef = useRef(null);
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside1);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside1);
        };
    }, []);
    const handleClickOutside1 = (event) => {
        if (foodInputRef.current && !foodInputRef.current.contains(event.target)) {
            setBorderColor(initialBorderColor);
        }
    };

    // ----- 04-07  active date----------
    const inputDateObj = moment("Thu Jul 04 2024", 'ddd MMM DD YYYY').toDate();
    const currentDateObj = new Date();
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const formattedCurrentDate = formatDate(currentDateObj);


    // -------------------------06-07------------------------------------
    // ----------add new student----------------

    const formData = new FormData();

    const gender1 = [
        {
            id: 1,
            value: "Male",
        },
        {
            id: 2,
            value: "Female",
        },
    ];

    const Blood1 = [
        {
            id: 1,
            value: "A+",
        },
        {
            id: 2,
            value: "A-",
        },
        {
            id: 3,
            value: "O+",
        },
        {
            id: 4,
            value: "B-",
        },
        {
            id: 5,
            value: "B+",
        },
        {
            id: 6,
            value: "B+",
        },
    ];

    const [user, setUser] = useState({
        school_id: localStorage.getItem("schoolId"),
        studentID: "",
        first_name: "",
        last_name: "",
        gender: "",
        programs_id: "",
        dob: "",
        joining_date: "",
        blood_group: "",
        address: "",
        father_name: "",
        mother_name: "",
        primary_email: "",
        secondary_email: "",
        primary_phone: "",
        secondary_phone: "",
        student_qr_code: "",
        status: 1,
        created_by: "",
        updated_by: "",
        profile_image: null,
    });

    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setUser({ ...user, profile_image: file });
        setSelectedImage(file);
    };

    const convertDBDateFormat = (dateString) => {
        const [day, month, year] = dateString.split('-');
        return `${year}-${month}-${day}`;
    };
    const dateFormatList = 'DD-MM-YYYY';

    const ondobChange = (date, dateString) => {
        setUser({ ...user, dob: dateString });
        console.log(date, dateString);
    };

    const ondojChange = (date, dateString) => {
        setUser({ ...user, joining_date: dateString });
        console.log(date, dateString);
    }
    const onSubmit = (ev) => {
        console.log("formData", formData)
        if (validateStudentForm()) {
            console.log(user);
            axios({
                method: "post",
                url: baseUrl + "/api/student_add",
                data: {
                    school_id: localStorage.getItem("schoolId"),
                    first_name: user.first_name,
                    last_name: "",
                    gender: user.gender,
                    dob: convertDBDateFormat(user.dob),
                    programs_id: user.programs_id,
                    joining_date: (user.joining_date !== "" && user.joining_date !== null) ? convertDBDateFormat(user.joining_date) : "",
                    blood_group: user.blood_group,
                    address: user.address,
                    father_name: user.father_name,
                    mother_name: user.mother_name,
                    primary_email: user.primary_email,
                    secondary_email: user.secondary_email,
                    primary_phone: user.primary_phone,
                    secondary_phone: user.secondary_phone,
                    profile_image: user.profile_image,
                    student_qr_code: "",
                    status: 1,
                    created_by: localStorage.getItem('loginUserId'),
                    studentID: user.studentID,
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response["data"]["status"] === true) {
                    setSnackbarMessage(response.data.message);
                    setMessageType('success');
                    handleSnackbarOpen();
                    $(".overlay").removeClass("visible");
                    $(".popup-modal-container").removeClass("visible");
                    setUser({
                        school_id: localStorage.getItem("schoolId"),
                        first_name: "",
                        last_name: "",
                        gender: "",
                        programs_id: "",
                        dob: "",
                        joining_date: "",
                        blood_group: "",
                        address: "",
                        father_name: "",
                        mother_name: "",
                        primary_email: "",
                        secondary_email: "",
                        primary_phone: "",
                        secondary_phone: "",
                        student_qr_code: "",
                        status: 1,
                        created_by: "",
                        updated_by: "",
                        studentID: "",
                        profile_image: null,
                    });
                    console.log(response);
                    fetchFoodData(0);
                } else {
                    setSnackbarMessage(response.data.message);
                    setMessageType('error');
                    handleSnackbarOpen();
                }
            });
        }
    };

    const [validationErrors, setValidationErrors] = useState({
        first_name: "",
        gender: "",
        dob: "",
        programs_id: "",
        profile_image: "",
        joining_date: "",
        studentID: "",
        blood_group: "",
        address: "",
        father_name: "",
        primary_phone: "",
        primary_email: "",
        mother_name: "",
        secondary_phone: "",
        secondary_email: "",
    });

    const validateStudentForm = () => {
        let errors = {};
        if (user.first_name === "") {
            errors.first_name = "Student name cannot be empty";
        }
        if (user.gender === "") {
            errors.gender = "Student gender cannot be empty";
        }
        if (user.dob === "") {
            errors.dob = "Student date of birth cannot be empty";
        }
        if (user.programs_id === "") {
            errors.programs_id = "Student programs cannot be empty";
        }
        if (user.address === "") {
            errors.address = "Student address cannot be empty";
        }
        if (user.father_name === "") {
            errors.father_name = "Student father name cannot be empty";
        }
        if (user.primary_phone.trim() === "") {
            errors.primary_phone = "Student father mobile number cannot be empty";
        } else if (
            !validator.isMobilePhone(user.primary_phone.trim()) ||
            user.primary_phone.trim().length !== 10
        ) {
            errors.primary_phone = "Please enter valid mobile number";
        }
        if (user.mother_name === "") {
            errors.mother_name = "Student mother name cannot be empty";
        }
        if (user.secondary_phone.trim() === "") {
            errors.secondary_phone = "Student mother mobile number cannot be empty";
        } else if (
            !validator.isMobilePhone(user.secondary_phone.trim()) ||
            user.secondary_phone.trim().length !== 10
        ) {
            errors.secondary_phone = "Please enter valid mobile number";
        }
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const [programs, setPrograms] = useState([]);

    const fetchPrograms = async () => {
        try {
            const response = await axios.post(baseUrl + "/api/get_programs", {
                school_id: localStorage.getItem("schoolId")
            }, {
                headers: {
                    "Authorization": localStorage.getItem('access_token')
                }
            });
            setPrograms(response.data.data);
        } catch (error) { }
    };

    const selectedProgram = programs.find(
        (program) => program.id === user.programs_id
    );

    const selectedGender = gender1.find(
        (gender) => gender.value === user.gender
    );

    const selectedBlood = Blood1.find(
        (blood_group) => blood_group.value === user.blood_group
    );

    const firstNameInputRef = useRef(null);
    const genderInputRef = useRef(null);
    const dobInputRef = useRef(null);
    const programInputRef = useRef(null);
    const imageInputRef = useRef(null);
    const joingInputRef = useRef(null);
    const studentInputRef = useRef(null);
    const bloodInputRef = useRef(null);
    const adressInputRef = useRef(null);
    const fathernameInputRef = useRef(null);
    const fatherphoneInputRef = useRef(null);
    const fatheremailInputRef = useRef(null);
    const mathernameInputRef = useRef(null);
    const matherphoneInputRef = useRef(null);
    const matheremailInputRef = useRef(null);
    const submitButtonRef = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === "Enter") {
            event.preventDefault();
            console.log("Next input ref:", nextInputRef); // Log the next input ref
            if (nextInputRef && nextInputRef.current) {
                nextInputRef.current.focus();
            } else {
                console.log("Next input ref is null");
            }
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside1);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside1);
        };
    }, []);

    // -------11-07-----------------

    const editProgramStyle = {
        position: "absolute",
        left: "430px",
        top: "55px",
        margin: "0px",
        maxWidth: "140px"
    }

    const editProgramPopupRef = useRef(null);
    const [editProgramVisible, setEditProgramVisible] = useState(false);
    const handleClickOutside = (event) => {
        if (editProgramPopupRef.current && !editProgramPopupRef.current.contains(event.target)) {
            setEditProgramVisible(false);
        }
    };

    useEffect(() => {
        if (editProgramVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [editProgramVisible]);

    const [isRecycleBinVisible, setRecycleBinVisible] = useState(false);
    const [isRecycleBinStatus, setRecycleBinStatus] = useState("");
    const handleRecycleBinClick = (status) => {
        setRecycleBinVisible(true);
    };

    const handleRecycleBinClose = () => {
        setRecycleBinVisible(false);
    };

    const handleRecycleBin = async () => {
        const url = `${baseUrl}/api/programs_delete`;
        const data = {
            id: localStorage.getItem("programId"),
            updated_by: localStorage.getItem('loginUserId')
        };
        const headers = {
            "Content-Type": "multipart/form-data",
            "Authorization": localStorage.getItem('access_token')
        };
        try {
            const response = await axios.post(url, data, { headers });
            console.log(response);
            if (response.data.status === true) {
                setRecycleBinVisible(false);
                setSnackbarMessage(response.data.message);
                setMessageType('success');
                handleSnackbarOpen();
                fetchProgramsData();
            } else {
                setSnackbarMessage(response.data.message);
                setMessageType('error');
                handleSnackbarOpen();
            }
        } catch (error) {
            console.error(error);
        }
    };

    // -------16-07------------------
    // const [studentDataLength, setStudentDataLength] = useState(23);
    const [studentDataLength, setStudentDataLength] = useState(() => Math.floor(window.innerWidth / 80));

    // ------24-07----------
    const [openPopoverIndex, setOpenPopoverIndex] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // -----25-07---------


    useEffect(() => {
        $(document).on("click", "#newStudentCreate", function () {
            setBorderColor(initialBorderColor);
            setSelectedImage(null);
            setUser({
                school_id: localStorage.getItem("schoolId"),
                first_name: "",
                last_name: "",
                gender: "",
                programs_id: "",
                dob: "",
                joining_date: "",
                blood_group: "",
                address: "",
                father_name: "",
                mother_name: "",
                primary_email: "",
                secondary_email: "",
                primary_phone: "",
                secondary_phone: "",
                student_qr_code: "",
                status: 1,
                created_by: "",
                updated_by: "",
                studentID: "",
                profile_image: null,
            });
            setValidationErrors({
                first_name: "",
                gender: "",
                dob: "",
                programs_id: "",
                profile_image: "",
                joining_date: "",
                studentID: "",
                blood_group: "",
                address: "",
                father_name: "",
                primary_phone: "",
                primary_email: "",
                mother_name: "",
                secondary_phone: "",
                secondary_email: "",
            });
            $(".overlay").addClass("visible");
            $(".popup-modal-container").addClass("visible");
        });
        $(document).on("click", ".sp-btn-cancel", function () {
            setBorderColor(initialBorderColor);
            $(".overlay").removeClass("visible");
            $(".popup-modal-container").removeClass("visible");
        });
        fetchFoodTimeData();
        fetchFoodTypeData();
        fetchQuantityData();
        fetchFoodCategoryData();
        fetchProgramsData();
        fetchTeachersData();
        fetchPrograms();
        fetchProgramTimeData();
        fetchFoodData(0);
    }, []);

    return (
        <section className="staff_atten_cus food_cus">
            <Snackbar className="snack_total"
                anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
                open={snackbarState.open}
                onClose={handleSnackbarClose}
                message={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemIcon>
                            <img src={messageType === 'success' ? SuccessIcon : ErrorIcon} alt="Success" style={{ width: '24px', height: '24px' }} />
                        </ListItemIcon>
                        <div className="success_cuc">
                            <p> {snackbarMessage} </p>
                        </div>
                    </div>
                }
                ContentProps={{
                    sx: {
                        border: "1px solid",
                        opacity: "1",
                        background: messageType === 'success' ? "#40BE61" : "#FD4954",
                        borderColor: messageType === 'success' ? "#40BE61" : "#FD4954",
                        width: '415px'
                    }
                }}
                autoHideDuration={3000}
            />
            <div className="outer-container">
                <div className="overlay" />
                <div id="left-panel" className="sp-left-panel">
                <CommonSidebarHeader/>
                    <div className="sp-middle-band">
                        <div className="sp-nav y-auto">
                            <div className="name_tag_side">
                                <h4 className="sp-title">Quick Actions</h4>
                                <ul>
                                    <li onClick={() => { navigate('/dashboard'); }}>
                                        <span>
                                            <i className="icon-Dashboard-Outline" />
                                            <p>Dashboard</p>
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/activity'); }}>
                                        <span>
                                            <i className="icon-Activity-Outline" />
                                            <p>Activity</p>
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/calendar'); }}>
                                        <span>
                                            <i className="icon-Timetable-Outline" />
                                            <p>Calendar</p>
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/staff_list'); }}>
                                        <span>
                                            <i className="icon-Staff-Outline" />
                                            <p>Staff</p>
                                        </span>
                                    </li>
                                    {/* <li>
                    <span>
                      <i className="icon-Message-Outline" />
                      Messages
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Fees-Outline" />
                      Fees Management
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Enquiries-Outline" />
                      Enquiries
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Invites-Outline" />
                      Invites
                    </span>
                  </li> */}
                                </ul>
                                <h4 className="sp-title flexc justifySp" onClick={() => handleOpenNewProgramPopupVisible("add")}>
                                    Programs{" "}
                                    <span id="add-programs" className="sp-plus">
                                        +
                                    </span>
                                </h4>
                                <ul>
                                    {programsLoading && programsData && programsData.length > 0 ?
                                        programsData.map((data, index) => {
                                            return (
                                                <li className={data.id === parseInt(localStorage.getItem('programId')) ? "selected" : ""} onClick={() => {
                                                    localStorage.setItem('programId', data.id);
                                                    localStorage.setItem('programName', data.program_name);
                                                    fetchFoodData(curruntWeek);
                                                    navigate('/food');
                                                }}>
                                                    <span>
                                                        <i className="icon-Program-Outline-1" />
                                                        <p>{data.program_name}</p>
                                                    </span>
                                                </li>
                                            );
                                        }) :
                                        <div className="data-loader extracss_load">
                                            {/* <Loader
                                                type="spinner-cub"
                                                bgColor="#4D6EE3"
                                                color="#6B6F8C"
                                                title="Loading..."
                                                size={35}
                                            /> */}
                                            <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                                            <p>Loading...</p>
                                        </div>
                                    }

                                </ul>
                            </div>
                        </div>
                        <Sidebar />
                    </div>
                </div>
                <div className="sp-right-panel">
                    <div className="sp-top-bar flexc justifySp exta_pad_10">
                        <div className="ft-wt600 flexc pad_ic_ri stude_li_edi">
                            <span className="selected-icon">
                                <i className="icon-Program-Outline-1" />
                            </span>
                            <p className="program_tit">{localStorage.getItem('programName')}</p>
                            {/* <i className={`icon-More-Icon program-icon-style ${editProgramVisible && "active-Icon"}`} onClick={() => setEditProgramVisible(true)} /> */}
                            <span className="pos_fix_edit_student" ref={editProgramPopupRef}>
                                {/* <i className="icon-More-Icon" /> */}
                                <div className="svg_hide_drop">
                                    <Dropdown
                                        label={
                                            <span
                                                onClick={() => setEditProgramVisible(true)}
                                                style={{
                                                    color: "#1B252F",
                                                    padding: "0px",
                                                    height: "10px"
                                                }}
                                            >
                                                {
                                                    editProgramVisible === false
                                                        ? <i className="icon-More-Icon active_ic_more" />
                                                        : <i className="icon-More-Icon active_ic_more active_click_more" />
                                                }
                                            </span>
                                        }
                                        style={{ width: "100%" }}
                                    >
                                        <Dropdown.Item onClick={() => handleOpenNewProgramPopupVisible("edit")} className="pad_progr1">
                                            <img src={editIcon} alt="" style={{ width: '18px' }} /> &nbsp; &nbsp;Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleRecycleBinClick("program")} className="pad_progr1">
                                            <img src={deleteIcon} alt="" style={{ width: '18px' }} /> &nbsp; &nbsp;Delete
                                        </Dropdown.Item>
                                    </Dropdown>
                                </div>
                            </span>
                        </div>
                        <div className="flexc">
                            <button className="sp-view-btn ft-wt500 flexc"
                                onClick={() => {
                                    importStudents1();
                                }}>
                                <i className="icon-Import-Outline" />
                                Import
                            </button>
                            <button
                                className="createN ft-wt500 mr-lf10 space_icon_pad"
                                id="newStudentCreate"
                            ><i class="fa-solid fa-plus"></i>New Student</button>
                        </div>
                    </div>
                    <div className="sp-header">
                        <nav className="flexc">
                            <div className="sp-list-view flexc" onClick={() => { navigate('/student_list'); }}>
                                <i className="icon-Students-Outline" />Student List</div>
                            <div className="sp-list-view flexc" onClick={() => { navigate('/student_attendance'); }}>
                                <i className="icon-Attendance-Outline" />Attendance</div>
                            <div className="sp-list-view flexc selected" onClick={() => { navigate('/food'); }}>
                                <i className="icon-Food" />Food</div>
                            <div className="sp-list-view flexc" onClick={() => { navigate("/sleep"); }}
                            ><i className="icon-Sleep" />Sleep</div>
                            <div className="sp-list-view flexc" onClick={() => { navigate('/student_timetable'); }}>
                                <i className="icon-Calendar-Outline" />Timetable</div>
                        </nav>
                        {
                            (studentCount && importstatus) === false &&
                            <div className="sp-menu-bar flexc justifySp">
                                <div className="sp-menugroup">
                                    <div className="flexc">
                                        <div className={`btn-search-bar flexc glo-showdow ${isFocused ? 'focused' : ''}`}>
                                            <i className="icon-Search" />
                                            {/* 31-07-2024 */}
                                            <input
                                                type="text"
                                                id="simple-search"
                                                placeholder="Search Students"
                                                value={filterValue}
                                                onChange={handleInputChange}
                                                onFocus={() => setIsFocused(true)}
                                                onBlur={() => setIsFocused(false)}
                                                className="search-input"
                                            />
                                            {/* 31-07-2024 */}
                                            <i onClick={clearInput} className={`icon-Close ${filterValue ? '' : 'hide-visibility'}`} />
                                            {/* <input
                                                type="search"
                                                placeholder="Search Students"
                                                value={filterValue}
                                                onChange={handleInputChange}
                                                onFocus={() => setIsFocused(true)}
                                                onBlur={() => setIsFocused(false)}
                                                className="search-input"
                                            /> */}
                                        </div>
                                        <div className="week-detail flexc">
                                            {
                                                <button className="set-bord left-arr glo-showdow" onClick={() => {
                                                    setCurruntWeek(curruntWeek + 7);
                                                    fetchFoodData(curruntWeek + 7);
                                                }}>
                                                    {/* <span className="arrow" /> */}
                                                    <img src={rigar} style={{ width: "7px", height: "14px" }} />
                                                </button>
                                            }
                                            <button className="sel-days set-bord glo-showdow">
                                                Week
                                            </button>
                                            <button className="set-bord right-arr glo-showdow" onClick={() => {
                                                const newWeek = Math.max(0, curruntWeek - 7);
                                                setCurruntWeek(newWeek);
                                                fetchFoodData(newWeek);
                                            }}
                                                disabled={
                                                    curruntWeek === 0
                                                }
                                            >
                                                {/* <span className="arrow" /> */}
                                                <img src={lefar} style={{ width: "7px", height: "14px" }} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {importstatus ?
                        <ImportComponent
                            importStudent={"Student"}
                            handleImportStatus={handleImportStatus}
                            setProgress={setProgress} setIsLoading={setIsLoading}
                        />
                        : <div className="he_fix_cus ">
                            {
                                (foodLoading && foodData) ? (
                                    foodData.data.length > 0 ? (
                                        <div className="std-attence">
                                            <div className="attence-top overxy-auto">
                                                <div className="students-add-list">
                                                    <ul className="sp-head sp_sticky defult-date-header">
                                                        <li>{foodData.student}</li>
                                                        {dataHeader && dataHeader.length > 0 ?
                                                            (dataHeader).map((data, index) => {
                                                                return (
                                                                    <li key={index} className={`${formatDate(moment(data.date_data, 'ddd MMM DD YYYY').toDate()) === formattedCurrentDate && 'active-date-header'}`}
                                                                    >{data.date_data}</li>
                                                                );
                                                            }) : <div></div>}
                                                    </ul>
                                                    {foodHeaderdata.map((student, studentIndex) => (
                                                        <React.Fragment key={studentIndex}>
                                                            <ul>
                                                                <li>
                                                                    <span className="ft-500 flexc truncate img_wid_cu">
                                                                        {
                                                                            student?.profile_image !== null ?
                                                                                <img
                                                                                    src={baseUrl + "/api/" + student?.profile_image}
                                                                                    width={40}
                                                                                    alt={student?.first_name}
                                                                                    style={{ borderRadius: "5px" }}
                                                                                /> : <LogoComponent name={student?.name} index={studentIndex} bgColor={student?.std_color}/>
                                                                        }
                                                                        {/* <img src={
                                                                            student.profile_image !== null
                                                                                ? baseUrl +
                                                                                "/api/" +
                                                                                student.profile_image
                                                                                : avatar1
                                                                        } width={40} alt="Avatar" /> */}
                                                                        <span
                                                                            className="mr-lf10 fs-13 truncate name_bold text-cap"
                                                                            data-tooltip-id={`name-tooltip-${student?.id}`}
                                                                            data-tooltip-content={student.name}
                                                                            data-tooltip-place="bottom"
                                                                            style={{ cursor: student.name.length > studentDataLength && "pointer" }}
                                                                        >
                                                                            {
                                                                                (student.name && student.name.length <= studentDataLength) ? student.name : `${student.name.slice(0, studentDataLength)}...`
                                                                            }
                                                                        </span>
                                                                    </span>
                                                                </li>
                                                                {
                                                                    student.name.length > studentDataLength &&
                                                                    <Tooltip className="custom-tooltip" id={`name-tooltip-${student.id}`} />
                                                                }
                                                                {student.food.map((foodData, foodIndex) => (
                                                                    <React.Fragment key={foodIndex}>
                                                                        {foodData.food_value.length > 0 ? (
                                                                            <li
                                                                                className={`${formatDate(moment(foodData.date, 'ddd MMM DD YYYY').toDate()) === formattedCurrentDate ? 'active-date-data' :
                                                                                    formatDate(moment(foodData.date, 'ddd MMM DD YYYY').toDate()) > formattedCurrentDate && 'next-date-data'} ${(studentIndex === (foodHeaderdata.length - 1) && formatDate(moment(foodData.date, 'ddd MMM DD YYYY').toDate()) === formattedCurrentDate) ? 'active-last-date-data' : ''}`}
                                                                            >
                                                                                <div>
                                                                                    {foodData.food_value.map((food, timeIndex) => (
                                                                                        <div className="sp-tag-selected food_date mr-lf10"
                                                                                            aria-describedby={openPopoverIndex === `${studentIndex}-${foodIndex}-update` ? 'simple-popover-update' : undefined}
                                                                                            variant="contained"
                                                                                            key={timeIndex}
                                                                                            style={{ cursor: "pointer" }} onClick={(event) => {
                                                                                                openUpdateFoodPopup(
                                                                                                    {
                                                                                                        id: food.food_data.id,
                                                                                                        student_id: student.id,
                                                                                                        formattedDate: foodData.formattedDate,
                                                                                                        food_time_id: food.food_data.food_time_id,
                                                                                                        quantity_id: food.food_data.quantity_id,
                                                                                                        type_id: food.food_data.type_id,
                                                                                                        category_id: food.food_data.category_id,
                                                                                                        message: food.food_data.message
                                                                                                    }, event, `${studentIndex}-${foodIndex}-update`
                                                                                                    //    student,foodData.formattedDate
                                                                                                );
                                                                                            }}>
                                                                                            {food.food_value}
                                                                                        </div>
                                                                                    ))}
                                                                                    <Popover
                                                                                        id="simple-popover-update"
                                                                                        open={openPopoverIndex === `${studentIndex}-${foodIndex}-update`}
                                                                                        anchorEl={anchorEl}
                                                                                        // onClose={handleClick}
                                                                                        anchorOrigin={{
                                                                                            vertical: 'bottom',
                                                                                            horizontal: 'center',
                                                                                        }}
                                                                                        transformOrigin={{
                                                                                            vertical: 'top',
                                                                                            horizontal: 'center',
                                                                                        }}
                                                                                        sx={{
                                                                                            '& .MuiPaper-root': {
                                                                                                backgroundColor: '#fff !important',
                                                                                                border: '1px solid #d9dce6 !important',
                                                                                                borderRadius: '10px !important',
                                                                                                boxShadow: '0 8px 24px 0 #0000000f !important',
                                                                                                transformOrigin: 'none !important',
                                                                                                transition: 'none !important',
                                                                                            },
                                                                                        }}
                                                                                    >
                                                                                        <Box sx={{ p: 4 }}>
                                                                                            <div className="food_sleap_lab" style={{ width: "400px", borderRadius: "20" }}>
                                                                                                <div className="sp-tt ft-14 flexc ft-wt600 food_head_style">{foodId === null ? "Add" : "Update"} Food</div>
                                                                                                <div className="form-section-default flexc justifySp fwrap">
                                                                                                    <div className="field-label half-field error_mes_cus extra_pad_drop pos_stu_cus">
                                                                                                        <label>Food Time<span className="col_red">*</span></label>
                                                                                                        <div
                                                                                                            className="field-value-holder margin_top10p dropbutton"
                                                                                                            ref={foodInputRef}
                                                                                                            style={{
                                                                                                                width: "100%",
                                                                                                                borderColor: foodTimeError ? "red" : borderColor.timeBorder,
                                                                                                            }}
                                                                                                            onFocus={() => handleFocus("timeBorder")}
                                                                                                            onBlur={handleBlur}
                                                                                                        >
                                                                                                            <Dropdown
                                                                                                                // label={<span style={{ color: 'black' }}>{selectedFoodTime ? selectedFoodTime.time_name : "Select Time"}</span>}
                                                                                                                label={
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            fontSize: "0.8825em",
                                                                                                                            color: selectedFoodTime ? '#1B252F' : '#292929',
                                                                                                                            justifyContent: "flex-start"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {selectedFoodTime ? selectedFoodTime.time_name : "Select Time"}
                                                                                                                    </span>
                                                                                                                }
                                                                                                                style={{ width: '100%' }}
                                                                                                            >
                                                                                                                {foodTimeData.map(item => {
                                                                                                                    if (item.is_food_time === 1) {
                                                                                                                        return (
                                                                                                                            <Dropdown.Item
                                                                                                                                key={item.id}
                                                                                                                                className={`${selectedFoodTime && selectedFoodTime.id === item.id ? "selected-item dd_select" : ""
                                                                                                                                    }`}
                                                                                                                                icon={selectedFoodTime && selectedFoodTime.id === item.id ? HiCheck : null}
                                                                                                                                onClick={() => setFoodDetails({ ...foodDetails, foodTime: item.id })}
                                                                                                                            >
                                                                                                                                {item.time_name}
                                                                                                                            </Dropdown.Item>
                                                                                                                        );
                                                                                                                    }
                                                                                                                    return null;
                                                                                                                })}
                                                                                                            </Dropdown>
                                                                                                        </div>
                                                                                                        {foodTimeError && <p className="error pos_abo_stu">{foodTimeError}</p>}
                                                                                                    </div>
                                                                                                    <div className="field-label half-field dropbutton error_mes_cus pos_stu_cus">
                                                                                                        <label>Quantity<span className="col_red">*</span></label>
                                                                                                        <div
                                                                                                            className="field-value-holder margin_top10p"
                                                                                                            style={{
                                                                                                                width: "100%",
                                                                                                                borderColor: quantityError ? "red" : borderColor.quantityBorder,
                                                                                                            }}
                                                                                                            onFocus={() => handleFocus("quantityBorder")}
                                                                                                            onBlur={handleBlur}
                                                                                                        >

                                                                                                            <Dropdown
                                                                                                                label={<span
                                                                                                                    style={{
                                                                                                                        fontSize: "0.8825em",
                                                                                                                        color: selectedQuantity ? '#1B252F' : '#292929',
                                                                                                                        justifyContent: "flex-start"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {selectedQuantity ? selectedQuantity.quantity_name : "Select Quantity"}
                                                                                                                </span>}
                                                                                                                style={{ width: '100%' }}
                                                                                                            >
                                                                                                                {quantityData.map(item => (
                                                                                                                    <Dropdown.Item
                                                                                                                        className={`${selectedQuantity && selectedQuantity.id === item.id ? "selected-item dd_select" : ""
                                                                                                                            }`}
                                                                                                                        // className={selectedQuantity && selectedQuantity.id === item.id ? "dd_select" : null}
                                                                                                                        icon={selectedQuantity && selectedQuantity.id === item.id ? HiCheck : null}
                                                                                                                        onClick={() => setFoodDetails({ ...foodDetails, quantity: item.id })}
                                                                                                                        key={item.id}
                                                                                                                    >
                                                                                                                        {item.quantity_name}
                                                                                                                    </Dropdown.Item>
                                                                                                                ))}
                                                                                                            </Dropdown>
                                                                                                        </div>
                                                                                                        {quantityError && <p className="error pos_abo_stu">{quantityError}</p>}
                                                                                                    </div>
                                                                                                    <div className="field-label half-field dropbutton error_mes_cus pos_stu_cus">
                                                                                                        <label>Type<span className="col_red">*</span></label>
                                                                                                        <div
                                                                                                            className="field-value-holder margin_top10p"
                                                                                                            style={{
                                                                                                                width: "100%",
                                                                                                                borderColor: foodTypeError ? "red" : borderColor.typeBorder,
                                                                                                            }}
                                                                                                            onFocus={() => handleFocus("typeBorder")}
                                                                                                            onBlur={handleBlur}
                                                                                                        >
                                                                                                            <Dropdown
                                                                                                                label={
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            fontSize: "0.8825em",
                                                                                                                            color: selectedFoodType ? '#1B252F' : '#292929',
                                                                                                                            justifyContent: "flex-start"
                                                                                                                        }}>
                                                                                                                        {selectedFoodType ? selectedFoodType.type_name : "Select Type"}</span>}
                                                                                                                style={{ width: '100%' }}
                                                                                                            >
                                                                                                                {foodTypeData.map(item => (
                                                                                                                    <Dropdown.Item
                                                                                                                        className={`${selectedFoodType && selectedFoodType.id === item.id ? "selected-item dd_select" : ""
                                                                                                                            }`}
                                                                                                                        // className={selectedFoodType && selectedFoodType.id === item.id ? "dd_select" : null}
                                                                                                                        icon={selectedFoodType && selectedFoodType.id === item.id ? HiCheck : null}
                                                                                                                        onClick={() => setFoodDetails({ ...foodDetails, foodType: item.id })}
                                                                                                                        key={item.id}
                                                                                                                    >
                                                                                                                        {item.type_name}
                                                                                                                    </Dropdown.Item>
                                                                                                                ))}
                                                                                                            </Dropdown>
                                                                                                        </div>
                                                                                                        {foodTypeError && <p className="error pos_abo_stu">{foodTypeError}</p>}
                                                                                                    </div>
                                                                                                    <div className="field-label half-field dropbutton error_mes_cus pos_stu_cus">
                                                                                                        <label>Category<span className="col_red">*</span></label>
                                                                                                        <div
                                                                                                            className="field-value-holder margin_top10p"
                                                                                                            style={{
                                                                                                                width: "100%",
                                                                                                                borderColor: categoryError ? "red" : borderColor.categoryBorder,
                                                                                                            }}
                                                                                                            onFocus={() => handleFocus("categoryBorder")}
                                                                                                            onBlur={handleBlur}

                                                                                                        >
                                                                                                            <Dropdown
                                                                                                                label={
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            fontSize: "0.8825em",
                                                                                                                            color: selectedCategory ? '#1B252F' : '#292929',
                                                                                                                            justifyContent: "flex-start"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {selectedCategory ? selectedCategory.category_name : "Select Category"}
                                                                                                                    </span>}
                                                                                                                style={{ width: '100%' }}
                                                                                                            >
                                                                                                                {foodCategoryData.map(item => (
                                                                                                                    <Dropdown.Item
                                                                                                                        className={`${selectedCategory && selectedCategory.id === item.id ? "selected-item dd_select" : ""
                                                                                                                            }`}
                                                                                                                        // className={selectedCategory && selectedCategory.id === item.id ? "dd_select" : null}
                                                                                                                        icon={selectedCategory && selectedCategory.id === item.id ? HiCheck : null}
                                                                                                                        onClick={() => setFoodDetails({ ...foodDetails, category: item.id })}
                                                                                                                        key={item.id}
                                                                                                                    >
                                                                                                                        {item.category_name}
                                                                                                                    </Dropdown.Item>
                                                                                                                ))}
                                                                                                            </Dropdown>
                                                                                                        </div>
                                                                                                        {categoryError && <p className="error pos_abo_stu">{categoryError}</p>}
                                                                                                    </div>
                                                                                                    <div className="field-label full-field dropbutton error_mes_cus">
                                                                                                        <label>Description</label>
                                                                                                        <div className="field-value-holder margin_top10p">
                                                                                                            <textarea

                                                                                                                value={foodDetails.description}
                                                                                                                // type="text"
                                                                                                                onChange={ev => setFoodDetails({ ...foodDetails, description: ev.target.value })}
                                                                                                                className="sp-field-input fixed-height-textarea"
                                                                                                                placeholder="Description"
                                                                                                            />
                                                                                                        </div>
                                                                                                        {/* {descriptionError && <p className="error">{descriptionError}</p>} */}
                                                                                                    </div>
                                                                                                    <div className="flexc mt-4 food_sleep_saveic ">
                                                                                                        <div className="flexc calender_cre_can">
                                                                                                            <button className="createN ft-wt500 mr-2" onClick={() => { foodId === null ? addFood() : updateFood() }}>{foodId === null ? "Save" : "Update"}</button>
                                                                                                            <button className="sp-view-btn ft-wt500 flexc cancel-btn" onClick={() => { closeAddFoodPopup(); }}>
                                                                                                                Cancel
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Box>
                                                                                    </Popover >
                                                                                </div>
                                                                                {foodData.attendance_status === 1 &&
                                                                                    <div>
                                                                                        <div className="sp-add-food cp"
                                                                                            aria-describedby={openPopoverIndex === `${studentIndex}-${foodIndex}-update` ? 'simple-popover-update' : undefined}
                                                                                            variant="contained"
                                                                                            onClick={(event) => {
                                                                                                //openAddFoodPopup(student,foodData.formattedDate);
                                                                                                openAddFoodPopup(
                                                                                                    {
                                                                                                        student_id: student.id,
                                                                                                        formattedDate: foodData.formattedDate
                                                                                                    }, event, `${studentIndex}-${foodIndex}-update`
                                                                                                );
                                                                                            }}>
                                                                                            <i className="icon-plus-w" />
                                                                                        </div>
                                                                                        <Popover
                                                                                            id="simple-popover-update"
                                                                                            open={openPopoverIndex === `${studentIndex}-${foodIndex}-update`}
                                                                                            // id={id}
                                                                                            // open={open}
                                                                                            anchorEl={anchorEl}
                                                                                            // onClose={handleClick}
                                                                                            anchorOrigin={{
                                                                                                vertical: 'bottom',
                                                                                                horizontal: 'center',
                                                                                            }}
                                                                                            transformOrigin={{
                                                                                                vertical: 'top',
                                                                                                horizontal: 'center',
                                                                                            }}
                                                                                            sx={{
                                                                                                '& .MuiPaper-root': {
                                                                                                    backgroundColor: '#fff !important',
                                                                                                    border: '1px solid #d9dce6 !important',
                                                                                                    borderRadius: '10px !important',
                                                                                                    boxShadow: '0 8px 24px 0 #0000000f !important',
                                                                                                    transformOrigin: 'none !important',
                                                                                                    transition: 'none !important',
                                                                                                },
                                                                                            }}
                                                                                        >
                                                                                            <Box sx={{ p: 4 }}>
                                                                                                <div className="food_sleap_lab" style={{ width: "400px", borderRadius: "20" }}>
                                                                                                    <div className="sp-tt ft-14 flexc ft-wt600 food_head_style">{foodId === null ? "Add" : "Update"} Food</div>
                                                                                                    <div className="form-section-default flexc justifySp fwrap">
                                                                                                        <div className="field-label half-field error_mes_cus extra_pad_drop pos_stu_cus">
                                                                                                            <label>Food Time<span className="col_red">*</span></label>
                                                                                                            <div
                                                                                                                className="field-value-holder margin_top10p dropbutton"
                                                                                                                ref={foodInputRef}
                                                                                                                style={{
                                                                                                                    width: "100%",
                                                                                                                    borderColor: foodTimeError ? "red" : borderColor.timeBorder,
                                                                                                                }}
                                                                                                                onFocus={() => handleFocus("timeBorder")}
                                                                                                                onBlur={handleBlur}
                                                                                                            >
                                                                                                                <Dropdown
                                                                                                                    // label={<span style={{ color: 'black' }}>{selectedFoodTime ? selectedFoodTime.time_name : "Select Time"}</span>}
                                                                                                                    label={
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: "0.8825em",
                                                                                                                                color: selectedFoodTime ? '#1B252F' : '#292929',
                                                                                                                                justifyContent: "flex-start"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {selectedFoodTime ? selectedFoodTime.time_name : "Select Time"}
                                                                                                                        </span>
                                                                                                                    }
                                                                                                                    style={{ width: '100%' }}
                                                                                                                >
                                                                                                                    {foodTimeData.map(item => {
                                                                                                                        if (item.is_food_time === 1) {
                                                                                                                            return (
                                                                                                                                <Dropdown.Item
                                                                                                                                    key={item.id}
                                                                                                                                    className={`${selectedFoodTime && selectedFoodTime.id === item.id ? "selected-item dd_select" : ""
                                                                                                                                        }`}
                                                                                                                                    icon={selectedFoodTime && selectedFoodTime.id === item.id ? HiCheck : null}
                                                                                                                                    onClick={() => setFoodDetails({ ...foodDetails, foodTime: item.id })}
                                                                                                                                >
                                                                                                                                    {item.time_name}
                                                                                                                                </Dropdown.Item>
                                                                                                                            );
                                                                                                                        }
                                                                                                                        return null;
                                                                                                                    })}
                                                                                                                </Dropdown>
                                                                                                            </div>
                                                                                                            {foodTimeError && <p className="error pos_abo_stu">{foodTimeError}</p>}
                                                                                                        </div>
                                                                                                        <div className="field-label half-field dropbutton error_mes_cus pos_stu_cus">
                                                                                                            <label>Quantity<span className="col_red">*</span></label>
                                                                                                            <div
                                                                                                                className="field-value-holder margin_top10p"
                                                                                                                style={{
                                                                                                                    width: "100%",
                                                                                                                    borderColor: quantityError ? "red" : borderColor.quantityBorder,
                                                                                                                }}
                                                                                                                onFocus={() => handleFocus("quantityBorder")}
                                                                                                                onBlur={handleBlur}
                                                                                                            >

                                                                                                                <Dropdown
                                                                                                                    label={<span
                                                                                                                        style={{
                                                                                                                            fontSize: "0.8825em",
                                                                                                                            color: selectedQuantity ? '#1B252F' : '#292929',
                                                                                                                            justifyContent: "flex-start"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {selectedQuantity ? selectedQuantity.quantity_name : "Select Quantity"}
                                                                                                                    </span>}
                                                                                                                    style={{ width: '100%' }}
                                                                                                                >
                                                                                                                    {quantityData.map(item => (
                                                                                                                        <Dropdown.Item
                                                                                                                            className={`${selectedQuantity && selectedQuantity.id === item.id ? "selected-item dd_select" : ""
                                                                                                                                }`}
                                                                                                                            // className={selectedQuantity && selectedQuantity.id === item.id ? "dd_select" : null}
                                                                                                                            icon={selectedQuantity && selectedQuantity.id === item.id ? HiCheck : null}
                                                                                                                            onClick={() => setFoodDetails({ ...foodDetails, quantity: item.id })}
                                                                                                                            key={item.id}
                                                                                                                        >
                                                                                                                            {item.quantity_name}
                                                                                                                        </Dropdown.Item>
                                                                                                                    ))}
                                                                                                                </Dropdown>
                                                                                                            </div>
                                                                                                            {quantityError && <p className="error pos_abo_stu">{quantityError}</p>}
                                                                                                        </div>
                                                                                                        <div className="field-label half-field dropbutton error_mes_cus pos_stu_cus">
                                                                                                            <label>Type<span className="col_red">*</span></label>
                                                                                                            <div
                                                                                                                className="field-value-holder margin_top10p"
                                                                                                                style={{
                                                                                                                    width: "100%",
                                                                                                                    borderColor: foodTypeError ? "red" : borderColor.typeBorder,
                                                                                                                }}
                                                                                                                onFocus={() => handleFocus("typeBorder")}
                                                                                                                onBlur={handleBlur}
                                                                                                            >
                                                                                                                <Dropdown
                                                                                                                    label={
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: "0.8825em",
                                                                                                                                color: selectedFoodType ? '#1B252F' : '#292929',
                                                                                                                                justifyContent: "flex-start"
                                                                                                                            }}>
                                                                                                                            {selectedFoodType ? selectedFoodType.type_name : "Select Type"}</span>}
                                                                                                                    style={{ width: '100%' }}
                                                                                                                >
                                                                                                                    {foodTypeData.map(item => (
                                                                                                                        <Dropdown.Item
                                                                                                                            className={`${selectedFoodType && selectedFoodType.id === item.id ? "selected-item dd_select" : ""
                                                                                                                                }`}
                                                                                                                            // className={selectedFoodType && selectedFoodType.id === item.id ? "dd_select" : null}
                                                                                                                            icon={selectedFoodType && selectedFoodType.id === item.id ? HiCheck : null}
                                                                                                                            onClick={() => setFoodDetails({ ...foodDetails, foodType: item.id })}
                                                                                                                            key={item.id}
                                                                                                                        >
                                                                                                                            {item.type_name}
                                                                                                                        </Dropdown.Item>
                                                                                                                    ))}
                                                                                                                </Dropdown>
                                                                                                            </div>
                                                                                                            {foodTypeError && <p className="error pos_abo_stu">{foodTypeError}</p>}
                                                                                                        </div>
                                                                                                        <div className="field-label half-field dropbutton error_mes_cus pos_stu_cus">
                                                                                                            <label>Category<span className="col_red">*</span></label>
                                                                                                            <div
                                                                                                                className="field-value-holder margin_top10p"
                                                                                                                style={{
                                                                                                                    width: "100%",
                                                                                                                    borderColor: categoryError ? "red" : borderColor.categoryBorder,
                                                                                                                }}
                                                                                                                onFocus={() => handleFocus("categoryBorder")}
                                                                                                                onBlur={handleBlur}

                                                                                                            >
                                                                                                                <Dropdown
                                                                                                                    label={
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: "0.8825em",
                                                                                                                                color: selectedCategory ? '#1B252F' : '#292929',
                                                                                                                                justifyContent: "flex-start"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {selectedCategory ? selectedCategory.category_name : "Select Category"}
                                                                                                                        </span>}
                                                                                                                    style={{ width: '100%' }}
                                                                                                                >
                                                                                                                    {foodCategoryData.map(item => (
                                                                                                                        <Dropdown.Item
                                                                                                                            className={`${selectedCategory && selectedCategory.id === item.id ? "selected-item dd_select" : ""
                                                                                                                                }`}
                                                                                                                            // className={selectedCategory && selectedCategory.id === item.id ? "dd_select" : null}
                                                                                                                            icon={selectedCategory && selectedCategory.id === item.id ? HiCheck : null}
                                                                                                                            onClick={() => setFoodDetails({ ...foodDetails, category: item.id })}
                                                                                                                            key={item.id}
                                                                                                                        >
                                                                                                                            {item.category_name}
                                                                                                                        </Dropdown.Item>
                                                                                                                    ))}
                                                                                                                </Dropdown>
                                                                                                            </div>
                                                                                                            {categoryError && <p className="error pos_abo_stu">{categoryError}</p>}
                                                                                                        </div>
                                                                                                        <div className="field-label full-field dropbutton error_mes_cus">
                                                                                                            <label>Description</label>
                                                                                                            <div className="field-value-holder margin_top10p">
                                                                                                                <textarea

                                                                                                                    value={foodDetails.description}
                                                                                                                    // type="text"
                                                                                                                    onChange={ev => setFoodDetails({ ...foodDetails, description: ev.target.value })}
                                                                                                                    className="sp-field-input fixed-height-textarea"
                                                                                                                    placeholder="Description"
                                                                                                                />
                                                                                                            </div>
                                                                                                            {/* {descriptionError && <p className="error">{descriptionError}</p>} */}
                                                                                                        </div>
                                                                                                        <div className="flexc mt-4 food_sleep_saveic ">
                                                                                                            <div className="flexc calender_cre_can">
                                                                                                                <button className="createN ft-wt500 mr-2" onClick={() => { foodId === null ? addFood() : updateFood() }}>{foodId === null ? "Save" : "Update"}</button>
                                                                                                                <button className="sp-view-btn ft-wt500 flexc cancel-btn" onClick={() => { closeAddFoodPopup(); }}>
                                                                                                                    Cancel
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Box>
                                                                                        </Popover >
                                                                                    </div>
                                                                                }
                                                                            </li>
                                                                        ) : (
                                                                            <li
                                                                                className={`${formatDate(moment(foodData.date, 'ddd MMM DD YYYY').toDate()) === formattedCurrentDate ? 'active-date-data' :
                                                                                    formatDate(moment(foodData.date, 'ddd MMM DD YYYY').toDate()) > formattedCurrentDate && 'next-date-data'} ${(studentIndex === (foodHeaderdata.length - 1) && formatDate(moment(foodData.date, 'ddd MMM DD YYYY').toDate()) === formattedCurrentDate) ? 'active-last-date-data' : ''}`}
                                                                            >
                                                                                {foodData.attendance_status === 1 ? (
                                                                                    <div>
                                                                                        <div className="sp-add-food cp"
                                                                                            aria-describedby={openPopoverIndex === `${studentIndex}-${foodIndex}` ? 'simple-popover' : undefined}
                                                                                            variant="contained"
                                                                                            onClick={(event) => {
                                                                                                //openAddFoodPopup(student,foodData.formattedDate);
                                                                                                openAddFoodPopup(
                                                                                                    {
                                                                                                        student_id: student.id,
                                                                                                        formattedDate: foodData.formattedDate
                                                                                                    }, event, `${studentIndex}-${foodIndex}`
                                                                                                );
                                                                                            }}>
                                                                                            <i className="icon-plus-w" />
                                                                                        </div>
                                                                                        <Popover
                                                                                            id='simple-popover'
                                                                                            open={openPopoverIndex === `${studentIndex}-${foodIndex}`}
                                                                                            // id={id}
                                                                                            // open={open}
                                                                                            anchorEl={anchorEl}
                                                                                            // onClose={handleClick}
                                                                                            anchorOrigin={{
                                                                                                vertical: 'bottom',
                                                                                                horizontal: 'center',
                                                                                            }}
                                                                                            transformOrigin={{
                                                                                                vertical: 'top',
                                                                                                horizontal: 'center',
                                                                                            }}
                                                                                            sx={{
                                                                                                '& .MuiPaper-root': {
                                                                                                    backgroundColor: '#fff !important',
                                                                                                    border: '1px solid #d9dce6 !important',
                                                                                                    borderRadius: '10px !important',
                                                                                                    boxShadow: '0 8px 24px 0 #0000000f !important',
                                                                                                    transformOrigin: 'none !important',
                                                                                                    transition: 'none !important',
                                                                                                },
                                                                                            }}
                                                                                        >
                                                                                            <Box sx={{ p: 4 }}>
                                                                                                <div className="food_sleap_lab" style={{ width: "400px", borderRadius: "20" }}>
                                                                                                    <div className="sp-tt ft-14 flexc ft-wt600 food_head_style">{foodId === null ? "Add" : "Update"} Food</div>
                                                                                                    <div className="form-section-default flexc justifySp fwrap">
                                                                                                        <div className="field-label half-field error_mes_cus extra_pad_drop pos_stu_cus">
                                                                                                            <label>Food Time<span className="col_red">*</span></label>
                                                                                                            <div
                                                                                                                className="field-value-holder margin_top10p dropbutton"
                                                                                                                ref={foodInputRef}
                                                                                                                style={{
                                                                                                                    width: "100%",
                                                                                                                    borderColor: foodTimeError ? "red" : borderColor.timeBorder,
                                                                                                                }}
                                                                                                                onFocus={() => handleFocus("timeBorder")}
                                                                                                                onBlur={handleBlur}
                                                                                                            >
                                                                                                                <Dropdown
                                                                                                                    // label={<span style={{ color: 'black' }}>{selectedFoodTime ? selectedFoodTime.time_name : "Select Time"}</span>}
                                                                                                                    label={
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: "0.8825em",
                                                                                                                                color: selectedFoodTime ? '#1B252F' : '#292929',
                                                                                                                                justifyContent: "flex-start"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {selectedFoodTime ? selectedFoodTime.time_name : "Select Time"}
                                                                                                                        </span>
                                                                                                                    }
                                                                                                                    style={{ width: '100%' }}
                                                                                                                >
                                                                                                                    {foodTimeData.map(item => {
                                                                                                                        if (item.is_food_time === 1) {
                                                                                                                            return (
                                                                                                                                <Dropdown.Item
                                                                                                                                    key={item.id}
                                                                                                                                    className={`${selectedFoodTime && selectedFoodTime.id === item.id ? "selected-item dd_select" : ""
                                                                                                                                        }`}
                                                                                                                                    icon={selectedFoodTime && selectedFoodTime.id === item.id ? HiCheck : null}
                                                                                                                                    onClick={() => setFoodDetails({ ...foodDetails, foodTime: item.id })}
                                                                                                                                >
                                                                                                                                    {item.time_name}
                                                                                                                                </Dropdown.Item>
                                                                                                                            );
                                                                                                                        }
                                                                                                                        return null;
                                                                                                                    })}
                                                                                                                </Dropdown>
                                                                                                            </div>
                                                                                                            {foodTimeError && <p className="error pos_abo_stu">{foodTimeError}</p>}
                                                                                                        </div>
                                                                                                        <div className="field-label half-field dropbutton error_mes_cus pos_stu_cus">
                                                                                                            <label>Quantity<span className="col_red">*</span></label>
                                                                                                            <div
                                                                                                                className="field-value-holder margin_top10p"
                                                                                                                style={{
                                                                                                                    width: "100%",
                                                                                                                    borderColor: quantityError ? "red" : borderColor.quantityBorder,
                                                                                                                }}
                                                                                                                onFocus={() => handleFocus("quantityBorder")}
                                                                                                                onBlur={handleBlur}
                                                                                                            >

                                                                                                                <Dropdown
                                                                                                                    label={<span
                                                                                                                        style={{
                                                                                                                            fontSize: "0.8825em",
                                                                                                                            color: selectedQuantity ? '#1B252F' : '#292929',
                                                                                                                            justifyContent: "flex-start"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {selectedQuantity ? selectedQuantity.quantity_name : "Select Quantity"}
                                                                                                                    </span>}
                                                                                                                    style={{ width: '100%' }}
                                                                                                                >
                                                                                                                    {quantityData.map(item => (
                                                                                                                        <Dropdown.Item
                                                                                                                            className={`${selectedQuantity && selectedQuantity.id === item.id ? "selected-item dd_select" : ""
                                                                                                                                }`}
                                                                                                                            // className={selectedQuantity && selectedQuantity.id === item.id ? "dd_select" : null}
                                                                                                                            icon={selectedQuantity && selectedQuantity.id === item.id ? HiCheck : null}
                                                                                                                            onClick={() => setFoodDetails({ ...foodDetails, quantity: item.id })}
                                                                                                                            key={item.id}
                                                                                                                        >
                                                                                                                            {item.quantity_name}
                                                                                                                        </Dropdown.Item>
                                                                                                                    ))}
                                                                                                                </Dropdown>
                                                                                                            </div>
                                                                                                            {quantityError && <p className="error pos_abo_stu">{quantityError}</p>}
                                                                                                        </div>
                                                                                                        <div className="field-label half-field dropbutton error_mes_cus pos_stu_cus">
                                                                                                            <label>Type<span className="col_red">*</span></label>
                                                                                                            <div
                                                                                                                className="field-value-holder margin_top10p"
                                                                                                                style={{
                                                                                                                    width: "100%",
                                                                                                                    borderColor: foodTypeError ? "red" : borderColor.typeBorder,
                                                                                                                }}
                                                                                                                onFocus={() => handleFocus("typeBorder")}
                                                                                                                onBlur={handleBlur}
                                                                                                            >
                                                                                                                <Dropdown
                                                                                                                    label={
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: "0.8825em",
                                                                                                                                color: selectedFoodType ? '#1B252F' : '#292929',
                                                                                                                                justifyContent: "flex-start"
                                                                                                                            }}>
                                                                                                                            {selectedFoodType ? selectedFoodType.type_name : "Select Type"}</span>}
                                                                                                                    style={{ width: '100%' }}
                                                                                                                >
                                                                                                                    {foodTypeData.map(item => (
                                                                                                                        <Dropdown.Item
                                                                                                                            className={`${selectedFoodType && selectedFoodType.id === item.id ? "selected-item dd_select" : ""
                                                                                                                                }`}
                                                                                                                            // className={selectedFoodType && selectedFoodType.id === item.id ? "dd_select" : null}
                                                                                                                            icon={selectedFoodType && selectedFoodType.id === item.id ? HiCheck : null}
                                                                                                                            onClick={() => setFoodDetails({ ...foodDetails, foodType: item.id })}
                                                                                                                            key={item.id}
                                                                                                                        >
                                                                                                                            {item.type_name}
                                                                                                                        </Dropdown.Item>
                                                                                                                    ))}
                                                                                                                </Dropdown>
                                                                                                            </div>
                                                                                                            {foodTypeError && <p className="error pos_abo_stu">{foodTypeError}</p>}
                                                                                                        </div>
                                                                                                        <div className="field-label half-field dropbutton error_mes_cus pos_stu_cus">
                                                                                                            <label>Category<span className="col_red">*</span></label>
                                                                                                            <div
                                                                                                                className="field-value-holder margin_top10p"
                                                                                                                style={{
                                                                                                                    width: "100%",
                                                                                                                    borderColor: categoryError ? "red" : borderColor.categoryBorder,
                                                                                                                }}
                                                                                                                onFocus={() => handleFocus("categoryBorder")}
                                                                                                                onBlur={handleBlur}

                                                                                                            >
                                                                                                                <Dropdown
                                                                                                                    label={
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: "0.8825em",
                                                                                                                                color: selectedCategory ? '#1B252F' : '#292929',
                                                                                                                                justifyContent: "flex-start"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {selectedCategory ? selectedCategory.category_name : "Select Category"}
                                                                                                                        </span>}
                                                                                                                    style={{ width: '100%' }}
                                                                                                                >
                                                                                                                    {foodCategoryData.map(item => (
                                                                                                                        <Dropdown.Item
                                                                                                                            className={`${selectedCategory && selectedCategory.id === item.id ? "selected-item dd_select" : ""
                                                                                                                                }`}
                                                                                                                            // className={selectedCategory && selectedCategory.id === item.id ? "dd_select" : null}
                                                                                                                            icon={selectedCategory && selectedCategory.id === item.id ? HiCheck : null}
                                                                                                                            onClick={() => setFoodDetails({ ...foodDetails, category: item.id })}
                                                                                                                            key={item.id}
                                                                                                                        >
                                                                                                                            {item.category_name}
                                                                                                                        </Dropdown.Item>
                                                                                                                    ))}
                                                                                                                </Dropdown>
                                                                                                            </div>
                                                                                                            {categoryError && <p className="error pos_abo_stu">{categoryError}</p>}
                                                                                                        </div>
                                                                                                        <div className="field-label full-field dropbutton error_mes_cus">
                                                                                                            <label>Description</label>
                                                                                                            <div className="field-value-holder margin_top10p">
                                                                                                                <textarea

                                                                                                                    value={foodDetails.description}
                                                                                                                    // type="text"
                                                                                                                    onChange={ev => setFoodDetails({ ...foodDetails, description: ev.target.value })}
                                                                                                                    className="sp-field-input fixed-height-textarea"
                                                                                                                    placeholder="Description"
                                                                                                                />
                                                                                                            </div>
                                                                                                            {/* {descriptionError && <p className="error">{descriptionError}</p>} */}
                                                                                                        </div>
                                                                                                        <div className="flexc mt-4 food_sleep_saveic ">
                                                                                                            <div className="flexc calender_cre_can">
                                                                                                                <button className="createN ft-wt500 mr-2" onClick={() => { foodId === null ? addFood() : updateFood() }}>{foodId === null ? "Save" : "Update"}</button>
                                                                                                                <button className="sp-view-btn ft-wt500 flexc cancel-btn" onClick={() => { closeAddFoodPopup(); }}>
                                                                                                                    Cancel
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Box>
                                                                                        </Popover >
                                                                                    </div>
                                                                                ) : foodData.attendance_status === 2 ? (
                                                                                    // <span className="notapp-ele" />
                                                                                    <img src={leave1} alt="" style={{ width: '16px' }} />
                                                                                ) : foodData.attendance_status > 2 ? (
                                                                                    // <span className="cross-ele" />
                                                                                    <img src={cross1} alt="" style={{ width: '16px' }} />
                                                                                ) : null}
                                                                            </li>
                                                                        )}
                                                                    </React.Fragment>
                                                                ))}
                                                            </ul>
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="no_bor_data">
                                            <center>
                                                <div className="sp-title flex-3">
                                                    {foodData.message}
                                                </div>
                                            </center>
                                        </div>
                                    )
                                ) : (
                                    <div className="data-loader extracss_load">
                                        {/* <Loader
                                            type="spinner-cub"
                                            bgColor="#4D6EE3"
                                            color="#6B6F8C"
                                            title="Loading..."
                                            size={35}
                                            /> */}
                                        <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                                        <p>Loading...</p>
                                    </div>
                                )
                            }
                        </div>
                    }
                </div>

                {/* Create New Student */}
                <div className="popup-modal-container" onSubmit={onSubmit}>
                    <div className="popup-wrap">
                        <h2 className="ft-wt700">Create New Student</h2>
                        <div className="sp-pop-header">
                            <h3 className="ft-wt600">Student Details</h3>
                            <div className="form-section-default flexc justifySp fwrap">
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Name<span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            ref={firstNameInputRef}
                                            type="text"
                                            value={user.first_name}
                                            onChange={(ev) =>
                                                setUser({ ...user, first_name: ev.target.value })
                                            }
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, genderInputRef)
                                            }
                                            className={`field-value-holder custamize_color_place  extra_bor_clr  sp-field-input ${validationErrors.first_name ? 'error' : ''}`}
                                            placeholder="Enter full name"
                                        />
                                    </div>
                                    {validationErrors.first_name && (
                                        <p className="error pos_abo_stu">{validationErrors.first_name}</p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus" style={{ padding: "0px" }}>
                                    <label>Gender<span className="col_red">*</span></label>
                                    <div
                                        className="field-value-holder dropbutton"
                                        ref={genderInputRef}
                                        style={{
                                            width: "100%",
                                            borderColor: validationErrors.gender ? "red" : borderColor.genderBorder,
                                        }}
                                        onKeyPress={(event) =>
                                            handleKeyPress(event, programInputRef)
                                        }
                                        onFocus={() => handleFocus("gender")}
                                        onBlur={handleBlur}
                                        tabIndex="0"
                                    >
                                        <Dropdown
                                            label={
                                                <span
                                                    className={`${selectedGender ? 'selected-item' : ''}`}
                                                    style={{ fontSize: "13px", justifyContent: "flex-start", color: selectedGender ? '#1B252F' : '#292929' }}
                                                >
                                                    {selectedGender ? selectedGender.value : "Select Gender"}
                                                </span>
                                            }
                                            style={{ width: "100%" }}
                                        >
                                            {gender1.map((item) => (
                                                <Dropdown.Item
                                                    key={item.id}
                                                    className={`${selectedGender && selectedGender.id === item.id ? "selected-item dd_select" : ""
                                                        }`}
                                                    icon={selectedGender && selectedGender.id === item.id ? HiCheck : null}
                                                    onClick={() => setUser({ ...user, gender: item.value })}
                                                >
                                                    {item.value}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown>
                                    </div>
                                    {validationErrors.gender && (
                                        <p className="error pos_abo_stu">{validationErrors.gender}</p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus date_pic_img">
                                    <label>
                                        Date of Birth<span className="col_red">*</span>
                                    </label>
                                    <div
                                        className={`field-value-holder ${validationErrors.dob ? 'error' : ''}`}>
                                        <div>
                                            <DatePicker
                                                ref={dobInputRef}
                                                placeholder="DD/MM/YYYY"
                                                format={dateFormatList}
                                                onChange={ondobChange}
                                                value={user.dob ? dayjs(user.dob, dateFormatList) : null}
                                            />

                                        </div>
                                    </div>
                                    {validationErrors.dob && <p className="error pos_abo_stu">{validationErrors.dob}</p>}
                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Programs<span className="col_red">*</span></label>
                                    <div
                                        ref={programInputRef}
                                        className="field-value-holder dropbutton"
                                        style={{
                                            width: "100%",
                                            borderColor: validationErrors.programs_id ? "red" : borderColor.programsBorder,
                                        }}
                                        onKeyPress={(event) =>
                                            handleKeyPress(event, studentInputRef)
                                        }
                                        onFocus={() => handleFocus("programs")}
                                        onBlur={handleBlur}
                                        tabIndex="0"
                                    >
                                        <Dropdown
                                            label={
                                                <span
                                                    className={`${selectedProgram ? 'selected-item' : ''}`}
                                                    style={{
                                                        fontSize: "13px",
                                                        color: selectedProgram ? '#1B252F' : '#292929'
                                                    }}
                                                >
                                                    {selectedProgram ? selectedProgram.program_name : "Select a Program"}
                                                </span>
                                            }
                                            style={{ width: "100%" }}
                                        >
                                            {programs.map((item) => (
                                                <Dropdown.Item
                                                    key={item.id}
                                                    className={`${selectedProgram && selectedProgram.id === item.id ? "selected-item dd_select" : ""}`}
                                                    icon={selectedProgram && selectedProgram.id === item.id ? HiCheck : null}
                                                    onClick={() => setUser({ ...user, programs_id: parseInt(item.id) })}
                                                >
                                                    {item.program_name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown>
                                    </div>
                                    {validationErrors.programs_id && (
                                        <p className="error pos_abo_stu">
                                            {validationErrors.programs_id}
                                        </p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus ">
                                    <label>Student Photo</label>
                                    <div className="field-value-holder">
                                        <span className="sp-upload-btn flexc justifySp" style={{
                                            color: " #A9AFC3",
                                            fontWeight: "500",
                                            fontSize: "13px"
                                        }}>
                                            {selectedImage === null ? (
                                                "Upload"
                                            ) : (
                                                <p style={{
                                                    color: " #1B252F",
                                                    fontWeight: "600",
                                                    fontSize: "13px"
                                                }} className="truncate"> {selectedImage.name}</p>
                                            )}
                                            <button className="sp-file-browse">Browse</button>
                                        </span>
                                        <input
                                            className="input-placeholder1"
                                            style={{
                                                cursor: "pointer"
                                            }}
                                            ref={imageInputRef}
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, joingInputRef)
                                            }
                                        />
                                    </div>
                                    {/* {validationErrors.profile_image && (
                    <p className="error">
                      {validationErrors.profile_image}
                    </p>
                  )} */}
                                </div>
                                <div className="field-label half-field error_mes_cus date_pic_img">
                                    <label>Joining Date</label>
                                    <div className="field-value-holder">
                                        <DatePicker
                                            ref={joingInputRef}
                                            placeholder="DD/MM/YYYY"
                                            value={user.joining_date ? dayjs(user.joining_date, dateFormatList) : null}
                                            format={dateFormatList}
                                            onChange={ondojChange}
                                        >
                                        </DatePicker>
                                    </div>
                                    {/* {validationErrors.joining_date && (
                    <p className="error">
                      {validationErrors.joining_date}
                    </p>
                  )} */}
                                </div>
                                <div className="field-label half-field error_mes_cus ">
                                    <label>Student ID</label>
                                    <div className="field-value-holder custamize_color_place">
                                        <input
                                            ref={studentInputRef}
                                            type="text"
                                            value={user.studentID}
                                            onChange={(ev) =>
                                                setUser({ ...user, studentID: ev.target.value })
                                            }
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, bloodInputRef)
                                            }
                                            className={`sp-field-input`}
                                            placeholder="001"
                                        />
                                    </div>
                                </div>
                                <div className="field-label half-field error_mes_cus " style={{ padding: "0px" }}>
                                    <label>Blood Group</label>
                                    <div className="field-value-holder dropbutton" ref={bloodInputRef}
                                        style={{
                                            width: "100%",
                                            borderColor: borderColor.bloodGroupBorder,
                                        }}
                                        onKeyPress={(event) =>
                                            handleKeyPress(event, adressInputRef)
                                        }
                                        onFocus={() => handleFocus("bloodGroup")}
                                        onBlur={handleBlur}
                                        tabIndex="0"
                                    >
                                        <Dropdown
                                            label={
                                                <span
                                                    style={{
                                                        fontSize: "0.8825em",
                                                        color: selectedBlood ? '#1B252F' : '#292929',
                                                        justifyContent: "flex-start"
                                                    }}
                                                >
                                                    {selectedBlood ? selectedBlood.value : "Select Blood Group"}
                                                </span>
                                            }
                                            style={{ width: "100%" }}
                                        >
                                            {Blood1.map((item) => (
                                                <Dropdown.Item
                                                    key={item.id}
                                                    className={`${selectedBlood && selectedBlood.id === item.id ? "selected-item dd_select" : ""
                                                        }`}
                                                    icon={selectedBlood && selectedBlood.id === item.id ? HiCheck : null}
                                                    onClick={() => setUser({ ...user, blood_group: item.value })}
                                                >
                                                    {item.value}
                                                </Dropdown.Item>
                                            ))}

                                        </Dropdown>
                                    </div>
                                    {/* {validationErrors.blood_group && (
                    <p className="error">
                      {validationErrors.blood_group}
                    </p>
                  )} */}
                                </div>
                                <div className="field-label full-field error_mes_cus">
                                    <label>Address<span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            type="text"
                                            ref={adressInputRef}
                                            value={user.address}
                                            onChange={(ev) =>
                                                setUser({ ...user, address: ev.target.value })
                                            }
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.address ? 'error' : ''}`}
                                            placeholder="Address"
                                            defaultValue={""}
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, fathernameInputRef)
                                            }
                                        />
                                    </div>
                                    {validationErrors.address && (
                                        <p className="error">{validationErrors.address}</p>
                                    )}
                                </div>
                            </div>
                            <h3 className="ft-wt600 mt-30">Father Details</h3>
                            <div className="form-section-default flexc justifySp fwrap">
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Name<span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            ref={fathernameInputRef}
                                            type="text"
                                            value={user.father_name}
                                            onChange={(ev) =>
                                                setUser({ ...user, father_name: ev.target.value })
                                            }
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.father_name ? 'error' : ''}`}
                                            placeholder="Enter Full name"
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, fatherphoneInputRef)
                                            }
                                        />
                                    </div>
                                    {validationErrors.father_name && (
                                        <p className="error pos_abo_stu">
                                            {validationErrors.father_name}
                                        </p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Phone Number<span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            ref={fatherphoneInputRef}
                                            type="text"
                                            value={user.primary_phone}
                                            onChange={(ev) =>
                                                setUser({ ...user, primary_phone: ev.target.value })
                                            }
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.primary_phone ? 'error' : ''}`}
                                            placeholder="Phone Number"
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, fatheremailInputRef)
                                            }
                                        />
                                    </div>
                                    {validationErrors.primary_phone && (
                                        <p className="error pos_abo_stu">
                                            {validationErrors.primary_phone}
                                        </p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus ">
                                    <label>Email</label>
                                    <div className="">
                                        <input
                                            ref={fatheremailInputRef}
                                            type="text"
                                            value={user.primary_email}
                                            onChange={(ev) =>
                                                setUser({ ...user, primary_email: ev.target.value })
                                            }
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, mathernameInputRef)
                                            }
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input`}
                                            placeholder="Enter your email"
                                        />
                                    </div>
                                    {/* {validationErrors.primary_email && (
                    <p className="error">
                      {validationErrors.primary_email}
                    </p>
                  )} */}
                                </div>
                            </div>
                            <h3 className="ft-wt600 mt-30">Mother Details</h3>
                            <div className="form-section-default flexc justifySp fwrap">
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Name<span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            ref={mathernameInputRef}
                                            type="text"
                                            value={user.mother_name}
                                            onChange={(ev) =>
                                                setUser({ ...user, mother_name: ev.target.value })
                                            }
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, matherphoneInputRef)
                                            }
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.mother_name ? 'error' : ''}`}
                                            placeholder="Enter Full name"
                                        />
                                    </div>
                                    {validationErrors.mother_name && (
                                        <p className="error pos_abo_stu">
                                            {validationErrors.mother_name}
                                        </p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Phone Number<span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            ref={matherphoneInputRef}
                                            type="text"
                                            value={user.secondary_phone}
                                            onChange={(ev) =>
                                                setUser({ ...user, secondary_phone: ev.target.value })
                                            }
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, matheremailInputRef)
                                            }
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.secondary_phone ? 'error' : ''}`}
                                            placeholder="Phone Number"
                                        />
                                    </div>
                                    {validationErrors.secondary_phone && (
                                        <p className="error pos_abo_stu">
                                            {validationErrors.secondary_phone}
                                        </p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus ">
                                    <label>Email</label>
                                    <div className="field-value-holder custamize_color_place">
                                        <input
                                            ref={matheremailInputRef}
                                            type="text"
                                            value={user.secondary_email}
                                            onChange={(ev) =>
                                                setUser({ ...user, secondary_email: ev.target.value })
                                            }
                                            className={`sp-field-input`}
                                            placeholder="Enter your email"
                                            onKeyDown={(event) =>
                                                handleKeyPress(event, submitButtonRef)
                                            }
                                        />
                                    </div>
                                    {/* {validationErrors.secondary_email && (
                    <p className="error">
                      {validationErrors.secondary_email}
                    </p>
                  )} */}
                                </div>
                            </div>
                        </div>
                        <div className="sp-bottom-sticky">
                            <button
                                className="sp-btn-create"
                                ref={submitButtonRef}
                                onClick={onSubmit}
                            >
                                Create
                            </button>
                            <button className="sp-btn-cancel">Cancel</button>
                        </div>
                    </div>
                </div>
                {/* my code */}
                {
                    // addFoodPopupVisible && (
                    //     <div className="dialog-model absolute selected food_sleap_lab" style={{ display: "block", zIndex: "999" }}>
                    //         <div className="sp-tt ft-14 flexc ft-wt600 food_head_style">{foodId === null ? "Add" : "Update"} Food</div>
                    //         <div className="form-section-default flexc justifySp fwrap">
                    //             <div className="field-label half-field error_mes_cus extra_pad_drop">
                    //                 <label>Food Time</label>
                    //                 <div
                    //                     className="field-value-holder margin_top10p dropbutton"
                    //                     ref={foodInputRef}
                    //                     style={{
                    //                         width: "100%",
                    //                         borderColor: foodTimeError ? "red" : borderColor.timeBorder,
                    //                     }}
                    //                     onFocus={() => handleFocus("timeBorder")}
                    //                     onBlur={handleBlur}
                    //                 >
                    //                     <Dropdown
                    //                         // label={<span style={{ color: 'black' }}>{selectedFoodTime ? selectedFoodTime.time_name : "Select Time"}</span>}
                    //                         label={
                    //                             <span
                    //                                 style={{
                    //                                     fontSize: "0.8825em",
                    //                                     color: selectedFoodTime ? '#1B252F' : '#292929',
                    //                                     justifyContent: "flex-start"
                    //                                 }}
                    //                             >
                    //                                 {selectedFoodTime ? selectedFoodTime.time_name : "Select Time"}
                    //                             </span>
                    //                         }
                    //                         style={{ width: '100%' }}
                    //                     >
                    //                         {foodTimeData.map(item => (
                    //                             <Dropdown.Item
                    //                                 key={item.id}
                    //                                 className={`${selectedFoodTime && selectedFoodTime.id === item.id ? "selected-item dd_select" : ""
                    //                                     }`}
                    //                                 icon={selectedFoodTime && selectedFoodTime.id === item.id ? HiCheck : null}
                    //                                 onClick={() => setFoodDetails({ ...foodDetails, foodTime: item.id })}
                    //                             >
                    //                                 {item.time_name}
                    //                             </Dropdown.Item>
                    //                         ))}
                    //                     </Dropdown>
                    //                 </div>
                    //                 {foodTimeError && <p className="error">{foodTimeError}</p>}
                    //             </div>
                    //             <div className="field-label half-field dropbutton error_mes_cus">
                    //                 <label>Quantity</label>
                    //                 <div
                    //                     className="field-value-holder margin_top10p"
                    //                     style={{
                    //                         width: "100%",
                    //                         borderColor: quantityError ? "red" : borderColor.quantityBorder,
                    //                     }}
                    //                     onFocus={() => handleFocus("quantityBorder")}
                    //                     onBlur={handleBlur}
                    //                 >

                    //                     <Dropdown
                    //                         label={<span
                    //                             style={{
                    //                                 fontSize: "0.8825em",
                    //                                 color: selectedQuantity ? '#1B252F' : '#292929',
                    //                                 justifyContent: "flex-start"
                    //                             }}
                    //                         >
                    //                             {selectedQuantity ? selectedQuantity.quantity_name : "Select Quantity"}
                    //                         </span>}
                    //                         style={{ width: '100%' }}
                    //                     >
                    //                         {quantityData.map(item => (
                    //                             <Dropdown.Item
                    //                                 className={`${selectedQuantity && selectedQuantity.id === item.id ? "selected-item dd_select" : ""
                    //                                     }`}
                    //                                 // className={selectedQuantity && selectedQuantity.id === item.id ? "dd_select" : null}
                    //                                 icon={selectedQuantity && selectedQuantity.id === item.id ? HiCheck : null}
                    //                                 onClick={() => setFoodDetails({ ...foodDetails, quantity: item.id })}
                    //                                 key={item.id}
                    //                             >
                    //                                 {item.quantity_name}
                    //                             </Dropdown.Item>
                    //                         ))}
                    //                     </Dropdown>
                    //                 </div>
                    //                 {quantityError && <p className="error">{quantityError}</p>}
                    //             </div>
                    //             <div className="field-label half-field dropbutton error_mes_cus">
                    //                 <label>Type</label>
                    //                 <div
                    //                     className="field-value-holder margin_top10p"
                    //                     style={{
                    //                         width: "100%",
                    //                         borderColor: foodTypeError ? "red" : borderColor.typeBorder,
                    //                     }}
                    //                     onFocus={() => handleFocus("typeBorder")}
                    //                     onBlur={handleBlur}
                    //                 >
                    //                     <Dropdown
                    //                         label={
                    //                             <span
                    //                                 style={{
                    //                                     fontSize: "0.8825em",
                    //                                     color: selectedFoodType ? '#1B252F' : '#292929',
                    //                                     justifyContent: "flex-start"
                    //                                 }}>
                    //                                 {selectedFoodType ? selectedFoodType.type_name : "Select Type"}</span>}
                    //                         style={{ width: '100%' }}
                    //                     >
                    //                         {foodTypeData.map(item => (
                    //                             <Dropdown.Item
                    //                                 className={`${selectedFoodType && selectedFoodType.id === item.id ? "selected-item dd_select" : ""
                    //                                     }`}
                    //                                 // className={selectedFoodType && selectedFoodType.id === item.id ? "dd_select" : null}
                    //                                 icon={selectedFoodType && selectedFoodType.id === item.id ? HiCheck : null}
                    //                                 onClick={() => setFoodDetails({ ...foodDetails, foodType: item.id })}
                    //                                 key={item.id}
                    //                             >
                    //                                 {item.type_name}
                    //                             </Dropdown.Item>
                    //                         ))}
                    //                     </Dropdown>
                    //                 </div>
                    //                 {foodTypeError && <p className="error">{foodTypeError}</p>}
                    //             </div>
                    //             <div className="field-label half-field dropbutton error_mes_cus">
                    //                 <label>Category</label>
                    //                 <div
                    //                     className="field-value-holder margin_top10p"
                    //                     style={{
                    //                         width: "100%",
                    //                         borderColor: categoryError ? "red" : borderColor.categoryBorder,
                    //                     }}
                    //                     onFocus={() => handleFocus("categoryBorder")}
                    //                     onBlur={handleBlur}

                    //                 >
                    //                     <Dropdown
                    //                         label={
                    //                             <span
                    //                                 style={{
                    //                                     fontSize: "0.8825em",
                    //                                     color: selectedCategory ? '#1B252F' : '#292929',
                    //                                     justifyContent: "flex-start"
                    //                                 }}
                    //                             >
                    //                                 {selectedCategory ? selectedCategory.category_name : "Select Category"}
                    //                             </span>}
                    //                         style={{ width: '100%' }}
                    //                     >
                    //                         {foodCategoryData.map(item => (
                    //                             <Dropdown.Item
                    //                                 className={`${selectedCategory && selectedCategory.id === item.id ? "selected-item dd_select" : ""
                    //                                     }`}
                    //                                 // className={selectedCategory && selectedCategory.id === item.id ? "dd_select" : null}
                    //                                 icon={selectedCategory && selectedCategory.id === item.id ? HiCheck : null}
                    //                                 onClick={() => setFoodDetails({ ...foodDetails, category: item.id })}
                    //                                 key={item.id}
                    //                             >
                    //                                 {item.category_name}
                    //                             </Dropdown.Item>
                    //                         ))}
                    //                     </Dropdown>
                    //                 </div>
                    //                 {categoryError && <p className="error">{categoryError}</p>}
                    //             </div>
                    //             <div className="field-label full-field dropbutton error_mes_cus">
                    //                 <label>Description</label>
                    //                 <div className="field-value-holder margin_top10p">
                    //                     <textarea

                    //                         value={foodDetails.description}
                    //                         // type="text"
                    //                         onChange={ev => setFoodDetails({ ...foodDetails, description: ev.target.value })}
                    //                         className="sp-field-input fixed-height-textarea"
                    //                         placeholder="Description"
                    //                     />
                    //                 </div>
                    //                 {/* {descriptionError && <p className="error">{descriptionError}</p>} */}
                    //             </div>
                    //             <div className="flexc mt-4 food_sleep_saveic ">
                    //                 <div className="flexc calender_cre_can">
                    //                     <button className="createN ft-wt500 mr-2" onClick={() => { foodId === null ? addFood() : updateFood() }}>{foodId === null ? "Save" : "Update"}</button>
                    //                     <button className="sp-view-btn ft-wt500 flexc cancel-btn" onClick={() => closeAddFoodPopup()}>
                    //                         Cancel
                    //                     </button>
                    //                 </div>
                    //             </div>
                    //         </div>
                    //     </div>
                    // )
                }

                {/* Recycle Bin Modal */}
                {
                    isRecycleBinVisible && (
                        <div
                            id="recycle-popup"
                            tabIndex={-1}
                            className="back_overlay fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full  max-h-full res_tot_div"
                        >
                            <div className="relative w-full max-w-xs max-h-full">
                                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4">   <div className="p-4 md:p-5 text-center">
                                    <div className="round-btn rounded inline-flex alignC justify-center">
                                        <img src={de} alt="" />
                                    </div>
                                    <p className="ft-wt600 ft-16 mb-4 mt-3">Recycle Bin</p>
                                    <h3 className="mb-5 font-normal text-sm">
                                        Are you sure you want to move this program to Recycle Bin?
                                    </h3>
                                    <button
                                        onClick={() => setDeleteProgramConfirmVisible(true)}
                                        type="button"
                                        className="yes_button_sta_cus sp-red-color text-white  hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 rounded-md  inline-flex items-center px-5 py-2.5 text-center"
                                    >
                                        Yes, Move
                                    </button>
                                    <button
                                        onClick={handleRecycleBinClose}
                                        type="button"
                                        style={{
                                            border: "1px solid #D7D9DD",
                                        }}
                                        className="can_cus_rec border-full ms-3 py-2.5 px-5  text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700"
                                    >
                                        Cancel
                                    </button>

                                </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                {/* Create New Program */}
                {
                    newProgramPopupVisible && (
                        <div className="popup-program-container visible">
                            <div className="popup-wrap">
                                <h2 className="ft-wt700">{newProgramAddUpdateStatus === "add" ? "Create" : "Update"} New Program</h2>
                                <div className="sp-pop-header ">
                                    <div className="form-section-default flexc justifySp fwrap">
                                        <div className="field-label full-field error_mes_cus  pos_stu_cus">
                                            <label>Program Name</label>
                                            <div className="">
                                                <input
                                                    className={`field-value-holder margin_top10p custamize_color_place  extra_bor_clr  sp-field-input ${programeNameError ? 'error' : ''}`}
                                                    // className="sp-field-input"
                                                    type="text"
                                                    placeholder="Enter Program name"
                                                    value={newProgramName}
                                                    onChange={(e) => setNewProgramName(e.target.value)}
                                                />
                                            </div>
                                            {programeNameError && <p className="error pos_abo_stu">{programeNameError}</p>}
                                        </div>
                                        <div className="field-label half-field error_mes_cus extra_pad_drop">
                                            <label>Program Teachers</label>
                                            <div className="field-value-holder margin_top10p dropbutton pos_stu_cus"
                                                style={{
                                                    width: "100%",
                                                    borderColor: programeTeacherError ? "red" : borderColor.newProgram,
                                                }}
                                            >
                                                <Dropdown
                                                    label={
                                                        <span
                                                            className={`${selectedTeachers ? 'selected-item' : ''}`}
                                                            style={{
                                                                fontSize: "13px",
                                                                justifyContent: "flex-start",
                                                                color: selectedTeachers ? '#1B252F' : '#292929'
                                                            }}
                                                        >
                                                            {selectedTeachers ? selectedTeachers.first_name : "Select Teacher"}</span>}
                                                    style={{ width: '100%' }}
                                                >
                                                    {teachersData.map(item => (
                                                        <Dropdown.Item
                                                            // className={selectedTeachers && selectedTeachers.id === item.id ? "dd_select" : null}
                                                            className={`${selectedTeachers && selectedTeachers.id === item.id ? "selected-item dd_select" : ""
                                                                }`}
                                                            icon={selectedTeachers && selectedTeachers.id === item.id ? HiCheck : null}
                                                            onClick={() => setTeacher(item.id)}
                                                            key={item.id}
                                                        >
                                                            {item.first_name}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown>

                                            </div>
                                            {programeTeacherError && <p className="error pos_abo_stu">{programeTeacherError}</p>}
                                        </div>
                                        <div className="field-label half-field error_mes_cus extra_date_drop">
                                            <label>Class Timing</label>
                                            <div className="flexc justifySp">
                                                <div className="half-field">
                                                    <div className="field-value-holder margin_top10p dropbutton cus_drop_siz" >
                                                        <Dropdown
                                                            label={


                                                                <span
                                                                    className={`${selectedStartTime ? 'selected-item' : ''}`}
                                                                    style={{
                                                                        fontSize: "12px",
                                                                        justifyContent: "flex-start",
                                                                        color: selectedStartTime ? '#1B252F' : '#292929'
                                                                    }}
                                                                >

                                                                    {/* <span style={{ color: 'black', fontSize: '12px' }}> */}



                                                                    <i class="fa-regular fa-clock" style={{ marginRight: '6PX' }}></i>
                                                                    {selectedStartTime ? selectedStartTime.time_name : "Start Time"}</span>}
                                                            style={{
                                                                width: "100%"
                                                            }}
                                                        >
                                                            {timeData.map(item => (
                                                                <Dropdown.Item

                                                                    //className={selectedStartTime && selectedStartTime.id === item.id ? "dd_select" : null}

                                                                    className={`${selectedStartTime && selectedStartTime.id === item.id ? "selected-item dd_select" : ""
                                                                        }`}
                                                                    icon={selectedStartTime && selectedStartTime.id === item.id ? HiCheck : null}
                                                                    onClick={() => setProgramStartTime(item.id)}
                                                                    key={item.id}
                                                                >
                                                                    {item.time_name}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <div className="half-field">
                                                    <div className="field-value-holder margin_top10p dropbutton cus_drop_siz" >
                                                        <Dropdown
                                                            label={


                                                                //  <span style={{ color: 'black', fontSize: '12px' }}>

                                                                <span
                                                                    className={`${selectedEndTime ? 'selected-item' : ''}`}
                                                                    style={{


                                                                        fontSize: "13px",
                                                                        fontWeight: "400",
                                                                        lineHeight: "18.2px",
                                                                        textAlign: "left",

                                                                        justifyContent: "flex-start",
                                                                        color: selectedEndTime ? '#1B252F' : '#292929'
                                                                    }}
                                                                >



                                                                    <i class="fa-regular fa-clock" style={{ marginRight: '6PX' }}>
                                                                    </i>{selectedEndTime ? selectedEndTime.time_name : "End Time"}</span>}
                                                            style={{
                                                                width: "100%"
                                                            }}
                                                        >
                                                            {timeData.map(item => (
                                                                <Dropdown.Item
                                                                    // className={selectedEndTime && selectedEndTime.id === item.id ? "dd_select" : null}
                                                                    className={`${selectedEndTime && selectedEndTime.id === item.id ? "selected-item dd_select" : ""
                                                                        }`}

                                                                    icon={selectedEndTime && selectedEndTime.id === item.id ? HiCheck : null}
                                                                    onClick={() => setProgramEndTime(item.id)}
                                                                    key={item.id}
                                                                >
                                                                    {item.time_name}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            {timeError && <p className="error">{timeError}</p>}
                                        </div>
                                    </div>

                                    <h3 className="ft-wt600 mt-30">Customise Features</h3>
                                    <div className="full-field mb-6">
                                        {Object.keys(features).map((feature) => (
                                            <div className="flex items-center mb-4 custom-checkbox dis_chek_cus mt-5" key={feature}>
                                                <input
                                                    type="checkbox"
                                                    className="w-4 h-4 text-blue-600 rounded"
                                                    checked={features[feature].status}
                                                    disabled={
                                                        (['qrCode', 'attendance', 'food', 'sleep', 'leaves'].includes(feature))
                                                        // features[feature].status=== true
                                                    }
                                                    onChange={() => handleFeatureChange(feature)}
                                                />
                                                <label className="ms-2 text-sm">{features[feature].name}</label>
                                                {['busTracking', 'cctv', 'fees'].includes(feature) && <img src={SplImg} alt="spl" style={{ width: '25px', height: '15px', marginLeft: "5px" }} />}
                                            </div>
                                        ))}
                                    </div>
                                    <h3 className="ft-wt600 mt-30">Additional Informations</h3>
                                    <div className="full-field">
                                        {Object.keys(additionalInfo).map((info) => (
                                            <div className="flex items-center mb-4 custom-checkbox mt-5" key={info}>
                                                <input
                                                    type="checkbox"
                                                    className="w-4 h-4 text-blue-600 rounded"
                                                    checked={additionalInfo[info].status}
                                                    onChange={() => handleAdditionalInfoChange(info)}
                                                />
                                                <label className="ms-2 text-sm">{additionalInfo[info].name}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="sp-bottom-sticky">
                                    <button className="sp-btn-create crt_div" onClick={() => handleCreateProgram()}>{newProgramAddUpdateStatus === "add" ? "Create" : "Update"}</button>
                                    <button className="sp-btn-cancel">Cancel</button>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    deleteProgramConfirmVisible && (
                        <div
                            id="recycle-popup"
                            tabIndex={-1}
                            className="back_overlay fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full  max-h-full res_tot_div"
                        >
                            <div className="relative w-full max-w-xs max-h-full">
                                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4">   <div className="p-4 md:p-5 text-center">
                                    <div className="round-btn rounded inline-flex alignC justify-center">
                                        <img src={attpopup} alt="" />
                                    </div>
                                    {/* <p className="ft-wt600 ft-16 mb-4 mt-3">Mark as {attendanceStatus === 1 ? "Present" : attendanceStatus === 2 ? "Absent" : "Leave"}</p> */}
                                    <h3 className="mb-5 font-normal text-sm">
                                        Are you sure you want to delete the program
                                    </h3>
                                    <div className="att-popup">
                                        <button
                                            onClick={handleDeleteProgramConfirmClose}
                                            type="button"
                                            className="att-cancel can_cus_rec border-full ms-3 py-2.5 px-5  text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            // onClick={()=>{setCheckProgramChangeStatus(true); updateUser();}}
                                            onClick={() => handleDeleteProgramConfirm()}
                                            type="button"
                                            className="save_button_cus sp-blue-color text-white ms-3 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 rounded-md ft-13 inline-flex items-center px-5 py-2.5 text-center save_cus_di"
                                        >
                                            Yes, Proceed
                                        </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </section>
    );

}

const CustomPopover = styled(Popover)`
  border: 1px solid #d9dce6 !important;
  border-radius: 10px !important;
  box-shadow: 0 8px 24px 0 #0000000f !important;
  transform-origin: none !important;
  transition: none !important;
`;
