import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import ListItemIcon from '@mui/material/ListItemIcon';
import SuccessIcon from './images/success.svg';
import ErrorIcon from './images/error.svg';
import file from "./images/fileupload.svg";
import axios from "axios";
import { baseUrl } from "../../common/common";
import filec from "./images/fileupload-c.svg";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import attpopup from "./images/attpopup.svg";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function Import({ importStudent, handleImportStatus, setProgress, setIsLoading }) {
  
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [fileMessage, setFileMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleSnackbarOpen = () => {
    setSnackbarState({ ...snackbarState, open: true });
  };

  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  useEffect(() => { }, [fileMessage, messageType]);

  const errors = [
    "React lets you build user interfaces",
    "Create your own React components",
    "Then combine them into entire screens, pages, and apps."
  ];
  const importStudents = async (event) => {
    const importFile = event.target.files[0];
    const formData = new FormData();
    formData.append("file", importFile);
    let response;
    setIsLoading(true);
    setProgress(0);

    if (importStudent === "Student") {
      formData.append("programs_id", localStorage.getItem("programId"));
      formData.append("school_id", localStorage.getItem("schoolId"));
      response = await axios.post(baseUrl + "/api/student_import", formData, {
        headers: { "Content-Type": "multipart/form-data","Authorization": localStorage.getItem('access_token')},
        onUploadProgress: (progressEvent) => {
          setProgress((progressEvent.loaded / progressEvent.total) * 100);
        }
      });
      //  response = {
      //   data: {
      //     status: false,
      //     message: "Students imported with errors",
      //     errors: [
      //       "Row 2 has empty fields. Skipping...",
      //       "Row 4 has empty fields. Skipping..."
      //     ]
      //   },
      //   onUploadProgress: (progressEvent) => {
      //     setProgress((progressEvent.loaded / progressEvent.total) * 100);
      //   }
      // };

      // response = {
      //   data: {
      //     status: true,
      //     message: "Students imported sucessfully",
      //     errors: []
      //   }
      // };
    } else if (importStudent === "staff") {
      console.log("staff");
      formData.append("school_id", localStorage.getItem("schoolId"));
      response = await axios.post(baseUrl + "/api/staff_import", formData, {
        headers: { "Content-Type": "multipart/form-data", "Authorization": localStorage.getItem('access_token')},
        onUploadProgress: (progressEvent) => {
          setProgress((progressEvent.loaded / progressEvent.total) * 100);
        }
      });
    }
    if (response.data.status === true) {
      handleImportStatus(true);
      setMessageType('success');
      setFileMessage(response.data.message);
      handleSnackbarOpen();
    } else {
      if(response.data.errors!==null)
      {
        const formattedErrors = response.data.errors.map((error, index) => `${index + 1}. ${error}`).join(', ');
        setErrorMessage(formattedErrors);
      }
      setFileMessage(response.data.message);     
      setImportErrorVisible(true);
      // handleImportStatus(false);
      // setMessageType('error');
      // setFileMessage(formattedErrors);      
      // setErrorMessage(response.data.errors);
      // handleSnackbarOpen();
      // handleModalOpen();
    }

    setIsLoading(false);
    setProgress(100);
  };

    // -------------------09-09-------------

    const [importErrorVisible, setImportErrorVisible] = useState(false);

    const importErrorClose = () => {
      setImportErrorVisible(false);
      setErrorMessage("");
      handleImportStatus(true);
    };

  return (
    <section className="import_cust">
      {/* <Snackbar className="snack_total"
        anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
        open={snackbarState.open}
        onClose={handleSnackbarClose}
        message={fileMessage}
        ContentProps={{
          sx: {
            border: "1px solid",
            opacity: "1",
            background: messageType === 'success' ? "#40BE61" : "#FD4954",
            borderColor: messageType === 'success' ? "#40BE61" : "#FD4954"
          }
        }}
        autoHideDuration={3000}
      /> */}
      <Snackbar
        className="snack_total"
        anchorOrigin={{
          vertical: snackbarState.vertical,
          horizontal: snackbarState.horizontal,
        }}
        open={snackbarState.open}
        onClose={handleSnackbarClose}
        message={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ListItemIcon>
              <img
                src={messageType === 'success' ? SuccessIcon : ErrorIcon}
                alt="Success"
                style={{ width: '24px', height: '24px' }}
              />
            </ListItemIcon>
            <div className="success_cuc">
              <p> {fileMessage} </p>
            </div>
          </div>
        }
        ContentProps={{
          sx: {
            border: '1px solid',
            opacity: '1',
            background: messageType === 'success' ? '#40BE61' : '#FD4954',
            borderColor: messageType === 'success' ? '#40BE61' : '#FD4954',
            width: '300px',
          },
        }}
        autoHideDuration={30000}
      />
      {/* <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ fontWeight: "600" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: '#000' }}>
              Errors
            </Typography>
          </div>
          <Typography id="modal-modal-description" sx={{ mt: 2, color: '#000' }}>
            {errorMessage.length > 0 ? (
              <ul>
                {errorMessage.map((error, index) => (
                  <li key={index}>
                    <Typography>{index + 1}. {error}</Typography>
                  </li>
                ))}
              </ul>
            ) : (
              <Typography>No errors found.</Typography>
            )}
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: "20px", width: "100%" }}><Button onClick={handleModalClose}>Close</Button></div>
        </Box>
      </Modal> */}
      <div className="outer-container">
        <div className="overlay" />
        <div className="load-container">
          <div className="sp-table-import y-auto flexc justify-center">
            <div className="sp-import-container">
              <section className="sp-top-box" id="upload-dropbox-zone">
                <img src={file} width={58} height={60} className="file-img" />
                <div className="ft-13 clr-grey line-h-4 mt-4">
                  Drag and drop file here or
                  <span className="relative clr-theme1 ft-wt500" style={{ marginLeft: "3px" }}>
                    <input
                      type="file"
                      title=""
                      multiple=""
                      accept=".jpg, .jpeg, .png, .webp, .dmg"
                      aria-labelledby="upload-dropbox-zone"
                      onChange={importStudents}
                    />
                    Choose file
                  </span>
                  <span className="ft-12 block">support XLSX files only</span>
                </div>
                <div className="flexc justify-center mt-6">
                  <div className="file-input-container">
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      accept=".xlsx"
                      onChange={importStudents}
                    />
                    <label
                      className="createN ft-wt500 flexc"
                      htmlFor="fileInput"
                    >
                      <i className="icon-Import-Outline" />
                      Import
                    </label>
                  </div>
                  <button
                    className="sp-view-btn ft-wt500 mr-lf10"
                    id="newStudentCreate"
                  >
                    Add Manually
                  </button>
                </div>
              </section>
              <section className="sp-bt-box mt-5">
                <div className="flexc justifySp">
                  <div className="">
                    <h4 className="ft-13 ft-wt600 flexc">
                      <img
                        src={filec}
                        width={28}
                        height={60}
                        className="file-col mr-2"
                      />
                      Table Sample
                    </h4>
                    <p className="ft-13 mt-2 line-h-4">
                      You can download the attached example and use them as a
                      starting point of your own file.
                    </p>
                  </div>
                  {importStudent === "Student" ? (
                    <button
                      className="sp-view-btn ft-wt500 mr-lf10"
                      onClick={() =>
                        (window.location.href = "/sample_file.xlsx")
                      }
                    >
                      Download
                    </button>
                  ) : (
                    <button
                      className="sp-view-btn ft-wt500 mr-lf10"
                      onClick={() => (window.location.href = "/Staff.xlsx")}
                    >
                      Download
                    </button>
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      {/* move Program confirm Modal */}
       {
           importErrorVisible && (
              <div
                id="recycle-popup"
                tabIndex={-1}
                className="back_overlay fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full  max-h-full res_tot_div"
              >
                <div className="relative w-full max-w-xs max-h-full">
                  <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4">   <div className="p-4 md:p-5 text-center">
                    <div className="round-btn rounded inline-flex alignC justify-center">
                      <img src={attpopup} alt="" />
                    </div>
                    {/* <p className="ft-wt600 ft-16 mb-4 mt-3">Mark as {attendanceStatus === 1 ? "Present" : attendanceStatus === 2 ? "Absent" : "Leave"}</p> */}
                    <p className="ft-wt600 ft-16 mb-4 mt-3">{fileMessage}</p>
                    <h3 className="mb-5 font-normal text-sm">
                    {errorMessage}
                    </h3>
                    <div className="att-popup">
                      {/* <button
                        onClick={importErrorClose}
                        type="button"
                        className="att-cancel can_cus_rec border-full ms-3 py-2.5 px-5  text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700"
                      >
                        Cancel
                      </button> */}
                      <button
                        // onClick={()=>{setCheckProgramChangeStatus(true); updateUser();}}
                        onClick={importErrorClose}
                        type="button" 
                        style={{margin:0}}
                        className="save_button_cus sp-blue-color text-white ms-3 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 rounded-md ft-13 inline-flex items-center px-5 py-2.5 text-center save_cus_di"
                      >
                        Okey
                      </button>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            )
          }
    </section>
  );
}
