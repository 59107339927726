import React, { useState, useRef, useEffect } from "react";
import axios from 'axios';
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../common/common";
import '../../common/components/css/style.css';
import avatar1 from "./images/avatar1.png";
import Snackbar from '@mui/material/Snackbar';
import ListItemIcon from '@mui/material/ListItemIcon';
import SuccessIcon from "./images/success.svg";
import ErrorIcon from "./images/error.svg";
import { Dropdown } from "flowbite-react";
import { HiCheck } from "react-icons/hi";
import SplImg from "./images/spl.png";
import tik from "./images/tik.svg";
import cross from "./images/cross.svg";
import leave from "./images/leave.svg";
import leave1 from "./images/leave1.svg";
import tik1 from "./images/tik1.svg";
import cross1 from "./images/cross1.svg";
import ImportComponent from "../Import/Import";
import { WindmillSpinner } from 'react-spinner-overlay'
import moment from 'moment';
import validator from "validator";
import { DatePicker } from "antd";
import dayjs from 'dayjs';
import editIcon from "./images/edit-icon.svg";
import deleteIcon from "./images/delete-icon.svg";
import de from "./images/de.svg";
import attpopup from "./images/attpopup.svg";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import Sidebar from "../../common/layouts/CommonSidebar.jsx";
import { LogoComponent } from "../school/Common.jsx";
import CommonSidebarHeader from "../../common/layouts/CommonSidebarHeader.jsx";

export default function StudentAttendance() {

    const navigate = useNavigate();

    const [programsData, setProgramsData] = useState({});
    const [programsLoading, setProgramsLoading] = useState(false);

    const fetchProgramsData = async () => {
        setProgramsLoading(false);
        try {
            const postData = {
                school_id: localStorage.getItem('schoolId')
                // school_id:24
            }
            const { data } = await axios.post(baseUrl + "/api/get_programs", postData, {
                headers: {
                    "Authorization": localStorage.getItem('access_token')
                }
            });
            setProgramsData(data.data);
            setProgramsLoading(true);
        } catch (error) {
            console.log(error)
        }
    }

    const [foodData, setFoodData] = useState({});
    const [dataHeader, setDataHeader] = useState([]);
    const [foodHeaderdata, setFoodHeaderData] = useState([]);
    const [foodLoading, setFoodLoading] = useState(false);
    const [filterValue, setFilterValue] = useState('');
    const handleInputChange = async (event) => {
        setFilterValue(event.target.value);
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/student_list_admin",
                data: {
                    school_id: localStorage.getItem('schoolId'),
                    programs_id: localStorage.getItem('programId'),
                    search: event.target.value.trim(),
                    list_status: 1,
                    previous_week: 0
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response['data']['status'] === true) {
                    setFoodData(response.data);
                    setDataHeader(response.data.dateresult);
                    setFoodHeaderData(response.data.data);
                    setFoodLoading(true);
                } else {
                    setFoodData(response.data);
                    setFoodLoading(true);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };
    /* 31-07-2024 */
    const clearInput = () => {
        setFilterValue('');
        fetchAttendanceData();
    };
    const fetchAttendanceData = () => {
        setFoodLoading(false);
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/student_list_admin",
                data: {
                    school_id: localStorage.getItem('schoolId'),
                    programs_id: localStorage.getItem('programId'),
                    search: "",
                    list_status: 1,
                    previous_week: 0
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response['data']['status'] === true) {
                    setFoodData(response.data);
                    setDataHeader(response.data.dateresult);
                    setFoodHeaderData(response.data.data);
                    setFoodLoading(true);
                } else {
                    setFoodData(response.data);
                    setStudentCount(false);
                    setFoodLoading(true);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const [selectedStudents, setSelectedStudents] = useState([]);

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allStudentIds = foodHeaderdata.map(student => student.id);
            setSelectedStudents(allStudentIds);
        } else {
            setSelectedStudents([]);
        }
    };

    const handleSelectStudent = (studentId) => {
        setSelectedStudents(prevSelected =>
            prevSelected.includes(studentId)
                ? prevSelected.filter(id => id !== studentId)
                : [...prevSelected, studentId]
        );
    };

    const [error, setError] = useState(null);
    const addAttendance = () => {
        const today = new Date();
        if (selectedStudents.length === 0) {
            // setError("Please Select Student")
            // setOpen(true);
            setSnackbarMessage("Please Select Student");
            setMessageType('error');
            handleSnackbarOpen();
        } else {
            const postData = {
                school_id: localStorage.getItem('schoolId'),
                attendance_date: today.toISOString().split('T')[0],
                programs_id: localStorage.getItem('programId'),
                student_ids: selectedStudents,
                attendance_status: attendanceStatus,
                status: 1,
                created_by: localStorage.getItem('loginUserId'),
                updated_by: localStorage.getItem('loginUserId')
            }
            console.log(postData)
            axios({
                method: "post",
                url: baseUrl + "/api/std_attendance_add_edit",
                data: postData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    setSnackbarMessage(response.data.message);
                    setMessageType('success');
                    handleSnackbarOpen();
                    setSelectedStudents([]);
                    // window.location.reload();
                    handleAttendanceClose();
                    fetchAttendanceData();
                } else {
                    setSnackbarMessage(response.data.message);
                    setMessageType('error');
                    handleSnackbarOpen();
                }
            });
        }

    }

    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    // ----------------------------------11-06----------------
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [snackbarState, setSnackbarState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center'
    });
    const handleSnackbarClose = () => {
        setSnackbarState({ ...snackbarState, open: false });
    };
    const handleSnackbarOpen = () => {
        setSnackbarState({ ...snackbarState, open: true });
    };
    useEffect(() => { }, [snackbarMessage, messageType]);
    // --------------------------------------------------------
    // -------------------13-06-------------------------------
    const [studentCount, setStudentCount] = useState(true);

    // -------------------------19-06-------------------------

    // const handleOpenNewProgramPopupVisible = () => {
    //     setProgramNameError(null);
    //     setProgramTeacherError(null);
    //     setTimeError(null);
    //     setTeacher("");
    //     setProgramStartTime("");
    //     setProgramEndTime("");
    //     setNewProgramPopupVisible(true);

    const [newProgramAddUpdateStatus, setNewProgramAddUpdateStatus] = useState("");
    const handleOpenNewProgramPopupVisible = (status) => {
        setNewProgramAddUpdateStatus(status);
        if (status === "add") {
            setProgramNameError(null);
            setProgramTeacherError(null);
            setTimeError(null);
            resetFeaturesAndAdditionalInfo();
            setTeacher("");
            setNewProgramName("");
            setProgramStartTime("");
            setProgramEndTime("");
            setEditProgramVisible(false);
            setNewProgramPopupVisible(true);
        } else {
            fetchEditProgramDetails();
        }

    }

    const fetchEditProgramDetails = async () => {
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/get_programs",
                data: {
                    school_id: localStorage.getItem("schoolId"),
                    id: localStorage.getItem('programId')
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    const programData = response.data.data[0];
                    setTeacher(response.data.teacher);
                    setNewProgramName(programData.program_name);
                    setProgramStartTime(programData.from_time_id);
                    setProgramEndTime(programData.to_time_id);
                    if (programData.features !== null && programData.additional_info !== null) {
                        // Set features
                        const featureIds = programData.features.split(',');
                        const updatedFeatures = { ...features };
                        featureIds.forEach(id => {
                            const featureKey = Object.keys(features).find(key => features[key].value.toString() === id);
                            if (featureKey) {
                                updatedFeatures[featureKey].status = true;
                            }
                        });
                        setFeatures(updatedFeatures);
                        // Set additional info
                        const additionalInfoIds = programData.additional_info.split(',');
                        const updatedAdditionalInfo = { ...additionalInfo };
                        additionalInfoIds.forEach(id => {
                            const infoKey = Object.keys(additionalInfo).find(key => additionalInfo[key].value.toString() === id);
                            if (infoKey) {
                                updatedAdditionalInfo[infoKey].status = true;
                            }
                        });
                        setAdditionalInfo(updatedAdditionalInfo);
                    }
                    setProgramNameError(null);
                    setProgramTeacherError(null);
                    setTimeError(null);
                    $('.overlay').addClass('visible');
                    $('.popup-program-container').addClass('visible');
                    setEditProgramVisible(false);
                    setNewProgramPopupVisible(true);
                }
            });
        } catch (error) {
            console.error('An error occurred while fetching program details:', error);
            // Optionally, you can set a general error message to state here
        }
    }

    // -------------------31-08--------------

    const [deleteProgramConfirmVisible, setDeleteProgramConfirmVisible] = useState(false);
    const handleDeleteProgramConfirmClose = () => {
        setDeleteProgramConfirmVisible(false);
    };

    const handleDeleteProgramConfirm = () => {
        // setCheckProgramChangeStatus(true);
        setDeleteProgramConfirmVisible(false);
        handleRecycleBin();
    };

    const [newProgramPopupVisible, setNewProgramPopupVisible] = useState(false);
    const [programStartTime, setProgramStartTime] = useState("");
    const [programEndTime, setProgramEndTime] = useState("");
    const [programTeacher, setTeacher] = useState("");
    const [timeData, setTimeData] = useState([]);
    const [teachersData, setTeachersData] = useState([]);
    const selectedStartTime = timeData.find(data => data.id === programStartTime);
    const selectedEndTime = timeData.find(data => data.id === programEndTime);
    const selectedTeachers = teachersData.find(data => data.id === programTeacher);

    const fetchProgramTimeData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/get_time",
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setTimeData(response.data.data);
                console.log(response);
            }
        });
    }

    const fetchTeachersData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/teacher_list",
            data: {
                school_id: localStorage.getItem("schoolId")
            },
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setTeachersData(response.data.data);
                console.log(response);
            }
        });
    }

    const [newProgramName, setNewProgramName] = useState('');
    const [features, setFeatures] = useState({
        qrCode: { status: true, name: 'QR Code', value: 2 },
        attendance: { status: true, name: 'Attendance', value: 5 },
        food: { status: true, name: 'Food', value: 3 },
        sleep: { status: true, name: 'Sleep', value: 4 },
        leaves: { status: true, name: 'Leaves', value: 6 },
        busTracking: { status: false, name: 'Bus Tracking', value: 7 },
        cctv: { status: false, name: 'CCTV', value: 8 },
        fees: { status: false, name: 'Fees', value: 9 },
    });
    const [additionalInfo, setAdditionalInfo] = useState({
        inviteParents: {
            status: true,
            name: 'Automatically Invite Parents as soon as student profile created',
            value: 1
        },
        // addParentChat: {
        //     status: true,
        //     name: 'Add Parent in chat as soon as student profile created',
        //     value: 2
        // },
        // createStudentID: {
        //     status: true,
        //     name: 'Automatically create Student ID as soon as profile created',
        //     value: 3
        // },
    });

    const resetFeaturesAndAdditionalInfo = () => {
        setFeatures({
            qrCode: { status: true, name: 'QR Code', value: 2 },
            attendance: { status: true, name: 'Attendance', value: 5 },
            food: { status: true, name: 'Food', value: 3 },
            sleep: { status: true, name: 'Sleep', value: 4 },
            leaves: { status: true, name: 'Leaves', value: 6 },
            busTracking: { status: false, name: 'Bus Tracking', value: 7 },
            cctv: { status: false, name: 'CCTV', value: 8 },
            fees: { status: false, name: 'Fees', value: 9 }
        });
        setAdditionalInfo({
            inviteParents: {
                status: true,
                name: 'Automatically Invite Parents as soon as student profile created',
                value: 1
            },
            // addParentChat: {
            //     status: true,
            //     name: 'Add Parent in chat as soon as student profile created',
            //     value: 2
            // },
            // createStudentID: {
            //     status: true,
            //     name: 'Automatically create Student ID as soon as profile created',
            //     value: 3
            // }
        })
    }

    const handleFeatureChange = (feature) => {
        setFeatures({
            ...features,
            [feature]: {
                ...features[feature],
                status: !features[feature].status,
            },
        });
    };
    const handleAdditionalInfoChange = (info) => {
        setAdditionalInfo({
            ...additionalInfo,
            [info]: {
                ...additionalInfo[info],
                status: !additionalInfo[info].status,
            },
        });
    };

    const [programeNameError, setProgramNameError] = useState(null);
    const [programeTeacherError, setProgramTeacherError] = useState(null);
    const [timeError, setTimeError] = useState(null);
    const validateProgramForm = () => {
        let valid = true;
        if (newProgramName === "") {
            setProgramNameError("Please enter program name");
            valid = false;
        } else {
            setProgramNameError(null);
        }
        if (programTeacher === "") {
            setProgramTeacherError("Please select program teacher");
            valid = false;
        } else {
            setProgramTeacherError(null);
        }
        // if (programStartTime === "" || programEndTime === "") {
        //   setTimeError("Please select start and end time");
        //   valid = false;
        // } else {
        //   setTimeError(null);
        // }
        return valid;
    };

    const handleCreateProgram = () => {
        const selectedFeatureValues = Object.keys(features)
            .filter((key) => features[key].status)
            .map((key) => features[key].value);

        const selectedAdditionalInfoValues = Object.keys(additionalInfo)
            .filter((key) => additionalInfo[key].status)
            .map((key) => additionalInfo[key].value);

        const programData = {
            school_id: localStorage.getItem("schoolId"),
            program_name: newProgramName,
            teacher_id: programTeacher,
            from_time_id: programStartTime,
            to_time_id: programEndTime,
            features: selectedFeatureValues.join(","),
            additional_info: selectedAdditionalInfoValues.join(","),
            status: 1,
            [newProgramAddUpdateStatus === "add" ? "created_by" : "updated_by"]: localStorage.getItem("loginUserId"),
            ...(newProgramAddUpdateStatus !== "add" && { id: localStorage.getItem("programId") })
        };
        console.log(programData);
        if (validateProgramForm()) {
            axios({
                method: "post",
                url: `${baseUrl}/api/programs_add_edit`,
                data: programData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response.data.status) {
                    $('.overlay').removeClass('visible');
                    $('.popup-program-container').removeClass('visible');
                    setNewProgramPopupVisible(false);
                    fetchProgramsData();
                    setSnackbarMessage(response.data.message);
                    setMessageType('success');
                    handleSnackbarOpen();
                    if (newProgramAddUpdateStatus === "edit") {
                        localStorage.setItem("programName", newProgramName);
                    }
                } else {
                    setSnackbarMessage(response.data.message);
                    setMessageType('error');
                    handleSnackbarOpen();
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    };

    const [isFocused, setIsFocused] = useState(false);
    const [progress, setProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [importstatus, setimportstatus] = useState(false);

    const importStudents1 = () => {
        setimportstatus(true);
    };

    const handleImportStatus = (status) => {
        if (status == true) {
            setimportstatus(false);
        }
    };
    // ----- 04-07  active date----------
    const inputDateObj = moment("Thu Jul 04 2024", 'ddd MMM DD YYYY').toDate();
    const currentDateObj = new Date();
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const formattedCurrentDate = formatDate(currentDateObj);


    // -------------------------06-07------------------------------------
    // ----------add new student----------------

    const formData = new FormData();

    const gender1 = [
        {
            id: 1,
            value: "Male",
        },
        {
            id: 2,
            value: "Female",
        },
    ];

    const Blood1 = [
        {
            id: 1,
            value: "A+",
        },
        {
            id: 2,
            value: "A-",
        },
        {
            id: 3,
            value: "O+",
        },
        {
            id: 4,
            value: "B-",
        },
        {
            id: 5,
            value: "B+",
        },
        {
            id: 6,
            value: "B+",
        },
    ];

    const [user, setUser] = useState({
        school_id: localStorage.getItem("schoolId"),
        studentID: "",
        first_name: "",
        last_name: "",
        gender: "",
        programs_id: "",
        dob: "",
        joining_date: "",
        blood_group: "",
        address: "",
        father_name: "",
        mother_name: "",
        primary_email: "",
        secondary_email: "",
        primary_phone: "",
        secondary_phone: "",
        student_qr_code: "",
        status: 1,
        created_by: "",
        updated_by: "",
        profile_image: null,
    });

    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setUser({ ...user, profile_image: file });
        setSelectedImage(file);
    };

    const convertDBDateFormat = (dateString) => {
        const [day, month, year] = dateString.split('-');
        return `${year}-${month}-${day}`;
    };
    const dateFormatList = 'DD-MM-YYYY';

    const ondobChange = (date, dateString) => {
        setUser({ ...user, dob: dateString });
        console.log(date, dateString);
    };

    const ondojChange = (date, dateString) => {
        setUser({ ...user, joining_date: dateString });
        console.log(date, dateString);
    }
    const onSubmit = (ev) => {
        console.log("formData", formData)
        if (validateForm()) {
            console.log(user);
            axios({
                method: "post",
                url: baseUrl + "/api/student_add",
                data: {
                    school_id: localStorage.getItem("schoolId"),
                    first_name: user.first_name,
                    last_name: "",
                    gender: user.gender,
                    dob: convertDBDateFormat(user.dob),
                    programs_id: user.programs_id,
                    joining_date: (user.joining_date !== "" && user.joining_date !== null) ? convertDBDateFormat(user.joining_date) : "",
                    blood_group: user.blood_group,
                    address: user.address,
                    father_name: user.father_name,
                    mother_name: user.mother_name,
                    primary_email: user.primary_email,
                    secondary_email: user.secondary_email,
                    primary_phone: user.primary_phone,
                    secondary_phone: user.secondary_phone,
                    profile_image: user.profile_image,
                    student_qr_code: "",
                    status: 1,
                    created_by: localStorage.getItem('loginUserId'),
                    studentID: user.studentID,
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response["data"]["status"] === true) {
                    setSnackbarMessage(response.data.message);
                    setMessageType('success');
                    handleSnackbarOpen();
                    $(".overlay").removeClass("visible");
                    $(".popup-modal-container").removeClass("visible");
                    setUser({
                        school_id: localStorage.getItem("schoolId"),
                        first_name: "",
                        last_name: "",
                        gender: "",
                        programs_id: "",
                        dob: "",
                        joining_date: "",
                        blood_group: "",
                        address: "",
                        father_name: "",
                        mother_name: "",
                        primary_email: "",
                        secondary_email: "",
                        primary_phone: "",
                        secondary_phone: "",
                        student_qr_code: "",
                        status: 1,
                        created_by: "",
                        updated_by: "",
                        studentID: "",
                        profile_image: null,
                    });
                    console.log(response);
                    fetchAttendanceData();
                } else {
                    setSnackbarMessage(response.data.message);
                    setMessageType('error');
                    handleSnackbarOpen();
                }
            });
        }
    };

    const [validationErrors, setValidationErrors] = useState({
        first_name: "",
        gender: "",
        dob: "",
        programs_id: "",
        profile_image: "",
        joining_date: "",
        studentID: "",
        blood_group: "",
        address: "",
        father_name: "",
        primary_phone: "",
        primary_email: "",
        mother_name: "",
        secondary_phone: "",
        secondary_email: "",
    });

    const validateForm = () => {
        let errors = {};
        if (user.first_name === "") {
            errors.first_name = "Student name cannot be empty";
        }
        if (user.gender === "") {
            errors.gender = "Student gender cannot be empty";
        }
        if (user.dob === "") {
            errors.dob = "Student date of birth cannot be empty";
        }
        if (user.programs_id === "") {
            errors.programs_id = "Student programs cannot be empty";
        }
        if (user.address === "") {
            errors.address = "Student address cannot be empty";
        }
        if (user.father_name === "") {
            errors.father_name = "Student father name cannot be empty";
        }
        if (user.primary_phone.trim() === "") {
            errors.primary_phone = "Student father mobile number cannot be empty";
        } else if (
            !validator.isMobilePhone(user.primary_phone.trim()) ||
            user.primary_phone.trim().length !== 10
        ) {
            errors.primary_phone = "Please enter valid mobile number";
        }
        if (user.mother_name === "") {
            errors.mother_name = "Student mother name cannot be empty";
        }
        if (user.secondary_phone.trim() === "") {
            errors.secondary_phone = "Student mother mobile number cannot be empty";
        } else if (
            !validator.isMobilePhone(user.secondary_phone.trim()) ||
            user.secondary_phone.trim().length !== 10
        ) {
            errors.secondary_phone = "Please enter valid mobile number";
        }
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const [programs, setPrograms] = useState([]);

    const fetchPrograms = async () => {
        try {
            const response = await axios.post(baseUrl + "/api/get_programs", {
                school_id: localStorage.getItem("schoolId")
            }, {
                headers: {
                    "Authorization": localStorage.getItem('access_token')
                }
            });
            setPrograms(response.data.data);
        } catch (error) { }
    };

    const selectedProgram = programs.find(
        (program) => program.id === user.programs_id
    );

    const selectedGender = gender1.find(
        (gender) => gender.value === user.gender
    );

    const selectedBlood = Blood1.find(
        (blood_group) => blood_group.value === user.blood_group
    );

    const firstNameInputRef = useRef(null);
    const genderInputRef = useRef(null);
    const dobInputRef = useRef(null);
    const programInputRef = useRef(null);
    const imageInputRef = useRef(null);
    const joingInputRef = useRef(null);
    const studentInputRef = useRef(null);
    const bloodInputRef = useRef(null);
    const adressInputRef = useRef(null);
    const fathernameInputRef = useRef(null);
    const fatherphoneInputRef = useRef(null);
    const fatheremailInputRef = useRef(null);
    const mathernameInputRef = useRef(null);
    const matherphoneInputRef = useRef(null);
    const matheremailInputRef = useRef(null);
    const submitButtonRef = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === "Enter") {
            event.preventDefault();
            console.log("Next input ref:", nextInputRef); // Log the next input ref
            if (nextInputRef && nextInputRef.current) {
                nextInputRef.current.focus();
            } else {
                console.log("Next input ref is null");
            }
        }
    };

    const initialBorderColor = {
        genderBorder: "",
        programsBorder: "",
        bloodGroupBorder: ""
    };

    const [borderColor, setBorderColor] = useState(initialBorderColor);

    const handleFocus = (borderColor) => {
        if (borderColor === "gender") {
            setBorderColor({ ...borderColor, genderBorder: "#4D6EE3" });
        } else if (borderColor === "programs") {
            setBorderColor({ ...borderColor, programsBorder: "#4D6EE3" });
        } else if (borderColor === "bloodGroup") {
            setBorderColor({ ...borderColor, bloodGroupBorder: "#4D6EE3" });
        }
    };

    const handleBlur = () => {
        setBorderColor(initialBorderColor);
    };

    const handleClickOutside1 = (event) => {
        if (genderInputRef.current && !genderInputRef.current.contains(event.target)) {
            setBorderColor(initialBorderColor);
        }
        if (programInputRef.current && !programInputRef.current.contains(event.target)) {
            setBorderColor(initialBorderColor);
        }
        if (bloodInputRef.current && !bloodInputRef.current.contains(event.target)) {
            setBorderColor(initialBorderColor);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside1);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside1);
        };
    }, []);


    // -------11-07-----------------

    const editProgramStyle = {
        position: "absolute",
        left: "430px",
        top: "55px",
        margin: "0px",
        maxWidth: "140px"
    }

    const editProgramPopupRef = useRef(null);
    const [editProgramVisible, setEditProgramVisible] = useState(false);
    const handleClickOutside = (event) => {
        if (editProgramPopupRef.current && !editProgramPopupRef.current.contains(event.target)) {
            setEditProgramVisible(false);
        }
    };

    useEffect(() => {
        if (editProgramVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [editProgramVisible]);

    const [isRecycleBinVisible, setRecycleBinVisible] = useState(false);
    const [isRecycleBinStatus, setRecycleBinStatus] = useState("");
    const handleRecycleBinClick = (status) => {
        setRecycleBinVisible(true);
    };

    const handleRecycleBinClose = () => {
        setRecycleBinVisible(false);
    };

    const handleRecycleBin = async () => {
        const url = `${baseUrl}/api/programs_delete`;
        const data = {
            id: localStorage.getItem("programId"),
            updated_by: localStorage.getItem('loginUserId')
        };
        const headers = {
            "Content-Type": "multipart/form-data",
            "Authorization": localStorage.getItem('access_token')
        };
        try {
            const response = await axios.post(url, data, { headers });
            console.log(response);
            if (response.data.status === true) {
                setRecycleBinVisible(false);
                setSnackbarMessage(response.data.message);
                setMessageType('success');
                handleSnackbarOpen();
                fetchProgramsData();
            } else {
                setSnackbarMessage(response.data.message);
                setMessageType('error');
                handleSnackbarOpen();
            }
        } catch (error) {
            console.error(error);
        }
    };

    // -------16-07------------------
    // const [studentDataLength, setStudentDataLength] = useState(23);
    const [studentDataLength, setStudentDataLength] = useState(() => Math.floor(window.innerWidth / 80));

    // -------------24-07-------------
    const [isAttendanceVisible, setAttendanceVisible] = useState(false);
    const [attendanceStatus, setAttendanceStatus] = useState(null);
    const handleAttendanceClick = (attendanceStatus) => {
        setAttendanceStatus(attendanceStatus)
        setAttendanceVisible(true);
    };

    const handleAttendanceClose = () => {
        setAttendanceStatus(null)
        setAttendanceVisible(false);
    };

    useEffect(() => {
        $(document).on("click", "#newStudentCreate", function () {
            setBorderColor(initialBorderColor);
            setSelectedImage(null);
            setUser({
                school_id: localStorage.getItem("schoolId"),
                first_name: "",
                last_name: "",
                gender: "",
                programs_id: "",
                dob: "",
                joining_date: "",
                blood_group: "",
                address: "",
                father_name: "",
                mother_name: "",
                primary_email: "",
                secondary_email: "",
                primary_phone: "",
                secondary_phone: "",
                student_qr_code: "",
                status: 1,
                created_by: "",
                updated_by: "",
                studentID: "",
                profile_image: null,
            });
            setValidationErrors({
                first_name: "",
                gender: "",
                dob: "",
                programs_id: "",
                profile_image: "",
                joining_date: "",
                studentID: "",
                blood_group: "",
                address: "",
                father_name: "",
                primary_phone: "",
                primary_email: "",
                mother_name: "",
                secondary_phone: "",
                secondary_email: "",
            });
            $(".overlay").addClass("visible");
            $(".popup-modal-container").addClass("visible");
        });
        $(document).on("click", ".sp-btn-cancel", function () {
            setError(null);
            setBorderColor(initialBorderColor);
            $(".overlay").removeClass("visible");
            $(".popup-modal-container").removeClass("visible");
        });
        fetchPrograms();
        fetchProgramsData();
        fetchTeachersData();
        fetchProgramTimeData();
        fetchAttendanceData();

    }, []);

    return (
        <section className="staff_atten_cus">
            <Snackbar className="snack_total"
                anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
                open={snackbarState.open}
                onClose={handleSnackbarClose}
                message={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemIcon>
                            <img src={messageType === 'success' ? SuccessIcon : ErrorIcon} alt="Success" style={{ width: '24px', height: '24px' }} />
                        </ListItemIcon>
                        <div className="success_cuc">
                            <p> {snackbarMessage} </p>
                        </div>
                    </div>
                }
                ContentProps={{
                    sx: {
                        border: "1px solid",
                        opacity: "1",
                        background: messageType === 'success' ? "#40BE61" : "#FD4954",
                        borderColor: messageType === 'success' ? "#40BE61" : "#FD4954",
                        width: '415px'
                    }
                }}
                autoHideDuration={3000}
            />
            <div className="outer-container">
                <div className="overlay" />
                <div id="left-panel" className="sp-left-panel">
                <CommonSidebarHeader/>
                    <div className="sp-middle-band">
                        <div className="sp-nav y-auto">
                            <div className="name_tag_side">
                                <h4 className="sp-title">Quick Actions</h4>
                                <ul>
                                    <li onClick={() => { navigate('/dashboard'); }}>
                                        <span>
                                            <i className="icon-Dashboard-Outline" />
                                            <p>Dashboard</p>
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/activity'); }}>
                                        <span>
                                            <i className="icon-Activity-Outline" />
                                            <p>Activity</p>
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/calendar'); }}>
                                        <span>
                                            <i className="icon-Timetable-Outline" />
                                            <p>Calendar</p>
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/staff_list'); }}>
                                        <span>
                                            <i className="icon-Staff-Outline" />
                                            <p>Staff</p>
                                        </span>
                                    </li>
                                    {/* <li>
                    <span>
                      <i className="icon-Message-Outline" />
                      Messages
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Fees-Outline" />
                      Fees Management
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Enquiries-Outline" />
                      Enquiries
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Invites-Outline" />
                      Invites
                    </span>
                  </li> */}
                                </ul>
                                <h4 className="sp-title flexc justifySp" onClick={() => handleOpenNewProgramPopupVisible("add")}>
                                    Programs{" "}
                                    <span id="add-programs" className="sp-plus">
                                        +
                                    </span>
                                </h4>
                                <ul>
                                    {programsLoading && programsData && programsData.length > 0 ?
                                        programsData.map((data, index) => {
                                            return (
                                                <li className={data.id === parseInt(localStorage.getItem('programId')) ? "selected" : ""} onClick={() => {
                                                    setSelectedStudents([]);
                                                    localStorage.setItem('programId', data.id);
                                                    localStorage.setItem('programName', data.program_name);
                                                    fetchAttendanceData();
                                                    navigate('/student_attendance');
                                                }}>
                                                    <span>
                                                        <i className="icon-Program-Outline-1" />
                                                        <p>{data.program_name}</p>
                                                    </span>
                                                </li>
                                            );
                                        }) :
                                        <div className="data-loader extracss_load">
                                            {/* <Loader
                                                type="spinner-cub"
                                                bgColor="#4D6EE3"
                                                color="#6B6F8C"
                                                title="Loading..."
                                                size={35}
                                            /> */}
                                            <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                                            <p>Loading...</p>
                                        </div>
                                    }
                                </ul>
                            </div>
                        </div>
                        <Sidebar />
                    </div>
                </div>
                <div className="sp-right-panel">
                    <div className="sp-top-bar flexc justifySp exta_pad_10">
                        <div className="ft-wt600 flexc pad_ic_ri stude_li_edi">
                            <span className="selected-icon">
                                <i className="icon-Program-Outline-1" />
                            </span>
                            <p className="program_tit">{localStorage.getItem('programName')}</p>
                            {/* <i className={`icon-More-Icon program-icon-style ${editProgramVisible && "active-Icon"}`} onClick={() => setEditProgramVisible(true)} /> */}
                            <span className="pos_fix_edit_student" ref={editProgramPopupRef}>
                                {/* <i className="icon-More-Icon" /> */}
                                <div className="svg_hide_drop">
                                    <Dropdown

                                        label={
                                            <span
                                                onClick={() => setEditProgramVisible(true)}
                                                style={{
                                                    color: "#1B252F",
                                                    padding: "0px",
                                                    height: "10px"
                                                }}
                                            >
                                                {
                                                    editProgramVisible === false
                                                        ? <i className="icon-More-Icon active_ic_more" />
                                                        : <i className="icon-More-Icon active_ic_more active_click_more" />
                                                }
                                            </span>
                                        }
                                        style={{ width: "100%" }}
                                    >
                                        <Dropdown.Item onClick={() => handleOpenNewProgramPopupVisible("edit")} className="pad_progr1">
                                            <img src={editIcon} alt="" style={{ width: '18px' }} /> &nbsp; &nbsp;Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleRecycleBinClick("program")} className="pad_progr1">
                                            <img src={deleteIcon} alt="" style={{ width: '18px' }} /> &nbsp; &nbsp;Delete
                                        </Dropdown.Item>
                                    </Dropdown>
                                </div>
                            </span>
                        </div>
                        <div className="flexc">
                            <button className="sp-view-btn ft-wt500 flexc"
                                onClick={() => {
                                    importStudents1();
                                }}
                            >
                                <i className="icon-Import-Outline" />
                                Import
                            </button>
                            <button
                                className="createN ft-wt500 mr-lf10 space_icon_pad"
                                id="newStudentCreate"
                            >
                                <i class="fa-solid fa-plus"></i> New Student
                            </button>
                        </div>
                    </div>
                    <div className="sp-header ">
                        <nav className="flexc">
                            <div className="sp-list-view flexc" onClick={() => { navigate('/student_list'); }}>
                                <i className="icon-Students-Outline" />Student List</div>
                            <div className="sp-list-view flexc selected" onClick={() => { navigate('/student_attendance'); }}>
                                <i className="icon-Attendance-Outline" />Attendance</div>
                            <div className="sp-list-view flexc" onClick={() => { navigate('/food'); }}>
                                <i className="icon-Food" />Food</div>
                            <div className="sp-list-view flexc " onClick={() => { navigate("/sleep"); }}>
                                <i className="icon-Sleep" />Sleep</div>
                            <div className="sp-list-view flexc" onClick={() => { navigate('/student_timetable'); }}>
                                <i className="icon-Calendar-Outline" />Timetable</div>
                        </nav>
                        {
                            (studentCount && importstatus) === false &&
                            <div className="sp-menu-bar flexc justifySp">
                                <div className="sp-menu icon_div_space pad_30_ex">
                                    <div className="flexc">
                                        {
                                            selectedStudents.length === 0 ?
                                                <div className={`btn-search-bar flexc glo-showdow ${isFocused ? 'focused' : ''}`}>
                                                    <i className="icon-Search" />
                                                    {/* 31-07-2024 */}
                                                    <input
                                                        type="text"
                                                        id="simple-search"
                                                        placeholder="Search Students"
                                                        value={filterValue}
                                                        onChange={handleInputChange}
                                                        onFocus={() => setIsFocused(true)}
                                                        onBlur={() => setIsFocused(false)}
                                                        className="search-input"
                                                    />
                                                    {/* 31-07-2024 */}
                                                    <i onClick={clearInput} className={`icon-Close ${filterValue ? '' : 'hide-visibility'}`} />
                                                    {/* <i className="icon-Search" />
                                                    <input
                                                        type="search"
                                                        placeholder="Search Students"
                                                        value={filterValue}
                                                        onChange={handleInputChange}
                                                        onFocus={() => setIsFocused(true)}
                                                        onBlur={() => setIsFocused(false)}
                                                        className="search-input"
                                                    /> */}
                                                </div> :
                                                <>
                                                    <button className="sp_mbtn flexc excla_div glo-showdow" onClick={() => { handleAttendanceClick(1); }}>
                                                        <span className="flexc ft-13">
                                                            {/* <span className="sp-lpdr  icon-Tick color_cha_whi" /> */}
                                                            <img src={tik} alt="" />
                                                        </span>
                                                        <span>Present</span>
                                                    </button>
                                                    <button className="sp_mbtn flexc excla_div glo-showdow" onClick={() => { handleAttendanceClick(2); }}>
                                                        {/* <span className="notapp-ele color_cha_whi   " /> */}

                                                        <img src={leave} alt="" />
                                                        <span>Absent</span>
                                                    </button>
                                                    <button className="sp_mbtn flexc excla_div glo-showdow" onClick={() => { handleAttendanceClick(5); }}>
                                                        {/* <span className="cross-ele color_cha_whi leave_ic" /> */}
                                                        <img src={cross} alt="" />
                                                        <span>Leave</span>
                                                    </button>

                                                </>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {

                        importstatus ?
                            <ImportComponent
                                importStudent={"Student"}
                                handleImportStatus={handleImportStatus}
                                setProgress={setProgress} setIsLoading={setIsLoading}
                            /> :
                            <div className="he_fix_cus ">
                                {
                                    (foodLoading && foodData) ? (
                                        foodData.data.length > 0 ? (
                                            <div className="std-attence">
                                                <div className="attence-top overxy-auto">
                                                    <div className="students-add-list extra_height">
                                                        <ul className="sp-head sp_sticky hei_fix_4 defult-date-header">
                                                            <li className="table_input_sty">
                                                                <input
                                                                    type="checkbox"
                                                                    id="check-all"
                                                                    onChange={handleSelectAll}
                                                                    checked={selectedStudents.length === foodHeaderdata.length}
                                                                />
                                                                {foodData.student}
                                                            </li>
                                                            {dataHeader && dataHeader.length > 0 ? (
                                                                dataHeader.map((data, index) => (
                                                                    <li key={index} className={`${formatDate(moment(data.date_data, 'ddd MMM DD YYYY').toDate()) === formattedCurrentDate && 'active-date-header'}`}
                                                                    >{data.date_data}</li>
                                                                ))
                                                            ) : (
                                                                <div></div>
                                                            )}
                                                        </ul>
                                                        {foodHeaderdata.map((student, studentIndex) => (
                                                            <React.Fragment key={studentIndex}>
                                                                <ul className="li_heigh_44">
                                                                    <li className="table_input_sty truncate">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="sub_select"
                                                                            onChange={() => handleSelectStudent(student.id)}
                                                                            checked={selectedStudents.includes(student.id)}
                                                                        />
                                                                        <span className="ft-500 flexc img_wid_cu">
                                                                            {/* <img
                                                                                src={
                                                                                    student.profile_image !== null
                                                                                        ? baseUrl + "/api/" + student.profile_image
                                                                                        : avatar1
                                                                                }
                                                                                width={40}
                                                                                alt="Avatar"
                                                                            /> */}
                                                                            {
                                                                                student?.profile_image !== null ?
                                                                                    <img
                                                                                        src={baseUrl + "/api/" + student?.profile_image}
                                                                                        width={40}
                                                                                        alt={student?.first_name}
                                                                                        style={{ borderRadius: "5px" }}
                                                                                    /> :<LogoComponent name={student?.name} index={studentIndex} bgColor={student?.std_color}/>
                                                                            }
                                                                            
                                                                            {/* <span className="mr-lf10 font_13 truncate name_bold"
                                                                            >{student.name}</span> */}
                                                                            <span
                                                                                className="mr-lf10 fs-13 truncate name_bold text-cap"
                                                                                data-tooltip-id={`name-tooltip-${student?.id}`}
                                                                                data-tooltip-content={student.name}
                                                                                data-tooltip-place="bottom"
                                                                                style={{ cursor: student.name.length > studentDataLength && "pointer" }}
                                                                            >
                                                                                {
                                                                                    (student.name && student.name.length <= studentDataLength) ? student.name : `${student.name.slice(0, studentDataLength)}...`
                                                                                }
                                                                            </span>
                                                                        </span>
                                                                    </li>
                                                                    {
                                                                        student.name.length > studentDataLength &&
                                                                        <Tooltip className="custom-tooltip" id={`name-tooltip-${student.id}`} />
                                                                    }
                                                                    {student.attendance.map((attendanceData, attendanceIndex) => (
                                                                        <React.Fragment key={attendanceIndex}>
                                                                            <li
                                                                                className={`${formatDate(moment(attendanceData.date, 'ddd MMM DD YYYY').toDate()) === formattedCurrentDate ? 'active-date-data' :
                                                                                    formatDate(moment(attendanceData.date, 'ddd MMM DD YYYY').toDate()) > formattedCurrentDate && 'next-date-data'} ${(studentIndex === (foodHeaderdata.length - 1) && formatDate(moment(attendanceData.date, 'ddd MMM DD YYYY').toDate()) === formattedCurrentDate) ? 'active-last-date-data' : ''}`}
                                                                            >
                                                                                {attendanceData.attendance_status === 1 ? (
                                                                                    <span className="flexc ft-13">
                                                                                        {/* <span className="sp-lpdr icon-Tick" /> */}
                                                                                        <img src={tik1} alt="" style={{ width: '16px' }} />
                                                                                    </span>
                                                                                ) : attendanceData.attendance_status === 2 ? (
                                                                                    // <span className="notapp-ele" />
                                                                                    <img src={leave1} alt="" style={{ width: '16px' }} />
                                                                                ) : attendanceData.attendance_status > 2 ? (
                                                                                    // <span className="cross-ele" />
                                                                                    <img src={cross1} alt="" style={{ width: '16px' }} />
                                                                                ) : null}
                                                                            </li>
                                                                        </React.Fragment>
                                                                    ))}
                                                                </ul>
                                                            </React.Fragment>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="no_bor_data">
                                                <center><div className="sp-title flex-3">{foodData.message}</div></center>
                                            </div>
                                        )
                                    ) : (
                                        <div className="data-loader extracss_load">
                                            {/* <Loader
                                          type="spinner-cub"
                                          bgColor="#4D6EE3"
                                          color="#6B6F8C"
                                          title="Loading..."
                                          size={35}
                                        /> */}
                                            <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                                            <p>Loading...</p>
                                        </div>
                                    )
                                }
                            </div>
                    }
                </div>
                {/* Create New Student */}
                <div className="popup-modal-container" onSubmit={onSubmit}>
                    <div className="popup-wrap">
                        <h2 className="ft-wt700">Create New Student</h2>
                        <div className="sp-pop-header">
                            <h3 className="ft-wt600">Student Details</h3>
                            <div className="form-section-default flexc justifySp fwrap">
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Name<span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            ref={firstNameInputRef}
                                            type="text"
                                            value={user.first_name}
                                            onChange={(ev) =>
                                                setUser({ ...user, first_name: ev.target.value })
                                            }
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, genderInputRef)
                                            }
                                            className={`field-value-holder custamize_color_place  extra_bor_clr  sp-field-input ${validationErrors.first_name ? 'error' : ''}`}
                                            placeholder="Enter full name"
                                        />
                                    </div>
                                    {validationErrors.first_name && (
                                        <p className="error pos_abo_stu">{validationErrors.first_name}</p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus" style={{ padding: "0px" }}>
                                    <label>Gender<span className="col_red">*</span></label>
                                    <div
                                        className="field-value-holder dropbutton"
                                        ref={genderInputRef}
                                        style={{
                                            width: "100%",
                                            borderColor: validationErrors.gender ? "red" : borderColor.genderBorder,
                                        }}
                                        onKeyPress={(event) =>
                                            handleKeyPress(event, programInputRef)
                                        }
                                        onFocus={() => handleFocus("gender")}
                                        onBlur={handleBlur}
                                        tabIndex="0"
                                    >
                                        <Dropdown
                                            label={
                                                <span
                                                    className={`${selectedGender ? 'selected-item' : ''}`}
                                                    style={{ fontSize: "13px", justifyContent: "flex-start", color: selectedGender ? '#1B252F' : '#292929' }}
                                                >
                                                    {selectedGender ? selectedGender.value : "Select Gender"}
                                                </span>
                                            }
                                            style={{ width: "100%" }}
                                        >
                                            {gender1.map((item) => (
                                                <Dropdown.Item
                                                    key={item.id}
                                                    className={`${selectedGender && selectedGender.id === item.id ? "selected-item dd_select" : ""
                                                        }`}
                                                    icon={selectedGender && selectedGender.id === item.id ? HiCheck : null}
                                                    onClick={() => setUser({ ...user, gender: item.value })}
                                                >
                                                    {item.value}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown>
                                    </div>
                                    {validationErrors.gender && (
                                        <p className="error pos_abo_stu">{validationErrors.gender}</p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus date_pic_img">
                                    <label>
                                        Date of Birth<span className="col_red">*</span>
                                    </label>
                                    <div
                                        className={`field-value-holder ${validationErrors.dob ? 'error' : ''}`}>
                                        <div>
                                            <DatePicker
                                                ref={dobInputRef}
                                                placeholder="DD/MM/YYYY"
                                                format={dateFormatList}
                                                onChange={ondobChange}
                                                value={user.dob ? dayjs(user.dob, dateFormatList) : null}
                                            />

                                        </div>
                                    </div>
                                    {validationErrors.dob && <p className="error pos_abo_stu">{validationErrors.dob}</p>}
                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Programs<span className="col_red">*</span></label>
                                    <div
                                        ref={programInputRef}
                                        className="field-value-holder dropbutton"
                                        style={{
                                            width: "100%",
                                            borderColor: validationErrors.programs_id ? "red" : borderColor.programsBorder,
                                        }}
                                        onKeyPress={(event) =>
                                            handleKeyPress(event, studentInputRef)
                                        }
                                        onFocus={() => handleFocus("programs")}
                                        onBlur={handleBlur}
                                        tabIndex="0"
                                    >
                                        <Dropdown
                                            label={
                                                <span
                                                    className={`${selectedProgram ? 'selected-item' : ''}`}
                                                    style={{
                                                        fontSize: "13px",
                                                        color: selectedProgram ? '#1B252F' : '#292929'
                                                    }}
                                                >
                                                    {selectedProgram ? selectedProgram.program_name : "Select a Program"}
                                                </span>
                                            }
                                            style={{ width: "100%" }}
                                        >
                                            {programs.map((item) => (
                                                <Dropdown.Item
                                                    key={item.id}
                                                    className={`${selectedProgram && selectedProgram.id === item.id ? "selected-item dd_select" : ""}`}
                                                    icon={selectedProgram && selectedProgram.id === item.id ? HiCheck : null}
                                                    onClick={() => setUser({ ...user, programs_id: parseInt(item.id) })}
                                                >
                                                    {item.program_name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown>
                                    </div>
                                    {validationErrors.programs_id && (
                                        <p className="error pos_abo_stu">
                                            {validationErrors.programs_id}
                                        </p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus ">
                                    <label>Student Photo</label>
                                    <div className="field-value-holder">
                                        <span className="sp-upload-btn flexc justifySp" style={{
                                            color: " #A9AFC3",
                                            fontWeight: "500",
                                            fontSize: "13px"
                                        }}>
                                            {selectedImage === null ? (
                                                "Upload"
                                            ) : (
                                                <p style={{
                                                    color: " #1B252F",
                                                    fontWeight: "600",
                                                    fontSize: "13px"
                                                }} className="truncate"> {selectedImage.name}</p>
                                            )}
                                            <button className="sp-file-browse">Browse</button>
                                        </span>
                                        <input
                                            className="input-placeholder1"
                                            style={{
                                                cursor: "pointer"
                                            }}
                                            ref={imageInputRef}
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, joingInputRef)
                                            }
                                        />
                                    </div>
                                    {/* {validationErrors.profile_image && (
                    <p className="error">
                      {validationErrors.profile_image}
                    </p>
                  )} */}
                                </div>
                                <div className="field-label half-field error_mes_cus date_pic_img">
                                    <label>Joining Date</label>
                                    <div className="field-value-holder">
                                        <DatePicker
                                            ref={joingInputRef}
                                            placeholder="DD/MM/YYYY"
                                            value={user.joining_date ? dayjs(user.joining_date, dateFormatList) : null}
                                            format={dateFormatList}
                                            onChange={ondojChange}
                                        >
                                        </DatePicker>
                                    </div>
                                    {/* {validationErrors.joining_date && (
                    <p className="error">
                      {validationErrors.joining_date}
                    </p>
                  )} */}
                                </div>
                                <div className="field-label half-field error_mes_cus ">
                                    <label>Student ID</label>
                                    <div className="field-value-holder custamize_color_place">
                                        <input
                                            ref={studentInputRef}
                                            type="text"
                                            value={user.studentID}
                                            onChange={(ev) =>
                                                setUser({ ...user, studentID: ev.target.value })
                                            }
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, bloodInputRef)
                                            }
                                            className={`sp-field-input`}
                                            placeholder="001"
                                        />
                                    </div>
                                </div>
                                <div className="field-label half-field error_mes_cus " style={{ padding: "0px" }}>
                                    <label>Blood Group</label>
                                    <div className="field-value-holder dropbutton" ref={bloodInputRef}
                                        style={{
                                            width: "100%",
                                            borderColor: borderColor.bloodGroupBorder,
                                        }}
                                        onKeyPress={(event) =>
                                            handleKeyPress(event, adressInputRef)
                                        }
                                        onFocus={() => handleFocus("bloodGroup")}
                                        onBlur={handleBlur}
                                        tabIndex="0"
                                    >
                                        <Dropdown
                                            label={
                                                <span
                                                    style={{
                                                        fontSize: "0.8825em",
                                                        color: selectedBlood ? '#1B252F' : '#292929',
                                                        justifyContent: "flex-start"
                                                    }}
                                                >
                                                    {selectedBlood ? selectedBlood.value : "Select Blood Group"}
                                                </span>
                                            }
                                            style={{ width: "100%" }}
                                        >
                                            {Blood1.map((item) => (
                                                <Dropdown.Item
                                                    key={item.id}
                                                    className={`${selectedBlood && selectedBlood.id === item.id ? "selected-item dd_select" : ""
                                                        }`}
                                                    icon={selectedBlood && selectedBlood.id === item.id ? HiCheck : null}
                                                    onClick={() => setUser({ ...user, blood_group: item.value })}
                                                >
                                                    {item.value}
                                                </Dropdown.Item>
                                            ))}

                                        </Dropdown>
                                    </div>
                                    {/* {validationErrors.blood_group && (
                    <p className="error">
                      {validationErrors.blood_group}
                    </p>
                  )} */}
                                </div>
                                <div className="field-label full-field error_mes_cus">
                                    <label>Address<span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            type="text"
                                            ref={adressInputRef}
                                            value={user.address}
                                            onChange={(ev) =>
                                                setUser({ ...user, address: ev.target.value })
                                            }
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.address ? 'error' : ''}`}
                                            placeholder="Address"
                                            defaultValue={""}
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, fathernameInputRef)
                                            }
                                        />
                                    </div>
                                    {validationErrors.address && (
                                        <p className="error">{validationErrors.address}</p>
                                    )}
                                </div>
                            </div>
                            <h3 className="ft-wt600 mt-30">Father Details</h3>
                            <div className="form-section-default flexc justifySp fwrap">
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Name<span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            ref={fathernameInputRef}
                                            type="text"
                                            value={user.father_name}
                                            onChange={(ev) =>
                                                setUser({ ...user, father_name: ev.target.value })
                                            }
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.father_name ? 'error' : ''}`}
                                            placeholder="Enter Full name"
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, fatherphoneInputRef)
                                            }
                                        />
                                    </div>
                                    {validationErrors.father_name && (
                                        <p className="error pos_abo_stu">
                                            {validationErrors.father_name}
                                        </p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Phone Number<span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            ref={fatherphoneInputRef}
                                            type="text"
                                            value={user.primary_phone}
                                            onChange={(ev) =>
                                                setUser({ ...user, primary_phone: ev.target.value })
                                            }
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.primary_phone ? 'error' : ''}`}
                                            placeholder="Phone Number"
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, fatheremailInputRef)
                                            }
                                        />
                                    </div>
                                    {validationErrors.primary_phone && (
                                        <p className="error pos_abo_stu">
                                            {validationErrors.primary_phone}
                                        </p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus ">
                                    <label>Email</label>
                                    <div className="">
                                        <input
                                            ref={fatheremailInputRef}
                                            type="text"
                                            value={user.primary_email}
                                            onChange={(ev) =>
                                                setUser({ ...user, primary_email: ev.target.value })
                                            }
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, mathernameInputRef)
                                            }
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input`}
                                            placeholder="Enter your email"
                                        />
                                    </div>
                                    {/* {validationErrors.primary_email && (
                    <p className="error">
                      {validationErrors.primary_email}
                    </p>
                  )} */}
                                </div>
                            </div>
                            <h3 className="ft-wt600 mt-30">Mother Details</h3>
                            <div className="form-section-default flexc justifySp fwrap">
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Name<span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            ref={mathernameInputRef}
                                            type="text"
                                            value={user.mother_name}
                                            onChange={(ev) =>
                                                setUser({ ...user, mother_name: ev.target.value })
                                            }
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, matherphoneInputRef)
                                            }
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.mother_name ? 'error' : ''}`}
                                            placeholder="Enter Full name"
                                        />
                                    </div>
                                    {validationErrors.mother_name && (
                                        <p className="error pos_abo_stu">
                                            {validationErrors.mother_name}
                                        </p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Phone Number<span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            ref={matherphoneInputRef}
                                            type="text"
                                            value={user.secondary_phone}
                                            onChange={(ev) =>
                                                setUser({ ...user, secondary_phone: ev.target.value })
                                            }
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, matheremailInputRef)
                                            }
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.secondary_phone ? 'error' : ''}`}
                                            placeholder="Phone Number"
                                        />
                                    </div>
                                    {validationErrors.secondary_phone && (
                                        <p className="error pos_abo_stu">
                                            {validationErrors.secondary_phone}
                                        </p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus ">
                                    <label>Email</label>
                                    <div className="field-value-holder custamize_color_place">
                                        <input
                                            ref={matheremailInputRef}
                                            type="text"
                                            value={user.secondary_email}
                                            onChange={(ev) =>
                                                setUser({ ...user, secondary_email: ev.target.value })
                                            }
                                            className={`sp-field-input`}
                                            placeholder="Enter your email"
                                            onKeyDown={(event) =>
                                                handleKeyPress(event, submitButtonRef)
                                            }
                                        />
                                    </div>
                                    {/* {validationErrors.secondary_email && (
                    <p className="error">
                      {validationErrors.secondary_email}
                    </p>
                  )} */}
                                </div>
                            </div>
                        </div>
                        <div className="sp-bottom-sticky">
                            <button
                                className="sp-btn-create"
                                ref={submitButtonRef}
                                onClick={onSubmit}
                            >
                                Create
                            </button>
                            <button className="sp-btn-cancel">Cancel</button>
                        </div>
                    </div>
                </div>
                {/* Attendance Modal */}
                {
                    isAttendanceVisible && (
                        <div
                            id="recycle-popup"
                            tabIndex={-1}
                            className="back_overlay fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full  max-h-full res_tot_div"
                        >
                            <div className="relative w-full max-w-xs max-h-full">
                                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4">   <div className="p-4 md:p-5 text-center">
                                    <div className="round-btn rounded inline-flex alignC justify-center">
                                        <img src={attpopup} alt="" />
                                    </div>
                                    <p className="ft-wt600 ft-16 mb-4 mt-3">Mark as {attendanceStatus === 1 ? "Present" : attendanceStatus === 2 ? "Absent" : "Leave"}</p>
                                    <h3 className="mb-5 font-normal text-sm">
                                        Are you sure you want to update the student attendance
                                    </h3>
                                    <div className="att-popup">
                                        <button
                                            onClick={handleAttendanceClose}
                                            type="button"
                                            className="att-cancel can_cus_rec border-full ms-3 py-2.5 px-5  text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={() => addAttendance()}
                                            type="button"
                                            className="save_button_cus sp-blue-color text-white ms-3 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 rounded-md ft-13 inline-flex items-center px-5 py-2.5 text-center save_cus_di"
                                        >
                                            Yes, Proceed
                                        </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                {/* edit Program popup */}
                {/* {
                    editProgramVisible && (
                        <div id="edit-program" ref={editProgramPopupRef} className="z-10 bg-white rounded-lg shadow w-full dark:bg-gray-700 border-full edit-program" style={editProgramStyle}>
                            <div className="icon_clr_ser">
                                <ul>
                                    <li id="edit-program-icon" className="cust-program-icons" onClick={() => handleOpenNewProgramPopupVisible("edit")}><img src={editIcon} alt="" style={{ width: '18px' }} /> &nbsp; &nbsp;Edit</li>
                                    <li className="cust-program-icons" onClick={() => handleRecycleBinClick()}><img src={deleteIcon} alt="" style={{ width: '18px' }} /> &nbsp; &nbsp;Delete</li>
                                </ul>
                            </div>
                        </div>
                    )
                } */}
                {/* Recycle Bin Modal */}
                {
                    isRecycleBinVisible && (
                        <div
                            id="recycle-popup"
                            tabIndex={-1}
                            className="back_overlay fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full  max-h-full res_tot_div"
                        >
                            <div className="relative w-full max-w-xs max-h-full">
                                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4">   <div className="p-4 md:p-5 text-center">
                                    <div className="round-btn rounded inline-flex alignC justify-center">
                                        <img src={de} alt="" />
                                    </div>
                                    <p className="ft-wt600 ft-16 mb-4 mt-3">Recycle Bin</p>
                                    <h3 className="mb-5 font-normal text-sm">
                                        Are you sure you want to move this program to Recycle Bin?
                                    </h3>
                                    <button
                                        onClick={() => setDeleteProgramConfirmVisible(true)}
                                        type="button"
                                        className="yes_button_sta_cus sp-red-color text-white  hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 rounded-md  inline-flex items-center px-5 py-2.5 text-center"
                                    >
                                        Yes, Move
                                    </button>
                                    <button
                                        onClick={handleRecycleBinClose}
                                        type="button"
                                        style={{
                                            border: "1px solid #D7D9DD",
                                        }}
                                        className="can_cus_rec border-full ms-3 py-2.5 px-5  text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700"
                                    >
                                        Cancel
                                    </button>

                                </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                {/* Create New Program */}
                {
                    newProgramPopupVisible && (
                        <div className="popup-program-container visible">
                            <div className="popup-wrap">
                                <h2 className="ft-wt700">{newProgramAddUpdateStatus === "add" ? "Create" : "Update"} New Program</h2>
                                <div className="sp-pop-header ">
                                    <div className="form-section-default flexc justifySp fwrap">
                                        <div className="field-label full-field error_mes_cus  pos_stu_cus">
                                            <label>Program Name</label>
                                            <div className="">
                                                <input
                                                    className={`field-value-holder margin_top10p custamize_color_place  extra_bor_clr  sp-field-input ${programeNameError ? 'error' : ''}`}
                                                    // className="sp-field-input"
                                                    type="text"
                                                    placeholder="Enter Program name"
                                                    value={newProgramName}
                                                    onChange={(e) => setNewProgramName(e.target.value)}
                                                />
                                            </div>
                                            {programeNameError && <p className="error pos_abo_stu">{programeNameError}</p>}
                                        </div>
                                        <div className="field-label half-field error_mes_cus extra_pad_drop">
                                            <label>Program Teachers</label>
                                            <div className="field-value-holder margin_top10p dropbutton pos_stu_cus"
                                                style={{
                                                    width: "100%",
                                                    borderColor: programeTeacherError ? "red" : borderColor.newProgram,
                                                }}
                                            >
                                                <Dropdown
                                                    label={
                                                        <span
                                                            className={`${selectedTeachers ? 'selected-item' : ''}`}
                                                            style={{
                                                                fontSize: "13px",
                                                                justifyContent: "flex-start",
                                                                color: selectedTeachers ? '#1B252F' : '#292929'
                                                            }}
                                                        >
                                                            {selectedTeachers ? selectedTeachers.first_name : "Select Teacher"}</span>}
                                                    style={{ width: '100%' }}
                                                >
                                                    {teachersData.map(item => (
                                                        <Dropdown.Item
                                                            // className={selectedTeachers && selectedTeachers.id === item.id ? "dd_select" : null}
                                                            className={`${selectedTeachers && selectedTeachers.id === item.id ? "selected-item dd_select" : ""
                                                                }`}
                                                            icon={selectedTeachers && selectedTeachers.id === item.id ? HiCheck : null}
                                                            onClick={() => setTeacher(item.id)}
                                                            key={item.id}
                                                        >
                                                            {item.first_name}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown>

                                            </div>
                                            {programeTeacherError && <p className="error pos_abo_stu">{programeTeacherError}</p>}
                                        </div>
                                        <div className="field-label half-field error_mes_cus extra_date_drop">
                                            <label>Class Timing</label>
                                            <div className="flexc justifySp">
                                                <div className="half-field">
                                                    <div className="field-value-holder margin_top10p dropbutton cus_drop_siz" >
                                                        <Dropdown
                                                            label={


                                                                <span
                                                                    className={`${selectedStartTime ? 'selected-item' : ''}`}
                                                                    style={{
                                                                        fontSize: "12px",
                                                                        justifyContent: "flex-start",
                                                                        color: selectedStartTime ? '#1B252F' : '#292929'
                                                                    }}
                                                                >

                                                                    {/* <span style={{ color: 'black', fontSize: '12px' }}> */}



                                                                    <i class="fa-regular fa-clock" style={{ marginRight: '6PX' }}></i>
                                                                    {selectedStartTime ? selectedStartTime.time_name : "Start Time"}</span>}
                                                            style={{
                                                                width: "100%"
                                                            }}
                                                        >
                                                            {timeData.map(item => (
                                                                <Dropdown.Item

                                                                    //className={selectedStartTime && selectedStartTime.id === item.id ? "dd_select" : null}

                                                                    className={`${selectedStartTime && selectedStartTime.id === item.id ? "selected-item dd_select" : ""
                                                                        }`}
                                                                    icon={selectedStartTime && selectedStartTime.id === item.id ? HiCheck : null}
                                                                    onClick={() => setProgramStartTime(item.id)}
                                                                    key={item.id}
                                                                >
                                                                    {item.time_name}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <div className="half-field">
                                                    <div className="field-value-holder margin_top10p dropbutton cus_drop_siz" >
                                                        <Dropdown
                                                            label={


                                                                //  <span style={{ color: 'black', fontSize: '12px' }}>

                                                                <span
                                                                    className={`${selectedEndTime ? 'selected-item' : ''}`}
                                                                    style={{


                                                                        fontSize: "13px",
                                                                        fontWeight: "400",
                                                                        lineHeight: "18.2px",
                                                                        textAlign: "left",

                                                                        justifyContent: "flex-start",
                                                                        color: selectedEndTime ? '#1B252F' : '#292929'
                                                                    }}
                                                                >



                                                                    <i class="fa-regular fa-clock" style={{ marginRight: '6PX' }}>
                                                                    </i>{selectedEndTime ? selectedEndTime.time_name : "End Time"}</span>}
                                                            style={{
                                                                width: "100%"
                                                            }}
                                                        >
                                                            {timeData.map(item => (
                                                                <Dropdown.Item
                                                                    // className={selectedEndTime && selectedEndTime.id === item.id ? "dd_select" : null}
                                                                    className={`${selectedEndTime && selectedEndTime.id === item.id ? "selected-item dd_select" : ""
                                                                        }`}

                                                                    icon={selectedEndTime && selectedEndTime.id === item.id ? HiCheck : null}
                                                                    onClick={() => setProgramEndTime(item.id)}
                                                                    key={item.id}
                                                                >
                                                                    {item.time_name}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            {timeError && <p className="error">{timeError}</p>}
                                        </div>
                                    </div>

                                    <h3 className="ft-wt600 mt-30">Customise Features</h3>
                                    <div className="full-field mb-6">
                                        {Object.keys(features).map((feature) => (
                                            <div className="flex items-center mb-4 custom-checkbox dis_chek_cus mt-5" key={feature}>
                                                <input
                                                    type="checkbox"
                                                    className="w-4 h-4 text-blue-600 rounded"
                                                    checked={features[feature].status}
                                                    disabled={
                                                        (['qrCode', 'attendance', 'food', 'sleep', 'leaves'].includes(feature))
                                                        // features[feature].status=== true
                                                    }
                                                    onChange={() => handleFeatureChange(feature)}
                                                />
                                                <label className="ms-2 text-sm">{features[feature].name}</label>
                                                {['busTracking', 'cctv', 'fees'].includes(feature) && <img src={SplImg} alt="spl" style={{ width: '25px', height: '15px', marginLeft: "5px" }} />}
                                            </div>
                                        ))}
                                    </div>
                                    <h3 className="ft-wt600 mt-30">Additional Informations</h3>
                                    <div className="full-field">
                                        {Object.keys(additionalInfo).map((info) => (
                                            <div className="flex items-center mb-4 custom-checkbox mt-5" key={info}>
                                                <input
                                                    type="checkbox"
                                                    className="w-4 h-4 text-blue-600 rounded"
                                                    checked={additionalInfo[info].status}
                                                    onChange={() => handleAdditionalInfoChange(info)}
                                                />
                                                <label className="ms-2 text-sm">{additionalInfo[info].name}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="sp-bottom-sticky">
                                    <button className="sp-btn-create crt_div" onClick={() => handleCreateProgram()}>{newProgramAddUpdateStatus === "add" ? "Create" : "Update"}</button>
                                    <button className="sp-btn-cancel">Cancel</button>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    deleteProgramConfirmVisible && (
                        <div
                            id="recycle-popup"
                            tabIndex={-1}
                            className="back_overlay fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full  max-h-full res_tot_div"
                        >
                            <div className="relative w-full max-w-xs max-h-full">
                                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4">   <div className="p-4 md:p-5 text-center">
                                    <div className="round-btn rounded inline-flex alignC justify-center">
                                        <img src={attpopup} alt="" />
                                    </div>
                                    {/* <p className="ft-wt600 ft-16 mb-4 mt-3">Mark as {attendanceStatus === 1 ? "Present" : attendanceStatus === 2 ? "Absent" : "Leave"}</p> */}
                                    <h3 className="mb-5 font-normal text-sm">
                                        Are you sure you want to delete the program
                                    </h3>
                                    <div className="att-popup">
                                        <button
                                            onClick={handleDeleteProgramConfirmClose}
                                            type="button"
                                            className="att-cancel can_cus_rec border-full ms-3 py-2.5 px-5  text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            // onClick={()=>{setCheckProgramChangeStatus(true); updateUser();}}
                                            onClick={() => handleDeleteProgramConfirm()}
                                            type="button"
                                            className="save_button_cus sp-blue-color text-white ms-3 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 rounded-md ft-13 inline-flex items-center px-5 py-2.5 text-center save_cus_di"
                                        >
                                            Yes, Proceed
                                        </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </section>
    );
}
