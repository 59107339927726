import $ from "jquery";

$(document).ready(function() {
    // $(document).on('click', '#sp-verfybtn', function() {
    //     $('.login-otp-inr').fadeIn();
    //     $(this).parent().parent().css('display', 'none');
    // });
    // $(document).on('click', '#sp-otp-btn', function() {
    //     //window.open('school-info.html');
    // });


    $(document).on('click', '.sp-btn-cancel', function() {
        $('.overlay').removeClass('visible');
        $('.popup-modal-container').removeClass('visible')
    });
    // $(document).on('click', '.sp-btn-create', function() {
    //     $('.overlay').removeClass('visible');
    //     $('.popup-modal-container').removeClass('visible')
    //     setTimeout(function() {
    //         $('.popup-student-details').addClass('visible');
    //     }, 500);
    // });
    $(document).on('click', '.popup-student-details .close', function() {
        $('.popup-student-details').removeClass('visible');
    });

    $(document).on('click', '.add-programs', function() {
        $('.overlay').removeClass('visible');
        // $('.popup-modal-program').removeClass('visible')
        setTimeout(function() {
            $('.popup-modal-program').addClass('visible');
        }, 500);
    });
    // $(document).on('click', '#edit-program-icon', function() {
    //     $('.overlay').addClass('visible');
    //     setTimeout(function() {
    //         $('.popup-modal-program').addClass('visible');
    //     }, 500);
    // });
    $(document).on('click', '.sp-btn-cancel', function() {
        $('.overlay').removeClass('visible');
        $('.popup-modal-program').removeClass('visible')
    });
    $(document).on('click', '.sp-list-view', function(index) {

        if ($(this).index() === 0) {
            //window.open('/index.html');
        } else if ($(this).index() === 1) {
            // window.open('/staffattendance.html');
        }
    });

    // $('.act-announce-inp input').on('click', function() {
    //     $('.announce-post-inr').slideDown();
    // });

      $(document).on('click', '#add-programs', function () {
        $('.overlay').addClass('visible');
        $('.popup-program-container').addClass('visible')
      });
    //   $(document).on('click', '#edit-program-icon', function () {
    //     $('.overlay').addClass('visible');
    //     $('.popup-program-container').addClass('visible')
    //   });
      $(document).on('click', '.popup-program-container .sp-btn-cancel', function () {
        $('.overlay').removeClass('visible');
        $('.popup-program-container').removeClass('visible');
      });
});

const popup = $(".dialog-model");
$("#sp-add-food").click(function (event) {
    event.stopPropagation();
    const clickedButton = $(this);
    $("#sp-add-food").addClass('selected');
    clickedButton.parent().addClass('selected');
    const buttonOffset = clickedButton.offset();
    const popupTop = buttonOffset.top + clickedButton.outerHeight() + 10;
    const popupLeft = buttonOffset.left + (clickedButton.outerWidth() / 2) - (popup.outerWidth() / 2) + 5;
    clickedButton.parent().append(popup);
    popup.css({
        display: "block"
    });
});

$(".dialog-model .cancel-btn").click(function (event) {
    event.stopPropagation();
    $('.outer-container').append(popup);
    $(".dialog-model").css('display', 'none');
    $("#sp-add-food").parent().removeClass('selected');
});