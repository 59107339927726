import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
// import router from "./Router";
import router from "./common/routers/Router";
import RootWrapper from "./common/routers/RootWrapper";
import Testing from "./Testing";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
      {/* <RootWrapper /> */}
    <RouterProvider router={router} />
    {/* <Testing/> */}
  </React.StrictMode>
);
