import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useStateContext } from "../../context/ContextProvider.jsx";
import "../../common/components/css/style1.css";
import panda_image from "./images/panda.png";
import password_image from "./images/password-icon.png";
import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import $ from "jquery";
import Snackbar from '@mui/material/Snackbar';
import validator from 'validator';
import { baseUrl } from "../../common/common";
import { Icon } from '@iconify/react';
import ListItemIcon from '@mui/material/ListItemIcon';
import SuccessIcon from "./images/success.png";
import ErrorIcon from "./images/error.png";

export default function Signup() {

    // const [status, setStatus] = useState(false);
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [password, setPassword] = useState("");
    const [termsChecked, setTermsChecked] = useState(true);
    const [fullNameError, setFullNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [mobileNumberError, setMobileNumberError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [termsCheckedError, setTermsCheckedError] = useState(null);
    const [dbInsertId, setdbInsertId] = useState(0);

    const navigate = useNavigate();

    const [verificationId, setVerificationId] = useState("");
    const [otp, setOTP] = useState("");
    const [otpError, setOtpError] = useState(null);

    let firebaseConfig = {
        apiKey: "AIzaSyDxStEcHP8L-wZMdcEwnklVSl4lHovSXuw",
        authDomain: "scool-panda.firebaseapp.com",
        projectId: "scool-panda",
        storageBucket: "scool-panda.appspot.com",
        messagingSenderId: "144297568045",
        appId: "1:144297568045:web:3e141a30b477e8409bd5a4",
        measurementId: "G-EDN8HRGH52"
    };

    firebase.initializeApp(firebaseConfig);

    const registerValidation = async () => {
        let valid = true;
        if (fullName === "") {
            setFullNameError("Name cannot be empty");
            valid = false;
        } else {
            setFullNameError(null);
        }
        if (email === "") {
            setEmailError("Email cannot be empty");
        } else {
            if (!validator.isEmail(email)) {
                setEmailError("Please Enter Valid Email");
                valid = false;
            } else {
                setEmailError(null);
            }
        }
        if (mobileNumber === "") {
            setMobileNumberError("Mobile number cannot be empty");
        } else {
            if (!validator.isMobilePhone(mobileNumber.trim()) || mobileNumber.trim().length !== 10) {
                setMobileNumberError("Please Enter Valid Mobile Number");
                valid = false;
            } else {
                setMobileNumberError(null);
            }
        }
        if (password === "") {
            setPasswordError("Password cannot be empty");
            valid = false;
        } else {
            setPasswordError(null);
        }
        if (termsChecked === false) {
            setTermsCheckedError("Please agree to the Terms & Conditions and Privacy Policy");
            valid = false;
        } else {
            setTermsCheckedError(null);
        }
        if (valid) {
            await axios({
                method: "post",
                url: baseUrl + "/api/school_register_validation",
                data: {
                    phone: mobileNumber.trim(),
                    email: email.trim(),
                    password: password.trim()
                },
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                if (response['data']['status'] == true) {
                    setMobileNumberError(null);
                    setEmailError(null);
                    setPasswordError(null);
                    // setStatus(true);
                    // setOpen(true);
                    sendOTP();
                    // const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
                    //     "recaptcha-container", {
                    //     size: "invisible"
                    // });
                    // firebase
                    //     .auth()
                    //     .signInWithPhoneNumber("+" + selectedCountry['phone'] + mobileNumber, recaptchaVerifier)
                    //     .then((confirmationResult) => {
                    //         setVerificationId(confirmationResult.verificationId);
                    //         setOtpError(null);
                    //     })
                    //     .catch((error) => {
                    //         setSnackbarMessage(error.message);
                    //         // setMessageType('error');
                    //         handleSnackbarOpen();
                    //         setOtpError(error.message);
                    //     });
                } else {
                    setMobileNumberError(response['data']['phone_error']);
                    setEmailError(response['data']['email_error']);
                    setPasswordError(response['data']['password_error']);
                }
            });
        }
        // navigate('/school_info',
        //     {
        //         state: {
        //             name: fullName,
        //             email: email,
        //             phone: mobileNumber,
        //             password: password,
        //             agree: termsChecked === true ? 1 : 0,
        //             otp: otpValues.join(''),
        //             dbInsertId: dbInsertId
        //         }
        //     }
        // );
    };

    const [otpStatus, setOtpStatus] = useState(false);
    const sendOTP = async () => {
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/AdminSendOTP",
                data: {
                    phone: mobileNumber
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    setdbInsertId(response.data.data_id);
                    setOtpStatus(true);
                    setSnackbarMessage(response.data.message);
                    setMessageType('success');
                    handleSnackbarOpen();
                    console.log(response);
                } else {
                    setSnackbarMessage(response.data.message);
                    setMessageType('error');
                    handleSnackbarOpen();
                }
            });
        } catch (error) {
            console.log(error)
        }
    };


    // const verifyOTP = () => {
    //     const credential = firebase.auth.PhoneAuthProvider.credential(
    //         verificationId,
    //         otpValues.join('')
    //     );

    //     firebase
    //         .auth()
    //         .signInWithCredential(credential)
    //         .then((result) => {
    //             navigate('/school_info',
    //                 {
    //                     state: {
    //                         name: fullName,
    //                         email: email,
    //                         phone: mobileNumber,
    //                         password: password,
    //                         agree: termsChecked === true ? 1 : 0,
    //                         otp: otpValues.join(''),
    //                     }
    //                 }
    //             );
    //         })
    //         .catch((error) => {
    //             setSnackbarMessage(error.message);
    //             // setMessageType('error');
    //             handleSnackbarOpen();
    //             setOtpError(error.message);
    //         });
    // };

    const verifyOTP = () => {
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/otpverify",
                data: {
                    phone: mobileNumber,
                    otp: otpValues.join('')
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    setSnackbarMessage(response.data.message);
                    setMessageType('success');
                    handleSnackbarOpen();
                    navigate('/school_info',
                        {
                            state: {
                                name: fullName,
                                email: email,
                                phone: mobileNumber,
                                password: password,
                                agree: termsChecked === true ? 1 : 0,
                                otp: otpValues.join(''),
                                dbInsertId: dbInsertId
                            }
                        }
                    );
                } else {
                    setSnackbarMessage(response.data.message);
                    setMessageType('error');
                    handleSnackbarOpen();
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const [otpValues, setOtpValues] = useState(["", "", "", ""]);
    const handleChange = (index, value) => {
        const newOtpValues = [...otpValues];
        newOtpValues[index] = value;
        setOtpValues(newOtpValues);
        if (value && index < 3) {
            document.getElementById(`otpInput${index + 1}`).focus();
        }
    };

    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleKeyDown = (e, nextInputId) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const nextInput = document.getElementById(nextInputId);
            if (nextInput) {
                nextInput.focus();
            }
        }
    };

    useEffect(() => {
        $(document).ready(function () {
            $(document).on('click', '#newStudentCreate', function () {
                $('.overlay').addClass('visible');
                $('.popup-modal-container').addClass('visible')
            });
            $(document).on('click', '.sp-btn-cancel', function () {
                $('.overlay').removeClass('visible');
                $('.popup-modal-container').removeClass('visible')
            });
            $(document).on('click', '.sp-table-body>div .checkbox', function () {
                $(this).find('i').toggleClass('checked');
                $(this).parent().toggleClass('m-checked');
                var c = 0;
                $('.sp-table-body>div .checkbox').each(function () {
                    if ($(this).parent().hasClass('m-checked')) {
                        c += 1;
                    }
                });
                if (c > 0) {
                    $('.sp-menugroup').addClass('hidden');
                    $('.sp-menu').removeClass('hidden');
                } else {
                    $('.sp-menugroup').removeClass('hidden');
                    $('.sp-menu').addClass('hidden');
                }
            });
            $(document).on('click', '.sp-btn-create', function () {
                $('.overlay').removeClass('visible');
                $('.popup-modal-container').removeClass('visible')
                setTimeout(function () {
                    $('.popup-student-details').addClass('visible');
                }, 500);
            });
            $(document).on('click', '.popup-student-details .close', function () {
                $('.popup-student-details').removeClass('visible');
            });
            $(document).on('click', '.sp-list-view', function (index) {
                if ($(this).index() === 0) {
                    //window.open('/index.html');
                } else if ($(this).index() === 1) {
                    // window.open('/staffattendance.html');
                }
            });
        });
    }, []);

    // --------------------------17-06-----------
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({
        name: "India",
        code: "IN",
        phone: 91
    });
    const [searchQuery, setSearchQuery] = useState("");
    const [isDropdownActive, setDropdownActive] = useState(false);

    useEffect(() => {
        const countriesData = [
            {
                name: "Afghanistan",
                code: "AF",
                phone: 93
            }, {
                name: "Aland Islands",
                code: "AX",
                phone: 358
            }, {
                name: "Albania",
                code: "AL",
                phone: 355
            }, {
                name: "Algeria",
                code: "DZ",
                phone: 213
            }, {
                name: "American Samoa",
                code: "AS",
                phone: 1684
            }, {
                name: "Andorra",
                code: "AD",
                phone: 376
            }, {
                name: "Angola",
                code: "AO",
                phone: 244
            }, {
                name: "Anguilla",
                code: "AI",
                phone: 1264
            }, {
                name: "Antarctica",
                code: "AQ",
                phone: 672
            }, {
                name: "Antigua and Barbuda",
                code: "AG",
                phone: 1268
            }, {
                name: "Argentina",
                code: "AR",
                phone: 54
            }, {
                name: "Armenia",
                code: "AM",
                phone: 374
            }, {
                name: "Aruba",
                code: "AW",
                phone: 297
            }, {
                name: "Australia",
                code: "AU",
                phone: 61
            }, {
                name: "Austria",
                code: "AT",
                phone: 43
            }, {
                name: "Azerbaijan",
                code: "AZ",
                phone: 994
            }, {
                name: "Bahamas",
                code: "BS",
                phone: 1242
            }, {
                name: "Bahrain",
                code: "BH",
                phone: 973
            }, {
                name: "Bangladesh",
                code: "BD",
                phone: 880
            }, {
                name: "Barbados",
                code: "BB",
                phone: 1246
            }, {
                name: "Belarus",
                code: "BY",
                phone: 375
            }, {
                name: "Belgium",
                code: "BE",
                phone: 32
            }, {
                name: "Belize",
                code: "BZ",
                phone: 501
            }, {
                name: "Benin",
                code: "BJ",
                phone: 229
            }, {
                name: "Bermuda",
                code: "BM",
                phone: 1441
            }, {
                name: "Bhutan",
                code: "BT",
                phone: 975
            }, {
                name: "Bolivia",
                code: "BO",
                phone: 591
            }, {
                name: "Bonaire, Sint Eustatius and Saba",
                code: "BQ",
                phone: 599
            }, {
                name: "Bosnia and Herzegovina",
                code: "BA",
                phone: 387
            }, {
                name: "Botswana",
                code: "BW",
                phone: 267
            }, {
                name: "Bouvet Island",
                code: "BV",
                phone: 55
            }, {
                name: "Brazil",
                code: "BR",
                phone: 55
            }, {
                name: "British Indian Ocean Territory",
                code: "IO",
                phone: 246
            }, {
                name: "Brunei Darussalam",
                code: "BN",
                phone: 673
            }, {
                name: "Bulgaria",
                code: "BG",
                phone: 359
            }, {
                name: "Burkina Faso",
                code: "BF",
                phone: 226
            }, {
                name: "Burundi",
                code: "BI",
                phone: 257
            }, {
                name: "Cambodia",
                code: "KH",
                phone: 855
            }, {
                name: "Cameroon",
                code: "CM",
                phone: 237
            }, {
                name: "Canada",
                code: "CA",
                phone: 1
            }, {
                name: "Cape Verde",
                code: "CV",
                phone: 238
            }, {
                name: "Cayman Islands",
                code: "KY",
                phone: 1345
            }, {
                name: "Central African Republic",
                code: "CF",
                phone: 236
            }, {
                name: "Chad",
                code: "TD",
                phone: 235
            }, {
                name: "Chile",
                code: "CL",
                phone: 56
            }, {
                name: "China",
                code: "CN",
                phone: 86
            }, {
                name: "Christmas Island",
                code: "CX",
                phone: 61
            }, {
                name: "Cocos (Keeling) Islands",
                code: "CC",
                phone: 672
            }, {
                name: "Colombia",
                code: "CO",
                phone: 57
            }, {
                name: "Comoros",
                code: "KM",
                phone: 269
            }, {
                name: "Congo",
                code: "CG",
                phone: 242
            }, {
                name: "Congo, Democratic Republic of the Congo",
                code: "CD",
                phone: 242
            }, {
                name: "Cook Islands",
                code: "CK",
                phone: 682
            }, {
                name: "Costa Rica",
                code: "CR",
                phone: 506
            }, {
                name: "Cote D'Ivoire",
                code: "CI",
                phone: 225
            }, {
                name: "Croatia",
                code: "HR",
                phone: 385
            }, {
                name: "Cuba",
                code: "CU",
                phone: 53
            }, {
                name: "Curacao",
                code: "CW",
                phone: 599
            }, {
                name: "Cyprus",
                code: "CY",
                phone: 357
            }, {
                name: "Czech Republic",
                code: "CZ",
                phone: 420
            }, {
                name: "Denmark",
                code: "DK",
                phone: 45
            }, {
                name: "Djibouti",
                code: "DJ",
                phone: 253
            }, {
                name: "Dominica",
                code: "DM",
                phone: 1767
            }, {
                name: "Dominican Republic",
                code: "DO",
                phone: 1809
            }, {
                name: "Ecuador",
                code: "EC",
                phone: 593
            }, {
                name: "Egypt",
                code: "EG",
                phone: 20
            }, {
                name: "El Salvador",
                code: "SV",
                phone: 503
            }, {
                name: "Equatorial Guinea",
                code: "GQ",
                phone: 240
            }, {
                name: "Eritrea",
                code: "ER",
                phone: 291
            }, {
                name: "Estonia",
                code: "EE",
                phone: 372
            }, {
                name: "Ethiopia",
                code: "ET",
                phone: 251
            }, {
                name: "Falkland Islands (Malvinas)",
                code: "FK",
                phone: 500
            }, {
                name: "Faroe Islands",
                code: "FO",
                phone: 298
            }, {
                name: "Fiji",
                code: "FJ",
                phone: 679
            }, {
                name: "Finland",
                code: "FI",
                phone: 358
            }, {
                name: "France",
                code: "FR",
                phone: 33
            }, {
                name: "French Guiana",
                code: "GF",
                phone: 594
            }, {
                name: "French Polynesia",
                code: "PF",
                phone: 689
            }, {
                name: "French Southern Territories",
                code: "TF",
                phone: 262
            }, {
                name: "Gabon",
                code: "GA",
                phone: 241
            }, {
                name: "Gambia",
                code: "GM",
                phone: 220
            }, {
                name: "Georgia",
                code: "GE",
                phone: 995
            }, {
                name: "Germany",
                code: "DE",
                phone: 49
            }, {
                name: "Ghana",
                code: "GH",
                phone: 233
            }, {
                name: "Gibraltar",
                code: "GI",
                phone: 350
            }, {
                name: "Greece",
                code: "GR",
                phone: 30
            }, {
                name: "Greenland",
                code: "GL",
                phone: 299
            }, {
                name: "Grenada",
                code: "GD",
                phone: 1473
            }, {
                name: "Guadeloupe",
                code: "GP",
                phone: 590
            }, {
                name: "Guam",
                code: "GU",
                phone: 1671
            }, {
                name: "Guatemala",
                code: "GT",
                phone: 502
            }, {
                name: "Guernsey",
                code: "GG",
                phone: 44
            }, {
                name: "Guinea",
                code: "GN",
                phone: 224
            }, {
                name: "Guinea-Bissau",
                code: "GW",
                phone: 245
            }, {
                name: "Guyana",
                code: "GY",
                phone: 592
            }, {
                name: "Haiti",
                code: "HT",
                phone: 509
            }, {
                name: "Heard Island and McDonald Islands",
                code: "HM",
                phone: 0
            }, {
                name: "Holy See (Vatican City State)",
                code: "VA",
                phone: 39
            }, {
                name: "Honduras",
                code: "HN",
                phone: 504
            }, {
                name: "Hong Kong",
                code: "HK",
                phone: 852
            }, {
                name: "Hungary",
                code: "HU",
                phone: 36
            }, {
                name: "Iceland",
                code: "IS",
                phone: 354
            }, {
                name: "India",
                code: "IN",
                phone: 91
            }, {
                name: "Indonesia",
                code: "ID",
                phone: 62
            }, {
                name: "Iran, Islamic Republic of",
                code: "IR",
                phone: 98
            }, {
                name: "Iraq",
                code: "IQ",
                phone: 964
            }, {
                name: "Ireland",
                code: "IE",
                phone: 353
            }, {
                name: "Isle of Man",
                code: "IM",
                phone: 44
            }, {
                name: "Israel",
                code: "IL",
                phone: 972
            }, {
                name: "Italy",
                code: "IT",
                phone: 39
            }, {
                name: "Jamaica",
                code: "JM",
                phone: 1876
            }, {
                name: "Japan",
                code: "JP",
                phone: 81
            }, {
                name: "Jersey",
                code: "JE",
                phone: 44
            }, {
                name: "Jordan",
                code: "JO",
                phone: 962
            }, {
                name: "Kazakhstan",
                code: "KZ",
                phone: 7
            }, {
                name: "Kenya",
                code: "KE",
                phone: 254
            }, {
                name: "Kiribati",
                code: "KI",
                phone: 686
            }, {
                name: "Korea, Democratic People's Republic of",
                code: "KP",
                phone: 850
            }, {
                name: "Korea, Republic of",
                code: "KR",
                phone: 82
            }, {
                name: "Kosovo",
                code: "XK",
                phone: 383
            }, {
                name: "Kuwait",
                code: "KW",
                phone: 965
            }, {
                name: "Kyrgyzstan",
                code: "KG",
                phone: 996
            }, {
                name: "Lao People's Democratic Republic",
                code: "LA",
                phone: 856
            }, {
                name: "Latvia",
                code: "LV",
                phone: 371
            }, {
                name: "Lebanon",
                code: "LB",
                phone: 961
            }, {
                name: "Lesotho",
                code: "LS",
                phone: 266
            }, {
                name: "Liberia",
                code: "LR",
                phone: 231
            }, {
                name: "Libyan Arab Jamahiriya",
                code: "LY",
                phone: 218
            }, {
                name: "Liechtenstein",
                code: "LI",
                phone: 423
            }, {
                name: "Lithuania",
                code: "LT",
                phone: 370
            }, {
                name: "Luxembourg",
                code: "LU",
                phone: 352
            }, {
                name: "Macao",
                code: "MO",
                phone: 853
            }, {
                name: "Macedonia, the Former Yugoslav Republic of",
                code: "MK",
                phone: 389
            }, {
                name: "Madagascar",
                code: "MG",
                phone: 261
            }, {
                name: "Malawi",
                code: "MW",
                phone: 265
            }, {
                name: "Malaysia",
                code: "MY",
                phone: 60
            }, {
                name: "Maldives",
                code: "MV",
                phone: 960
            }, {
                name: "Mali",
                code: "ML",
                phone: 223
            }, {
                name: "Malta",
                code: "MT",
                phone: 356
            }, {
                name: "Marshall Islands",
                code: "MH",
                phone: 692
            }, {
                name: "Martinique",
                code: "MQ",
                phone: 596
            }, {
                name: "Mauritania",
                code: "MR",
                phone: 222
            }, {
                name: "Mauritius",
                code: "MU",
                phone: 230
            }, {
                name: "Mayotte",
                code: "YT",
                phone: 262
            }, {
                name: "Mexico",
                code: "MX",
                phone: 52
            }, {
                name: "Micronesia, Federated States of",
                code: "FM",
                phone: 691
            }, {
                name: "Moldova, Republic of",
                code: "MD",
                phone: 373
            }, {
                name: "Monaco",
                code: "MC",
                phone: 377
            }, {
                name: "Mongolia",
                code: "MN",
                phone: 976
            }, {
                name: "Montenegro",
                code: "ME",
                phone: 382
            }, {
                name: "Montserrat",
                code: "MS",
                phone: 1664
            }, {
                name: "Morocco",
                code: "MA",
                phone: 212
            }, {
                name: "Mozambique",
                code: "MZ",
                phone: 258
            }, {
                name: "Myanmar",
                code: "MM",
                phone: 95
            }, {
                name: "Namibia",
                code: "NA",
                phone: 264
            }, {
                name: "Nauru",
                code: "NR",
                phone: 674
            }, {
                name: "Nepal",
                code: "NP",
                phone: 977
            }, {
                name: "Netherlands",
                code: "NL",
                phone: 31
            }, {
                name: "Netherlands Antilles",
                code: "AN",
                phone: 599
            }, {
                name: "New Caledonia",
                code: "NC",
                phone: 687
            }, {
                name: "New Zealand",
                code: "NZ",
                phone: 64
            }, {
                name: "Nicaragua",
                code: "NI",
                phone: 505
            }, {
                name: "Niger",
                code: "NE",
                phone: 227
            }, {
                name: "Nigeria",
                code: "NG",
                phone: 234
            }, {
                name: "Niue",
                code: "NU",
                phone: 683
            }, {
                name: "Norfolk Island",
                code: "NF",
                phone: 672
            }, {
                name: "Northern Mariana Islands",
                code: "MP",
                phone: 1670
            }, {
                name: "Norway",
                code: "NO",
                phone: 47
            }, {
                name: "Oman",
                code: "OM",
                phone: 968
            }, {
                name: "Pakistan",
                code: "PK",
                phone: 92
            }, {
                name: "Palau",
                code: "PW",
                phone: 680
            }, {
                name: "Palestinian Territory, Occupied",
                code: "PS",
                phone: 970
            }, {
                name: "Panama",
                code: "PA",
                phone: 507
            }, {
                name: "Papua New Guinea",
                code: "PG",
                phone: 675
            }, {
                name: "Paraguay",
                code: "PY",
                phone: 595
            }, {
                name: "Peru",
                code: "PE",
                phone: 51
            }, {
                name: "Philippines",
                code: "PH",
                phone: 63
            }, {
                name: "Pitcairn",
                code: "PN",
                phone: 64
            }, {
                name: "Poland",
                code: "PL",
                phone: 48
            }, {
                name: "Portugal",
                code: "PT",
                phone: 351
            }, {
                name: "Puerto Rico",
                code: "PR",
                phone: 1787
            }, {
                name: "Qatar",
                code: "QA",
                phone: 974
            }, {
                name: "Reunion",
                code: "RE",
                phone: 262
            }, {
                name: "Romania",
                code: "RO",
                phone: 40
            }, {
                name: "Russian Federation",
                code: "RU",
                phone: 7
            }, {
                name: "Rwanda",
                code: "RW",
                phone: 250
            }, {
                name: "Saint Barthelemy",
                code: "BL",
                phone: 590
            }, {
                name: "Saint Helena",
                code: "SH",
                phone: 290
            }, {
                name: "Saint Kitts and Nevis",
                code: "KN",
                phone: 1869
            }, {
                name: "Saint Lucia",
                code: "LC",
                phone: 1758
            }, {
                name: "Saint Martin",
                code: "MF",
                phone: 590
            }, {
                name: "Saint Pierre and Miquelon",
                code: "PM",
                phone: 508
            }, {
                name: "Saint Vincent and the Grenadines",
                code: "VC",
                phone: 1784
            }, {
                name: "Samoa",
                code: "WS",
                phone: 684
            }, {
                name: "San Marino",
                code: "SM",
                phone: 378
            }, {
                name: "Sao Tome and Principe",
                code: "ST",
                phone: 239
            }, {
                name: "Saudi Arabia",
                code: "SA",
                phone: 966
            }, {
                name: "Senegal",
                code: "SN",
                phone: 221
            }, {
                name: "Serbia",
                code: "RS",
                phone: 381
            }, {
                name: "Serbia and Montenegro",
                code: "CS",
                phone: 381
            }, {
                name: "Seychelles",
                code: "SC",
                phone: 248
            }, {
                name: "Sierra Leone",
                code: "SL",
                phone: 232
            }, {
                name: "Singapore",
                code: "SG",
                phone: 65
            }, {
                name: "St Martin",
                code: "SX",
                phone: 721
            }, {
                name: "Slovakia",
                code: "SK",
                phone: 421
            }, {
                name: "Slovenia",
                code: "SI",
                phone: 386
            }, {
                name: "Solomon Islands",
                code: "SB",
                phone: 677
            }, {
                name: "Somalia",
                code: "SO",
                phone: 252
            }, {
                name: "South Africa",
                code: "ZA",
                phone: 27
            }, {
                name: "South Georgia and the South Sandwich Islands",
                code: "GS",
                phone: 500
            }, {
                name: "South Sudan",
                code: "SS",
                phone: 211
            }, {
                name: "Spain",
                code: "ES",
                phone: 34
            }, {
                name: "Sri Lanka",
                code: "LK",
                phone: 94
            }, {
                name: "Sudan",
                code: "SD",
                phone: 249
            }, {
                name: "Suriname",
                code: "SR",
                phone: 597
            }, {
                name: "Svalbard and Jan Mayen",
                code: "SJ",
                phone: 47
            }, {
                name: "Swaziland",
                code: "SZ",
                phone: 268
            }, {
                name: "Sweden",
                code: "SE",
                phone: 46
            }, {
                name: "Switzerland",
                code: "CH",
                phone: 41
            }, {
                name: "Syrian Arab Republic",
                code: "SY",
                phone: 963
            }, {
                name: "Taiwan, Province of China",
                code: "TW",
                phone: 886
            }, {
                name: "Tajikistan",
                code: "TJ",
                phone: 992
            }, {
                name: "Tanzania, United Republic of",
                code: "TZ",
                phone: 255
            }, {
                name: "Thailand",
                code: "TH",
                phone: 66
            }, {
                name: "Timor-Leste",
                code: "TL",
                phone: 670
            }, {
                name: "Togo",
                code: "TG",
                phone: 228
            }, {
                name: "Tokelau",
                code: "TK",
                phone: 690
            }, {
                name: "Tonga",
                code: "TO",
                phone: 676
            }, {
                name: "Trinidad and Tobago",
                code: "TT",
                phone: 1868
            }, {
                name: "Tunisia",
                code: "TN",
                phone: 216
            }, {
                name: "Turkey",
                code: "TR",
                phone: 90
            }, {
                name: "Turkmenistan",
                code: "TM",
                phone: 7370
            }, {
                name: "Turks and Caicos Islands",
                code: "TC",
                phone: 1649
            }, {
                name: "Tuvalu",
                code: "TV",
                phone: 688
            }, {
                name: "Uganda",
                code: "UG",
                phone: 256
            }, {
                name: "Ukraine",
                code: "UA",
                phone: 380
            }, {
                name: "United Arab Emirates",
                code: "AE",
                phone: 971
            }, {
                name: "United Kingdom",
                code: "GB",
                phone: 44
            }, {
                name: "United States",
                code: "US",
                phone: 1
            }, {
                name: "United States Minor Outlying Islands",
                code: "UM",
                phone: 1
            }, {
                name: "Uruguay",
                code: "UY",
                phone: 598
            }, {
                name: "Uzbekistan",
                code: "UZ",
                phone: 998
            }, {
                name: "Vanuatu",
                code: "VU",
                phone: 678
            }, {
                name: "Venezuela",
                code: "VE",
                phone: 58
            }, {
                name: "Viet Nam",
                code: "VN",
                phone: 84
            }, {
                name: "Virgin Islands, British",
                code: "VG",
                phone: 1284
            }, {
                name: "Virgin Islands, U.s.",
                code: "VI",
                phone: 1340
            }, {
                name: "Wallis and Futuna",
                code: "WF",
                phone: 681
            }, {
                name: "Western Sahara",
                code: "EH",
                phone: 212
            }, {
                name: "Yemen",
                code: "YE",
                phone: 967
            }, {
                name: "Zambia",
                code: "ZM",
                phone: 260
            }, {
                name: "Zimbabwe",
                code: "ZW",
                phone: 263
            }
        ];
        setCountries(countriesData);
    }, []);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    const handleCountrySelect = (country) => {
        setSelectedCountry(country);
        setDropdownActive(false);
        setSearchQuery("");
    };

    const filteredCountries = countries.filter(country =>
        country.name.toLowerCase().includes(searchQuery)
    );

    // ------11-06----------------
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [snackbarState, setSnackbarState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center'
    });
    const handleSnackbarClose = () => {
        setSnackbarState({ ...snackbarState, open: false });
    };
    const handleSnackbarOpen = () => {
        setSnackbarState({ ...snackbarState, open: true });
    };
    useEffect(() => { }, [snackbarMessage, messageType]);

    // ------------04-09-------------
    const countryCodeRef = useRef(null);

    const handleClickOutside = (event) => {
        if (countryCodeRef.current && !countryCodeRef.current.contains(event.target)) {
            setDropdownActive(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <section className='sign_cus_page'>
                <Snackbar
                    anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
                    open={snackbarState.open}
                    onClose={handleSnackbarClose}
                    message={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ListItemIcon>
                                <img src={messageType === 'success' ? SuccessIcon : ErrorIcon} alt="Success" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                            </ListItemIcon>
                            <div className="success_cuc">
                                <p> {snackbarMessage} </p>
                            </div>
                        </div>
                    }
                    ContentProps={{
                        sx: {
                            border: "1px solid",
                            opacity: "1",
                            background: messageType === 'success' ? "#40BE61" : "#FD4954",
                            borderColor: messageType === 'success' ? "#40BE61" : "#FD4954",
                            width: '415px'
                        }
                    }}
                    autoHideDuration={3000}
                />
                <div className="whole-container sign_page">
                    <div className="log-container">
                        <div className="login-cont-inr flex-prop">
                            <div className="login-cont-left">
                                <div className="log-left-inr flex-prop">
                                    <div className="mrg-cen main_panda">
                                        <img src={panda_image} style={{ width: "80px" }} width="80" height="100" alt="Scool Panda" />
                                        <h3 className="scl-name">Scool Panda</h3>
                                        <span>Transforming Learning Communication</span>
                                    </div>
                                </div>
                            </div>
                            <div className="login-cont-right">
                                <div className="flex-prop ">
                                    <div className="sign-inp mrg-cen ">
                                        {otpStatus === false ? (
                                            <div className="login-cont-inner ">
                                                <div className="sign-form pad_div_5t">
                                                    <h1>Starting your new journey It’s very easy to setup!</h1>
                                                    <div className="forms-fields-text error_mes_cus">
                                                        <label htmlFor="fullName">Full Name</label>
                                                        <input
                                                            type="text"
                                                            id="fullName"
                                                            value={fullName}
                                                            onChange={(e) => setFullName(e.target.value)}
                                                            onKeyDown={(e) => handleKeyDown(e, "email")}
                                                            placeholder="Enter your full name"
                                                            style={{ borderColor: fullNameError ? 'red' : '', paddingLeft: "15px" }}
                                                        />
                                                        {fullNameError && <p className="error">{fullNameError}</p>}
                                                    </div>
                                                    <div className="forms-fields-text error_mes_cus">
                                                        <label htmlFor="email">Email</label>
                                                        <input
                                                            type="text"
                                                            id="email"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            onKeyDown={(e) => handleKeyDown(e, "mobileNumber")}
                                                            placeholder="Enter your email"
                                                            style={{ borderColor: emailError ? 'red' : '', paddingLeft: "15px" }}
                                                        />
                                                        {emailError && <p className="error">{emailError}</p>}
                                                    </div>
                                                    <div className="mar_20bott error_mes_cus">
                                                        {/* <div className="num-cont flex-prop select-box forms-fields-text" style={{ marginBottom: '5px' }}>
                                                        <div className="num-box">
                                                            <label>Code</label>
                                                            <button className="sp-btn-number">
                                                                <span className="iconify" data-icon="flag:gb-4x3"></span>
                                                                <span>+91</span>
                                                            </button>
                                                            <div className="options">
                                                                <input type="text" className="search-box" placeholder="Search Country Name" style={{ paddingLeft: "15px" }} />
                                                                <ol></ol>
                                                            </div>
                                                        </div>
                                                        <p>
                                                            <label htmlFor="mobileNumber">Mobile Number</label>
                                                            <input
                                                                type="number"
                                                                id="mobileNumber"
                                                                value={mobileNumber}
                                                                onChange={(e) => setMobileNumber(e.target.value)}
                                                                onKeyDown={(e) => handleKeyDown(e, "password")}
                                                                placeholder="Enter Mobile Number"
                                                                style={{ borderColor: mobileNumberError ? 'red' : '', paddingLeft: "15px" }}
                                                            />

                                                        </p>

                                                    </div> */}
                                                        <div className="num-cont flex-prop select-box forms-fields-text" style={{ marginBottom: "0px" }}>
                                                            <div className="num-box" ref={countryCodeRef}>
                                                                <label>Code</label>
                                                                <button
                                                                    className="sp-btn-number"
                                                                    style={{
                                                                        border:isDropdownActive&&"1px solid #1c64f2"
                                                                    }}
                                                                    onClick={() => setDropdownActive(!isDropdownActive)}
                                                                >
                                                                    <Icon icon={`flag:${selectedCountry.code.toLowerCase()}-4x3`} />
                                                                    <span>+{selectedCountry.phone}</span>
                                                                </button>
                                                                {isDropdownActive && (
                                                                    <div className="options active">
                                                                        <input
                                                                            type="text"
                                                                            className="search-box"
                                                                            placeholder="Search Country Name"
                                                                            value={searchQuery}
                                                                            onChange={handleSearch}
                                                                        />
                                                                        <ol>
                                                                            {filteredCountries.map(country => (
                                                                                <li
                                                                                    key={country.code}
                                                                                    className="option"
                                                                                    onClick={() => handleCountrySelect(country)}
                                                                                >
                                                                                    <div>
                                                                                        <Icon icon={`flag:${country.code.toLowerCase()}-4x3`} />
                                                                                        <span className="country-name">{country.name}</span>
                                                                                    </div>
                                                                                    <em>+{country.phone}</em>
                                                                                </li>
                                                                            ))}
                                                                        </ol>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <p>
                                                                <label htmlFor="mobileNumber">Mobile Number</label>
                                                                <input
                                                                    type="text"
                                                                    id="mobileNumber"
                                                                    value={mobileNumber}
                                                                    onChange={(e) => setMobileNumber(e.target.value)}
                                                                    onKeyDown={(e) => handleKeyDown(e, "password")}
                                                                    placeholder="Enter Mobile Number"
                                                                    style={{ borderColor: mobileNumberError ? 'red' : '', paddingLeft: "15px" }}
                                                                />
                                                            </p>
                                                        </div>
                                                        {mobileNumberError && <p className="error" >{mobileNumberError}</p>}
                                                    </div>
                                                    <div className="forms-fields-text error_mes_cus">
                                                        <label htmlFor="password">Password</label>
                                                        <input
                                                            type="password"
                                                            id="password"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            onKeyDown={(e) => handleKeyDown(e, "sp-verfybtn")}
                                                            placeholder="Type password"
                                                            style={{ borderColor: passwordError ? 'red' : '', paddingLeft: "15px" }}
                                                        />
                                                        {passwordError && <p className="error">{passwordError}</p>}
                                                    </div>

                                                    <div className="error_mes_cus">
                                                        <div className="terms-cont forms-fields-text">
                                                            <label className="label-container">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={termsChecked}
                                                                    onChange={(e) => setTermsChecked(e.target.checked)}
                                                                />
                                                                <span className="tick-ele"></span>
                                                            </label>
                                                            <span>I agree to the <a>Terms & Conditions</a> and <a>Privacy Policy</a></span>
                                                        </div>
                                                        {termsCheckedError && <p className="error">{termsCheckedError}</p>}


                                                    </div>
                                                    <button onClick={registerValidation} className="verify-btn" id="sp-verfybtn">Verify</button>
                                                    <div className="acount_type">
                                                        <span>Already have an account? <a><Link to="/signin"> Sign In</Link></a></span>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="login-otp-inr">
                                                <div className="flex-prop ">
                                                    <div className="login-cont-right flex-prop otp-right-inr main_panda">
                                                        <div className="main_panda error_mes_cus">
                                                            {/* <Snackbar
                                                                open={open}
                                                                onClose={handleClose}
                                                                autoHideDuration={3000}
                                                                message="OTP Sent Successfully"
                                                            /> */}
                                                            <img src={password_image} style={{ width: "50px" }} width="50" alt="Scool Panda" />
                                                            <p>OTP Verification</p>
                                                            {/* <span>OTP has sent to your mobile number and email address</span> */}
                                                            <span>OTP has sent to your mobile number</span>
                                                            <div className="opt-boxes flex-prop otp_font">
                                                                {otpValues.map((value, index) => (
                                                                    <input
                                                                        key={index}
                                                                        type="number"
                                                                        id={`otpInput${index}`}
                                                                        className="sp-otp-inout "
                                                                        value={value}
                                                                        onChange={(e) => handleChange(index, e.target.value)}
                                                                        maxLength={1}
                                                                        onKeyDown={(e) => handleKeyDown(e, `otpInput${index + 1}`)}
                                                                    />
                                                                ))}
                                                            </div>
                                                            {otpError && <p className="error">{otpError}</p>}
                                                            <button onClick={verifyOTP} className="verify-btn" disabled={otpValues.some(value => !value)}>START A NEW JOURNEY</button>
                                                            <div>
                                                                <span>Didn’t Receive OTP? <span className="resent_otp" onClick={() => { setOtpValues(["", "", "", ""]); sendOTP(); }}>Resend OTP</span></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <div id="recaptcha-container"></div> */}
        </>
    );
}

