import React from 'react';
import { Navigate } from 'react-router-dom';

const RedirectIfLoggedIn = () => {
    const schoolid = localStorage.getItem('schoolId');
    if (schoolid) {
        return <Navigate to="/student_list" />;
    } else {
        return <Navigate to="/signin" />;
    }
};

export default RedirectIfLoggedIn;
