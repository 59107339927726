import avatar from "./images/avatar1.png";
import React, { useState, useRef, useEffect } from "react";
import axios from 'axios';
import $ from "jquery";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../../common/common";
import Loader from "react-js-loader";
import '../../common/components/css/style.css';
import avatar1 from "./images/avatar1.png";
import "../../index.css";
import Snackbar from '@mui/material/Snackbar';
import ListItemIcon from '@mui/material/ListItemIcon';
import SuccessIcon from "./images/success.svg";
import ErrorIcon from "./images/error.svg";
import SplImg from "./images/spl.png";
import { Dropdown } from "flowbite-react";
import { HiCheck } from "react-icons/hi";
import ImportComponent from "../Import/Import";
import tik from "./images/tik.svg";
import cross from "./images/cross.svg";
import leave from "./images/leave.svg";
import leave1 from "./images/leave1.svg";
import tik1 from "./images/tik1.svg";
import cross1 from "./images/cross1.svg";
import gift from "./images/gift.svg";
import upar from "./images/upar.svg";
import ques from "./images/ques.svg";
import { WindmillSpinner } from 'react-spinner-overlay';
import moment from 'moment';
import validator from "validator";
import { DatePicker } from "antd";
import dayjs from 'dayjs';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import attpopup from "./images/attpopup.svg";
import Sidebar from "../../common/layouts/CommonSidebar.jsx";
import { LogoComponent } from "../school/Common.jsx";
import CommonSidebarHeader from "../../common/layouts/CommonSidebarHeader.jsx";

export default function Staffattendance() {

    const navigate = useNavigate();
    const [programsData, setProgramsData] = useState({});
    const [programsLoading, setProgramsLoading] = useState(false);
    const fetchProgramsData = async () => {
        setProgramsLoading(false);
        try {
            const postData = {
                school_id: localStorage.getItem('schoolId')
                // school_id:24
            }
            const { data } = await axios.post(baseUrl + "/api/get_programs", postData, {
                headers: {
                    "Authorization": localStorage.getItem('access_token')
                }
            });
            setProgramsData(data.data);
            setProgramsLoading(true);
        } catch (error) {
            console.log(error)
        }
    }

    const [foodData, setFoodData] = useState({});
    const [dataHeader, setDataHeader] = useState([]);
    const [foodHeaderdata, setFoodHeaderData] = useState([]);
    const [foodLoading, setFoodLoading] = useState(false);

    const [filterValue, setFilterValue] = useState('');
    const handleInputChange = async (event) => {
        setFilterValue(event.target.value);
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/staff_attendance_list_admin",
                data: {
                    school_id: localStorage.getItem('schoolId'),
                    search: event.target.value.trim(),
                    // programs_id: localStorage.getItem('programId'),
                    // list_status: 1
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response['data']['status'] === true) {
                    setFoodData(response.data);
                    setDataHeader(response.data.dateresult);
                    setFoodHeaderData(response.data.data);
                    setFoodLoading(true);
                } else {
                    setFoodData(response.data);
                    setFoodLoading(true);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };
    /* 31-07-2024 */
    const clearInput = () => {
        setFilterValue('');
        fetchAttendanceData();
    };
    const fetchAttendanceData = () => {
        setFoodLoading(false);
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/staff_attendance_list_admin",
                data: {
                    school_id: localStorage.getItem('schoolId'),
                    search: ""
                    // programs_id: localStorage.getItem('programId'),
                    // list_status: 1
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response['data']['status'] === true) {
                    setFoodData(response.data);
                    setDataHeader(response.data.dateresult);
                    setFoodHeaderData(response.data.data);
                    setFoodLoading(true);
                } else {
                    setFoodData(response.data);
                    setFoodLoading(true);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const [selectedStaff, setselectedStaff] = useState([]);

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allStudentIds = foodHeaderdata.map(student => student.id);
            setselectedStaff(allStudentIds);
        } else {
            setselectedStaff([]);
        }
    };

    const handleSelectStudent = (studentId) => {
        setselectedStaff(prevSelected =>
            prevSelected.includes(studentId)
                ? prevSelected.filter(id => id !== studentId)
                : [...prevSelected, studentId]
        );
    };

    const [error, setError] = useState(null);
    const addAttendance = () => {
        const today = new Date();
        if (selectedStaff.length === 0) {
            // setError("Please Select Student")
            // setOpen(true);
            setSnackbarMessage("Please Select Student");
            setMessageType('error');
            handleSnackbarOpen();
        } else {
            const postData = {
                school_id: localStorage.getItem('schoolId'),
                attendance_date: today.toISOString().split('T')[0],
                programs_id: localStorage.getItem('programId'),
                staff_ids: selectedStaff,
                attendance_status: attendanceStatus,
                status: 1,
                created_by: localStorage.getItem('schoolId'),
                updated_by: localStorage.getItem('schoolId')
            }
            console.log(postData)
            axios({
                method: "post",
                url: baseUrl + "/api/staff_attendance_add_edit",
                data: postData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    setselectedStaff([])
                    setSnackbarMessage(response.data.message);
                    setMessageType('success');
                    handleSnackbarOpen();
                    handleAttendanceClose();
                    fetchAttendanceData();
                } else {
                    setSnackbarMessage(response.data.message);
                    setMessageType('error');
                    handleSnackbarOpen();
                }
            });
        }

    }

    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    // ------11-06----------------
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [snackbarState, setSnackbarState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center'
    });
    const handleSnackbarClose = () => {
        setSnackbarState({ ...snackbarState, open: false });
    };
    const handleSnackbarOpen = () => {
        setSnackbarState({ ...snackbarState, open: true });
    };
    useEffect(() => { }, [snackbarMessage, messageType]);

    // -------------------------19-06-------------------------

    const handleOpenNewProgramPopupVisible = () => {
        setProgramNameError(null);
        setProgramTeacherError(null);
        setTimeError(null);
        setTeacher("");
        setProgramStartTime("");
        setProgramEndTime("");
        setNewProgramPopupVisible(true);
    }

    const [newProgramPopupVisible, setNewProgramPopupVisible] = useState(false);
    const [programStartTime, setProgramStartTime] = useState("");
    const [programEndTime, setProgramEndTime] = useState("");
    const [programTeacher, setTeacher] = useState("");
    const [timeData, setTimeData] = useState([]);
    const [teachersData, setTeachersData] = useState([]);
    const selectedStartTime = timeData.find(data => data.id === programStartTime);
    const selectedEndTime = timeData.find(data => data.id === programEndTime);
    const selectedTeachers = teachersData.find(data => data.id === programTeacher);

    const fetchProgramTimeData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/get_time",
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setTimeData(response.data.data);
                console.log(response);
            }
        });
    }

    const fetchTeachersData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/teacher_list",
            data: {
                school_id: localStorage.getItem("schoolId")
            },
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setTeachersData(response.data.data);
                console.log(response);
            }
        });
    }

    const [newProgramName, setNewProgramName] = useState('');
    const [features, setFeatures] = useState({
        qrCode: { status: true, name: 'QR Code', value: 2 },
        attendance: { status: true, name: 'Attendance', value: 5 },
        food: { status: true, name: 'Food', value: 3 },
        sleep: { status: true, name: 'Sleep', value: 4 },
        leaves: { status: true, name: 'Leaves', value: 6 },
        busTracking: { status: false, name: 'Bus Tracking', value: 7 },
        cctv: { status: false, name: 'CCTV', value: 8 },
        fees: { status: false, name: 'Fees', value: 9 },
    });
    const [additionalInfo, setAdditionalInfo] = useState({
        inviteParents: {
            status: true,
            name: 'Automatically Invite Parents as soon as student profile created',
            value: 1
        },
        // addParentChat: {
        //     status: true,
        //     name: 'Add Parent in chat as soon as student profile created',
        //     value: 2
        // },
        // createStudentID: {
        //     status: true,
        //     name: 'Automatically create Student ID as soon as profile created',
        //     value: 3
        // },
    });


    const handleFeatureChange = (feature) => {
        setFeatures({
            ...features,
            [feature]: {
                ...features[feature],
                status: !features[feature].status,
            },
        });
    };
    const handleAdditionalInfoChange = (info) => {
        setAdditionalInfo({
            ...additionalInfo,
            [info]: {
                ...additionalInfo[info],
                status: !additionalInfo[info].status,
            },
        });
    };

    const [programeNameError, setProgramNameError] = useState(null);
    const [programeTeacherError, setProgramTeacherError] = useState(null);
    const [timeError, setTimeError] = useState(null);
    const validateProgramForm = () => {
        let valid = true;
        if (newProgramName === "") {
            setProgramNameError("Please enter program name");
            valid = false;
        } else {
            setProgramNameError(null);
        }
        if (programTeacher === "") {
            setProgramTeacherError("Please select program teacher");
            valid = false;
        } else {
            setProgramTeacherError(null);
        }
        // if (programStartTime === "" || programEndTime === "") {
        //   setTimeError("Please select start and end time");
        //   valid = false;
        // } else {
        //   setTimeError(null);
        // }
        return valid;
    };

    const handleCreateProgram = () => {

        const selectedFeatureValues = Object.keys(features).filter((key) => features[key].status).map((key) => features[key].value);
        const selectedAdditionalInfoValues = Object.keys(additionalInfo).filter((key) => additionalInfo[key].status).map((key) => additionalInfo[key].value);

        console.log({
            school_id: localStorage.getItem("schoolId"),
            program_name: newProgramName,
            teacher_id: programTeacher,
            from_time_id: programStartTime,
            to_time_id: programEndTime,
            features: selectedFeatureValues.join(","),
            additional_info: selectedAdditionalInfoValues.join(","),
            status: 1,
            created_by: localStorage.getItem("loginUserId")
        });
        if (validateProgramForm()) {
            try {
                axios({
                    method: "post",
                    url: baseUrl + "/api/programs_add_edit",
                    data: {
                        school_id: localStorage.getItem("schoolId"),
                        program_name: newProgramName,
                        teacher_id: programTeacher,
                        from_time_id: programStartTime,
                        to_time_id: programEndTime,
                        features: selectedFeatureValues.join(","),
                        additional_info: selectedAdditionalInfoValues.join(","),
                        status: 1,
                        created_by: localStorage.getItem("loginUserId")
                    },
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": localStorage.getItem('access_token')
                    },
                }).then((response) => {
                    console.log(response);
                    if (response.data.status === true) {
                        $('.overlay').removeClass('visible');
                        $('.popup-program-container').removeClass('visible');
                        setNewProgramPopupVisible(false);
                        fetchProgramsData();
                        setSnackbarMessage(response.data.message);
                        setMessageType('success');
                        handleSnackbarOpen();
                    } else {
                        setSnackbarMessage(response.data.message);
                        setMessageType('error');
                        handleSnackbarOpen();
                    }
                });
            } catch (error) {
                console.log(error)
            }
        }

    };


    // -------------27-06-----------

    const [progress, setProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [importstatus, setimportstatus] = useState(false);

    const importStudents1 = () => {
        setimportstatus(true);
    };

    const handleImportStatus = (status) => {
        if (status == true) {
            setimportstatus(false);
        }
    };

    // ----- 04-07  active date----------
    const inputDateObj = moment("Thu Jul 04 2024", 'ddd MMM DD YYYY').toDate();
    const currentDateObj = new Date();
    const activeFormatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const formattedCurrentDate = activeFormatDate(currentDateObj);


    // -------------------------06-07------------------------------------
    // ------------------------add new staff----------------   

    const [user, setUser] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        dob: "",
        joining_date: "",
        address: "",
        role_id: "",
        profile_image: null
    });

    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [selectedPrograms, setSelectedPrograms] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setDropdownOpen((prev) => !prev);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };
    const handleProgramsChange = (programId) => {
        setSelectedPrograms((prevSelected) =>
            prevSelected.includes(programId)
                ? prevSelected.filter((id) => id !== programId)
                : [...prevSelected, programId]
        );
    };

    const fetchRoles = async () => {
        try {
            const response = await axios.post(
                baseUrl + "/api/get_role",
                {},
                {
                    headers: {
                        "Authorization": localStorage.getItem('access_token')
                    }
                }
            );
            setRoles(response.data.data);
        } catch (error) {
            console.error("Error fetching roles:", error);
            setError("Failed to fetch roles");
        }
    };

    const fetchPrograms = async () => {
        try {
            const response = await axios.post(
                baseUrl + "/api/get_programs",
                {
                    school_id: localStorage.getItem("schoolId"),
                },
                {
                    headers: {
                        "Authorization": localStorage.getItem('access_token')
                    }
                }
            );
            setPrograms(response.data.data);
        } catch (error) {
            console.error("Error fetching programs:", error);
        }
    };

    useEffect(() => {
        if (dropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownOpen]);

    const convertDBDateFormat = (dateString) => {
        const [day, month, year] = dateString.split('-');
        return `${year}-${month}-${day}`;
    };

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };
    const dateFormatList = 'DD-MM-YYYY';

    const ondobChange = (date, dateString) => {
        setUser({ ...user, dob: dateString });
        console.log(date, dateString);
    };

    const ondojChange = (date, dateString) => {
        setUser({ ...user, joining_date: dateString });
        console.log(date, dateString);
    }
    const selectedRoles = roles.find((data) => data.id === user.role_id);

    const firstNameInputRef = useRef(null);
    const emailInputRef = useRef(null);
    const pnoneInputRef = useRef(null);
    const dobInputRef = useRef(null);
    const joingInputRef = useRef(null);
    const adressInputRef = useRef(null);
    const roleInputRef = useRef(null);
    const programsInputRef = useRef(null);
    const submitButtonRef = useRef(null);
    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === "Enter") {
            event.preventDefault();
            console.log("Next input ref:", nextInputRef);
            if (nextInputRef && nextInputRef.current) {
                nextInputRef.current.focus();
            } else {
                console.log("Next input ref is null");
            }
        }
    };

    const [validationErrors, setValidationErrors] = useState({
        first_name: "",
        email: "",
        phone: "",
        dob: "",
        joining_date: "",
        address: "",
        role_id: "",
        programs: "",
        permissions_id: ""
    });
    const validateForm = () => {
        let errors = {};
        if (user.first_name === "") {
            errors.first_name = "Staff name cannot be empty";
        }
        if (user.phone.trim() === "") {
            errors.phone = "Staff phone number cannot be empty";
        } else if (
            !validator.isMobilePhone(user.phone.trim()) ||
            user.phone.trim().length !== 10
        ) {
            errors.phone = "Please enter valid mobile number";
        }
        if (user.address === "") {
            errors.address = "Staff address cannot be empty";
        }
        if (user.role_id === "" || user.role_id === null) {
            errors.role_id = "Staff role cannot be empty";
        }
        if (selectedPrograms.length === 0) {
            errors.programs = "Staff Programs cannot be empty";
        }
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const [searchQuery, setSearchQuery] = useState('');
    const filteredPrograms = programs.filter((program) =>
        program.program_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const initialBorderColor = {
        role: ""
    };

    const [borderColor, setBorderColor] = useState(initialBorderColor);

    const handleFocus = (borderColor) => {
        if (borderColor === "role") {
            setBorderColor({ ...borderColor, role: "#4D6EE3" });
        }
    };

    const handleBlur = () => {
        setBorderColor(initialBorderColor);
    };

    const onSubmit = (ev) => {
        if (validateForm()) {
            const postData = {
                school_id: localStorage.getItem("schoolId"),
                job_title: "",
                first_name: user.first_name,
                last_name: "",
                gender: "",
                email: user.email,
                phone: user.phone,
                dob: user.dob !== "" ? convertDBDateFormat(user.dob) : "",
                joining_date: user.joining_date !== "" ? convertDBDateFormat(user.joining_date) : "",
                // dob: convertDBDateFormat(user.dob),
                // joining_date: convertDBDateFormat(user.joining_date),
                blood_group: "",
                address: user.address,
                assign_classes: selectedPrograms.join(","),
                role_id: user.role_id,
                permissions_id: selectedPermissions.join(","),
                status: 1,
                created_by: localStorage.getItem('loginUserId'),
                updated_by: localStorage.getItem('loginUserId'),
                created_on: "",
                updated_on: "",
                profile_image: user.profile_image,
            };
            setError(null);
            axios({
                method: "post",
                url: baseUrl + "/api/staff_add",
                data: postData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                if (response["data"]["status"] === true) {
                    setSnackbarMessage(response.data.message);
                    setMessageType('success');
                    handleSnackbarOpen();
                    $(".overlay").removeClass("visible");
                    $(".popup-modal-container").removeClass("visible");
                    setUser({
                        first_name: "",
                        email: "",
                        phone: "",
                        dob: "",
                        joining_date: "",
                        address: "",
                        role_id: "",
                    });
                    fetchAttendanceData();
                    setSelectedPermissions([]);
                    setSelectedPrograms([]);
                    console.log(response);
                } else {
                    setSnackbarMessage(response.data.message);
                    setMessageType('error');
                    handleSnackbarOpen();
                }
            });
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside1);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside1);
        };
    }, []);

    const handleClickOutside1 = (event) => {
        if (roleInputRef.current && !roleInputRef.current.contains(event.target)) {
            setBorderColor(initialBorderColor);
        }
    };

    // -------16-07------------------
    // const [staffDataLength, setStaffDataLength] = useState(23);
    const [staffDataLength, setStaffDataLength] = useState(() => Math.floor(window.innerWidth / 80));
    // -------------24-07-------------
    const [isAttendanceVisible, setAttendanceVisible] = useState(false);
    const [attendanceStatus, setAttendanceStatus] = useState(null);
    const handleAttendanceClick = (attendanceStatus) => {
        setAttendanceStatus(attendanceStatus)
        setAttendanceVisible(true);
    };

    const handleAttendanceClose = () => {
        setAttendanceStatus(null)
        setAttendanceVisible(false);
    };

    // 25-07
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setUser({ ...user, profile_image: file });
        setSelectedImage(file);
    };


    useEffect(() => {
        $(document).on("click", "#newStaffCreate", function () {
            setValidationErrors({});
            setUser({
                first_name: "",
                email: "",
                phone: "",
                dob: "",
                joining_date: "",
                address: "",
                role_id: "",
                profile_image: null
            });
            setSelectedPermissions([]);
            setSelectedPrograms([]);
            setSelectedImage(null);
            $(".overlay").addClass("visible");
            $(".popup-modal-container").addClass("visible");
        });
        $(document).on("click", ".popup-student-details .close", function () {
            $(".popup-student-details").removeClass("visible");
        });
        $(document).on("click", ".sp-btn-cancel", function () {
            $(".overlay").removeClass("visible");
            $(".popup-modal-container").removeClass("visible");
        });
        // $(document).on("click", "#newStaffCreate", function () {
        fetchProgramsData();
        fetchTeachersData();
        fetchProgramTimeData();
        fetchAttendanceData();
        fetchRoles();
        fetchPrograms();
        //     $(".overlay").addClass("visible");
        //     $(".popup-modal-container").addClass("visible");
        // });
        // $(document).on("click", ".popup-student-details .close", function () {
        //     $(".popup-student-details").removeClass("visible");
        // });
        // $(document).on("click", ".sp-btn-cancel", function () {
        //     setError(null);
        //     $(".overlay").removeClass("visible");
        //     $(".popup-modal-container").removeClass("visible");
        // });


    }, []);

    return (
        <section className="staff_atten_cus">
            <Snackbar className="snack_total"
                anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
                open={snackbarState.open}
                onClose={handleSnackbarClose}
                message={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemIcon>
                            <img src={messageType === 'success' ? SuccessIcon : ErrorIcon} alt="Success" style={{ width: '24px', height: '24px' }} />
                        </ListItemIcon>
                        <div className="success_cuc">
                            <p> {snackbarMessage} </p>
                        </div>
                    </div>
                }
                ContentProps={{
                    sx: {
                        border: "1px solid",
                        opacity: "1",
                        background: messageType === 'success' ? "#40BE61" : "#FD4954",
                        borderColor: messageType === 'success' ? "#40BE61" : "#FD4954",
                        width: '415px'
                    }
                }}
                autoHideDuration={3000}
            />
            <div className="outer-container">
                <div className="overlay" />
                <div id="left-panel" className="sp-left-panel">
                <CommonSidebarHeader/>
                    <div className="sp-middle-band">
                        <div className="sp-nav y-auto">
                            <div className="name_tag_side">
                                <h4 className="sp-title">Quick Actions</h4>
                                <ul>
                                    <li onClick={() => { navigate('/dashboard'); }}>
                                        <span>
                                            <i className="icon-Dashboard-Outline" />
                                            <p>Dashboard</p>
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/activity'); }}>
                                        <span>
                                            <i className="icon-Activity-Outline" />
                                            <p>Activity</p>
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/calendar'); }}>
                                        <span>
                                            <i className="icon-Timetable-Outline" />
                                            <p>Calendar</p>
                                        </span>
                                    </li>
                                    <li className="selected">
                                        <span>
                                            <i className="icon-Staff-Outline" />
                                            <p>Staff</p>
                                        </span>
                                    </li>
                                    {/* <li>
                    <span>
                      <i className="icon-Message-Outline" />
                      Messages
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Fees-Outline" />
                      Fees Management
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Enquiries-Outline" />
                      Enquiries
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Invites-Outline" />
                      Invites
                    </span>
                  </li> */}
                                </ul>
                                <h4 className="sp-title flexc justifySp" onClick={() => handleOpenNewProgramPopupVisible()}>
                                    Programs{" "}
                                    <span id="add-programs" className="sp-plus">
                                        +
                                    </span>
                                </h4>
                                <ul>
                                    {programsLoading && programsData && programsData.length > 0 ?
                                        programsData.map((data) => {
                                            return (
                                                <li onClick={() => {
                                                    localStorage.setItem("programId", data.id);
                                                    localStorage.setItem("programName", data.program_name);
                                                    navigate('/student_list');
                                                }}>
                                                    <span>
                                                        <i className="icon-Program-Outline-1" />
                                                        <p>{data.program_name}</p>
                                                    </span>
                                                </li>
                                            );
                                        }) :
                                        <div className="data-loader extracss_load">
                                            {/* <Loader
                                                type="spinner-cub"
                                                bgColor="#4D6EE3"
                                                color="#6B6F8C"
                                                title="Loading..."
                                                size={35}
                                            /> */}
                                            <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                                            <p>Loading...</p>
                                        </div>
                                    }
                                </ul>
                            </div>
                        </div>
                        <Sidebar />
                    </div>
                </div>
                <div className="sp-right-panel">
                    <div className="sp-top-bar flexc justifySp exta_pad_10">
                        <div className="ft-wt600 flexc pad_ic_ri">
                            <span className="selected-icon">
                                <i className="icon-Staff-Outline" />
                            </span>
                            <p className="program_tit">Staff</p>
                            {/* <i className="icon-More-Icon" /> */}
                        </div>
                        <div className="flexc">
                            <button className="sp-view-btn ft-wt500 flexc"
                                onClick={() => {
                                    importStudents1();
                                }}>
                                <i className="icon-Import-Outline" />
                                Import
                            </button>
                            <button className="createN ft-wt500 mr-lf10 space_icon_pad" id="newStaffCreate">
                                <i class="fa-solid fa-plus"></i> New Staff
                            </button>
                        </div>
                    </div>
                    <div className="sp-header">
                        <nav className="flexc">
                            {/* <div class="sp-list-view flexc hidden"><i class="icon-Attendance-Outline"></i>Student List</div> */}
                            <div className="sp-list-view flexc" onClick={() => { navigate('/staff_list'); }}>
                                <i className="icon-Students-Outline" />
                                Staff List
                            </div>
                            <div className="sp-list-view flexc selected">
                                <i className="icon-Attendance-Outline" />
                                Attendance
                            </div>
                            {/* <div className="sp-list-view flexc">
                                <i className="icon-Calendar-Outline" />
                                Timetable
                            </div> */}
                        </nav>
                        {importstatus === false &&
                            <div className="sp-menu-bar flexc justifySp">
                                <div className="sp-menu icon_div_space pad_30_ex">
                                    {/* <Snackbar
                                    open={open}
                                    onClose={handleClose}
                                    autoHideDuration={3000}
                                    message={error}
                                /> */}
                                    <div className="flexc">
                                        {
                                            selectedStaff.length === 0 ?
                                                <div className="btn-search-bar flexc">
                                                    <i className="icon-Search" />
                                                    {/* 31-07-2024 */}
                                                    <input
                                                        type="text"
                                                        id="simple-search"
                                                        placeholder="Search Staff"
                                                        value={filterValue}
                                                        onChange={handleInputChange}
                                                        className="search-input"
                                                    />
                                                    {/* 31-07-2024 */}
                                                    <i onClick={clearInput} className={`icon-Close ${filterValue ? '' : 'hide-visibility'}`} />
                                                    {/* <i className="icon-Search" />
                                                    <input
                                                        type="search"
                                                        placeholder="Search Staff"
                                                        value={filterValue}
                                                        onChange={handleInputChange}
                                                    /> */}
                                                </div> :
                                                <>
                                                    <button className="sp_mbtn flexc excla_div glo-showdow" onClick={() => { handleAttendanceClick(1); }}>
                                                        <span className="flexc ft-13">
                                                            <img src={tik} alt="" />
                                                            {/* <span className="sp-lpdr  icon-Tick color_cha_whi" /> */}
                                                        </span>
                                                        <span>Present</span>
                                                    </button>
                                                    <button className="sp_mbtn flexc excla_div glo-showdow" onClick={() => { handleAttendanceClick(2); }}>
                                                        <img src={leave} alt="" />
                                                        {/* <span className="notapp-ele color_cha_whi   " /> */}
                                                        <span>Absent</span>
                                                    </button>
                                                    <button className="sp_mbtn flexc excla_div glo-showdow" onClick={() => { handleAttendanceClick(5); }}>
                                                        <img src={cross} alt="" />
                                                        {/* <span className="cross-ele color_cha_whi leave_ic" /> */}
                                                        <span>Leave</span>
                                                    </button>
                                                </>
                                        }
                                    </div>
                                    {/* <div className="flexc">
                                    <button className="sp_mbtn flexc" onClick={() => { addAttendance(1); }}>
                                        <span className="flexc ft-13">
                                            <span className="sp-lpdr  icon-Tick color_cha_whi" />
                                        </span>
                                        <span>Present</span>
                                    </button>
                                    <button className="sp_mbtn flexc excla_div" onClick={() => { addAttendance(2); }}>
                                        <span className="notapp-ele color_cha_whi   " />
                                        <span>Absent</span>
                                    </button>
                                    <button className="sp_mbtn flexc" onClick={() => { addAttendance(5); }}>
                                        <span className="cross-ele color_cha_whi leave_ic" />
                                        <span>Leave</span>
                                    </button>
                                    <div className="btn-search-bar flexc">
                                        <i className="icon-Search" />
                                        <input
                                            type="search"
                                            placeholder="Search Staff"
                                            value={filterValue}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div> */}
                                </div>
                            </div>
                        }
                    </div>

                    {importstatus ?
                        <>

                            <ImportComponent
                                importStudent={"staff"}
                                handleImportStatus={handleImportStatus}
                                setProgress={setProgress} setIsLoading={setIsLoading}
                            />
                        </>
                        :
                        <div className="he_fix_cus ">
                            {
                                (foodLoading && foodData) ? (
                                    foodData.status === true ? (
                                        <div className="std-attence">
                                            <div className="attence-top overxy-auto">
                                                <div className="students-add-list">
                                                    <ul className="sp-head sp_sticky defult-date-header">
                                                        <li className="table_input_sty">
                                                            <input
                                                                type="checkbox"
                                                                id="check-all"
                                                                onChange={handleSelectAll}
                                                                checked={selectedStaff.length === foodHeaderdata.length}
                                                            />
                                                            {foodData.staff}
                                                        </li>
                                                        {dataHeader && dataHeader.length > 0 ?
                                                            dataHeader.map((data, index) => (
                                                                <li key={index} className={`${activeFormatDate(moment(data.date_data, 'ddd MMM DD YYYY').toDate()) === formattedCurrentDate && 'active-date-header'}`}>{data.date_data}</li>
                                                            )) : <div></div>}
                                                    </ul>
                                                    {foodHeaderdata.map((student, studentIndex) => (
                                                        <React.Fragment key={studentIndex}>
                                                            <ul>
                                                                <li className="table_input_sty truncate">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="sub_select"
                                                                        onChange={() => handleSelectStudent(student.id)}
                                                                        checked={selectedStaff.includes(student.id)}
                                                                    />
                                                                    <span className="ft-500 flexc img_wid_dis">
                                                                        {
                                                                            student?.profile_image !== null ?
                                                                                <img
                                                                                    src={baseUrl + "/api/" + student?.profile_image}
                                                                                    width={40}
                                                                                    alt={student?.first_name}
                                                                                    style={{ borderRadius: "5px" }}
                                                                                /> : <LogoComponent name={student?.name} index={studentIndex} bgColor={student?.staff_color}/>
                                                                        }
                                                                        {/* <img
                                                                            src={
                                                                                student?.profile_image !== null
                                                                                    ? baseUrl + "/api/" + student?.profile_image
                                                                                    : avatar1
                                                                            }
                                                                            width={40}
                                                                            alt={student?.first_name}
                                                                            style={{ borderRadius: "5px" }}
                                                                        /> */}
                                                                        {/* <span className="mr-lf10 ft-14 truncate name_bold">{student.name}</span> */}
                                                                        <span
                                                                            className="mr-lf10 fs-13 truncate name_bold text-cap"
                                                                            data-tooltip-id={`name-tooltip-${student?.id}`}
                                                                            data-tooltip-content={student.name}
                                                                            data-tooltip-place="bottom"
                                                                            style={{ cursor: student.name.length > staffDataLength && "pointer" }}
                                                                        >
                                                                            {
                                                                                (student.name && student.name.length <= staffDataLength) ? student.name : `${student.name.slice(0, staffDataLength)}...`
                                                                            }
                                                                        </span>
                                                                    </span>
                                                                </li>
                                                                {
                                                                    student.name.length > staffDataLength &&
                                                                    <Tooltip className="custom-tooltip" id={`name-tooltip-${student.id}`} />
                                                                }
                                                                {student.attendance.map((attendanceData, attendanceIndex) => (
                                                                    <React.Fragment key={attendanceIndex}>
                                                                        <li
                                                                            className={`${activeFormatDate(moment(attendanceData.date, 'ddd MMM DD YYYY').toDate()) === formattedCurrentDate ? 'active-date-data' :
                                                                                activeFormatDate(moment(attendanceData.date, 'ddd MMM DD YYYY').toDate()) > formattedCurrentDate && 'next-date-data'} ${(studentIndex === (foodHeaderdata.length - 1) && activeFormatDate(moment(attendanceData.date, 'ddd MMM DD YYYY').toDate()) === formattedCurrentDate) ? 'active-last-date-data' : ''}`}
                                                                        >
                                                                            {attendanceData.attendance_status === 1 ? (
                                                                                <span className="flexc ft-13">
                                                                                    <img src={tik1} alt="" style={{ width: '16px' }} />
                                                                                    {/* <span className="sp-lpdr icon-Tick " /> */}
                                                                                </span>
                                                                            ) : attendanceData.attendance_status === 2 ? (
                                                                                // <span className="notapp-ele" />
                                                                                <img src={leave1} alt="" style={{ width: '16px' }} />
                                                                            ) : attendanceData.attendance_status > 2 ? (
                                                                                // <span className="cross-ele" />
                                                                                <img src={cross1} alt="" style={{ width: '16px' }} />
                                                                            ) : null}
                                                                        </li>
                                                                    </React.Fragment>
                                                                ))}
                                                            </ul>
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        foodData.status === false && (
                                            <div className="no_bor_data">
                                                <center><div className="sp-title flex-3">{foodData.message}</div></center>
                                            </div>
                                        )
                                    )
                                ) : (
                                    <div className="data-loader extracss_load">
                                        {/* <Loader
                                        type="spinner-cub"
                                        bgColor="#4D6EE3"
                                        color="#6B6F8C"
                                        title="Loading..."
                                        size={35}
                                        /> */}
                                        <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                                        <p>Loading...</p>
                                    </div>
                                )
                            }
                        </div>
                    }

                </div>
                {/* / Create NewStaff / */}
                <div className="popup-modal-container">
                    <div className="popup-wrap">
                        <h2 className="ft-wt700">Create New Staff</h2>
                        <div className="sp-pop-header">
                            <h3 className="ft-wt600">Staff Details</h3>
                            <div className="form-section-default flexc justifySp fwrap">
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <h3 className="ft-wt600">Roles &amp; Permissions</h3>
                                    <div className="roll_mar_top">
                                        <label>Role<span className="col_red">*</span></label>
                                        <div
                                            className="field-value-holder dropbutton"
                                            ref={roleInputRef}
                                            style={{
                                                width: "100%",
                                                borderColor: validationErrors.role_id ? "red" : borderColor.role,
                                            }}
                                            onKeyDown={(event) =>
                                                handleKeyPress(event, submitButtonRef)
                                            }
                                            onFocus={() => handleFocus("role")}
                                            onBlur={handleBlur}
                                            tabIndex="0"
                                        >
                                            <Dropdown
                                                label={
                                                    <span
                                                        style={{
                                                            fontSize: "0.8825em",
                                                            color: selectedRoles ? '#1B252F' : '#292929',
                                                            justifyContent: "flex-start"
                                                        }}
                                                    >
                                                        {selectedRoles ? selectedRoles.role_name : "Select Role"}
                                                    </span>
                                                }
                                                style={{ width: "100%" }}
                                            >
                                                {roles.map((item) => (
                                                    <Dropdown.Item
                                                        key={item.id}
                                                        className={`${selectedRoles && selectedRoles.id === item.id ? "selected-item dd_select" : ""
                                                            }`}
                                                        icon={selectedRoles && selectedRoles.id === item.id ? HiCheck : null}
                                                        onClick={() => setUser({ ...user, role_id: item.id })}
                                                    >
                                                        {item.role_name}
                                                    </Dropdown.Item>
                                                ))}

                                            </Dropdown>
                                        </div>
                                        {validationErrors.role_id && (
                                            <p className="error pos_abo_stu">{validationErrors.role_id}</p>
                                        )}
                                    </div>

                                </div>
                                <div className=" full-field ">
                                    {selectedRoles && (
                                        <div className="field-label mar_top_roll full-field error_mes_cus">
                                            {selectedRoles.role_name === "Administrator" && (
                                                <div>
                                                    <label>Permissions</label>
                                                    <div className="label-permissions mar_ful_permison">
                                                        <button className="sp-selected-bt ft-wt600 ft-13 ">Manage Student & Staff</button>
                                                        <button className="sp-selected-bt ft-wt600 ft-13 ">Full Access to all features</button>
                                                        <button className="sp-selected-bt ft-wt600 ft-13 ">Manage Program</button>
                                                        <button className="sp-selected-bt ft-wt600 ft-13 ">Manage Fees</button>
                                                        <button className="sp-selected-bt ft-wt600 ft-13 ">Admin Control</button>
                                                    </div>
                                                </div>
                                            )}
                                            {selectedRoles.role_name === "Teacher" && (
                                                <div>
                                                    <label>Permissions</label>
                                                    <div className="label-permissions mar_ful_permison">
                                                        <button className="sp-selected-bt ft-wt600 ft-13 ">Manage Student</button>
                                                        <button className="sp-selected-bt ft-wt600 ft-13 ">Full Access to all features</button>
                                                    </div>
                                                </div>
                                            )}
                                            {selectedRoles.role_name === "Security" && (
                                                <div>
                                                    <label>Permissions</label>
                                                    <div className="label-permissions mar_ful_permison">
                                                        <button className="sp-selected-bt ft-wt600 ft-13 ">Scan QR</button>
                                                        <button className="sp-selected-bt ft-wt600 ft-13 ">Add Visitor</button>
                                                    </div>
                                                </div>
                                            )}
                                            {selectedRoles.role_name === "Driver" && (
                                                <div>
                                                    <label>Permissions</label>
                                                    <div className="label-permissions mar_ful_permison">
                                                        <button className="sp-selected-bt ft-wt600 ft-13 ">Pickup Detail</button>
                                                    </div>
                                                </div>
                                            )}
                                            {selectedRoles.role_name === "Helper" && (
                                                <div>
                                                    <label>Helper have below permissions</label>
                                                    <div className="label-permissions mar_ful_permison">
                                                        <button className="sp-selected-bt ft-wt600 ft-13 ">Scan QR</button>
                                                        <button className="sp-selected-bt ft-wt600 ft-13">Pickup Details</button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Staff Name<span className="col_red">*</span><span></span></label>
                                    <div className="">
                                        <input
                                            ref={firstNameInputRef}
                                            type="text"
                                            value={user.first_name}
                                            onChange={(ev) =>
                                                setUser({ ...user, first_name: ev.target.value })
                                            }
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, programsInputRef)
                                            }
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.first_name ? 'error' : ''}`}
                                            placeholder="Enter full name"
                                        />
                                    </div>
                                    {validationErrors.first_name && (
                                        <p className="error pos_abo_stu">{validationErrors.first_name}</p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus cus_pro but_foc_cus pos_stu_cus">
                                    <label>Programs<span className="col_red">*</span></label>
                                    <div className={`field-value-holder`} style={{ position: 'relative', borderColor: validationErrors.programs ? "red" : "#d9dce6" }}>
                                        <button
                                            id="dropdownSearchButton"
                                            ref={programsInputRef}
                                            className={`${validationErrors.programs ? 'error' : ''}`}
                                            onClick={toggleDropdown}
                                            onKeyPress={(event) => handleKeyPress(event, pnoneInputRef)}
                                            type="button"
                                            style={{
                                                position: "relative",
                                                width: '100%',
                                                textAlign: "start",
                                                height: "40px",
                                                fontSize: "13px",
                                                color: "#292929",
                                                padding: selectedPrograms.length !== 0 ? "0px" : "7px 0.75rem"
                                            }}
                                        >
                                            <div className="dis_pro_flex">
                                                <div className="pro_wid_style">
                                                    {selectedPrograms.length !== 0
                                                        ? selectedPrograms.slice(0, 3).map((selectedId) => {
                                                            const program = programs.find((p) => p.id.toString() === selectedId);
                                                            if (program) {
                                                                return (
                                                                    <button
                                                                        key={program.id}
                                                                        className="sp-selected-bt ft-wt600 ft-13 mr-2 truncate"
                                                                        style={{ margin: "5px" }}
                                                                        onClick={(e) => e.stopPropagation()}
                                                                    >
                                                                        {program.program_name}
                                                                    </button>
                                                                );
                                                            }
                                                            return null;
                                                        })
                                                        : "Selected Programs"
                                                    }
                                                </div>
                                                {
                                                    selectedPrograms.length > 3 && <div><p className="pro_more_text">{`+${selectedPrograms.length - 3} more`}</p></div>
                                                }
                                            </div>
                                            <div className="drop_ic_cus">
                                                <i class="fa-solid fa-chevron-down"></i>
                                            </div>
                                        </button>
                                        {dropdownOpen && (
                                            <div id="dropdownSearch"
                                                ref={dropdownRef}
                                                style={{ position: 'absolute', zIndex: '999', backgroundColor: 'white', width: '100%' }}>
                                                <ul className=" px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200 back_white_cus" aria-labelledby="dropdownSearchButton">
                                                    <div className="field-value-holder custamize_color_place" style={{ position: 'relative', width: '100%', marginTop: "0px" }}>
                                                        <i
                                                            className="icon-Search"
                                                            style={{
                                                                position: 'absolute',
                                                                left: '10px',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                pointerEvents: 'none',
                                                            }}
                                                        ></i>
                                                        <input
                                                            style={{ width: '100%', height: '40px', paddingLeft: '35px' }}
                                                            type="search"
                                                            id="input-group-search"
                                                            value={searchQuery}
                                                            onChange={(e) => setSearchQuery(e.target.value)}
                                                            placeholder="Search"
                                                        />
                                                    </div>
                                                    {filteredPrograms.map((item, index) => (
                                                        <li key={item.id}>
                                                            <div className={selectedPrograms.includes(item.id.toString()) ? "program_checked flex items-center pad_15 rounded hover:bg-gray-100 dark:hover:bg-gray-600" : "flex items-center pad_15 rounded hover:bg-gray-100 dark:hover:bg-gray-600"}>
                                                                <input
                                                                    id={`checkbox-item-${item.id}`}
                                                                    type="checkbox"
                                                                    checked={selectedPrograms.includes(item.id.toString())}
                                                                    onChange={(e) => handleProgramsChange(item.id.toString(), e.target.checked)}
                                                                //className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                                                />
                                                                <label htmlFor={`checkbox-item-${item.id}`} className="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
                                                                    {item.program_name}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                    {validationErrors.programs && (
                                        <p className="error pos_abo_stu">{validationErrors.programs}</p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Phone Number<span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            ref={pnoneInputRef}
                                            type="text"
                                            value={user.phone}
                                            onChange={(ev) =>
                                                setUser({ ...user, phone: ev.target.value })
                                            }
                                            onKeyPress={(event) => handleKeyPress(event, emailInputRef)}
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.phone ? 'error' : ''}`}
                                            placeholder="Phone Number"
                                        />
                                    </div>
                                    {validationErrors.phone && (
                                        <p className="error pos_abo_stu">{validationErrors.phone}</p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Email</label>
                                    <div className="">
                                        <input
                                            ref={emailInputRef}
                                            type="text"
                                            value={user.email}
                                            onChange={(ev) =>
                                                setUser({ ...user, email: ev.target.value })
                                            }
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, adressInputRef)
                                            }
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.email ? 'error' : ''}`}
                                            placeholder="Enter your email"
                                        />
                                    </div>
                                    {validationErrors.email && (
                                        <p className="error pos_abo_stu">{validationErrors.email}</p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus date_pic_img">
                                    <label>Date of Birth</label>
                                    <div className="field-value-holder ">
                                        <DatePicker
                                            // ref={dobInputRef}
                                            placeholder="DD/MM/YYYY"
                                            format={dateFormatList}
                                            onChange={ondobChange}
                                            value={user.dob ? dayjs(user.dob, dateFormatList) : null}
                                        ></DatePicker>
                                    </div>
                                    {validationErrors.dob && (
                                        <p className="error">{validationErrors.dob}</p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus date_pic_img pos_stu_cus">
                                    <label>Joining Date</label>
                                    <div className="field-value-holder">
                                        <DatePicker
                                            // ref={joingInputRef}
                                            placeholder="DD/MM/YYYY"
                                            format={dateFormatList}
                                            onChange={ondojChange}
                                            value={user.joining_date ? dayjs(user.joining_date, dateFormatList) : null}
                                        ></DatePicker>
                                    </div>
                                    {validationErrors.joining_date && (
                                        <p className="error pos_abo_stu">
                                            {validationErrors.joining_date}
                                        </p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus ">
                                    <label>Staff Photo</label>
                                    <div className="field-value-holder">
                                        <span className="sp-upload-btn flexc justifySp" style={{
                                            color: " #A9AFC3",
                                            fontWeight: "500",
                                            fontSize: "13px"
                                        }}>
                                            {selectedImage === null ? (
                                                // studentId === null ?"Upload":displayUser.profile_image
                                                "Upload"
                                            ) : (
                                                <p style={{
                                                    color: " #1B252F",
                                                    fontWeight: "600",
                                                    fontSize: "13px"
                                                }} className="truncate"> {selectedImage.name}</p>
                                            )}
                                            <button className="sp-file-browse">Browse</button>
                                        </span>
                                        <input
                                            className="input-placeholder1"
                                            style={{
                                                cursor: "pointer"
                                            }}
                                            // ref={imageInputRef}
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, joingInputRef)
                                            }
                                        />
                                    </div>
                                    {/* {validationErrors.profile_image && (
                    <p className="error">
                      {validationErrors.profile_image}
                    </p>
                  )} */}
                                </div>
                                <div className="field-label full-field error_mes_cus pos_stu_cus">
                                    <label>Address<span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            ref={adressInputRef}
                                            type="text"
                                            value={user.address}
                                            onChange={(ev) =>
                                                setUser({ ...user, address: ev.target.value })
                                            }
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, roleInputRef)
                                            }
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.address ? 'error' : ''}`}
                                            placeholder="Address"
                                        // defaultValue={""}
                                        />
                                    </div>
                                    {validationErrors.address && (
                                        <p className="error pos_abo_stu">{validationErrors.address}</p>
                                    )}
                                </div>
                            </div>
                            {/* <h3 className="ft-wt600 mt-30">Roles &amp; Permissions</h3>
              <div className="field-label half-field error_mes_cus pos_stu_cus">
                <label>Role<span className="col_red">*</span></label>
                <div
                  className="field-value-holder dropbutton"
                  ref={roleInputRef}
                  style={{
                    width: "100%",
                    borderColor: validationErrors.role_id ? "red" : borderColor.role,
                  }}
                  onKeyDown={(event) =>
                    handleKeyPress(event, submitButtonRef)
                  }
                  onFocus={() => handleFocus("role")}
                  onBlur={handleBlur}
                  tabIndex="0"
                >
                  <Dropdown
                    label={
                      <span
                        style={{
                          fontSize: "0.8825em",
                          color: selectedRoles ? '#1B252F' : '#292929',
                          justifyContent: "flex-start"
                        }}
                      >
                        {selectedRoles ? selectedRoles.role_name : "Select Role"}
                      </span>
                    }
                    style={{ width: "100%" }}
                  >
                    {roles.map((item) => (
                      <Dropdown.Item
                        key={item.id}
                        className={`${selectedRoles && selectedRoles.id === item.id ? "selected-item dd_select" : ""
                          }`}
                        icon={selectedRoles && selectedRoles.id === item.id ? HiCheck : null}
                        onClick={() => setUser({ ...user, role_id: item.id })}
                      >
                        {item.role_name}
                      </Dropdown.Item>
                    ))}

                  </Dropdown>
                </div>
                {validationErrors.role_id && (
                  <p className="error pos_abo_stu">{validationErrors.role_id}</p>
                )}
              </div>
              {selectedRoles && (
                <div className="field-label full-field error_mes_cus">
                  {selectedRoles.role_name === "Administrator" && (
                    <div>
                      <label>Admin have below permissions</label>
                      <div className="label-permissions mt-3">
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Manage Student & Staff</button>
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Full Access to all features</button>
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Manage Program</button>
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Manage Fees</button>
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Admin Control</button>
                      </div>
                    </div>
                  )}
                  {selectedRoles.role_name === "Teacher" && (
                    <div>
                      <label>Teacher have below permissions</label>
                      <div className="label-permissions mt-3">
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Manage Student</button>
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Full Access to all features</button>
                      </div>
                    </div>
                  )}
                  {selectedRoles.role_name === "Security" && (
                    <div>
                      <label>Security have below permissions</label>
                      <div className="label-permissions mt-3">
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Scan QR</button>
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Add Visitor</button>
                      </div>
                    </div>
                  )}
                  {selectedRoles.role_name === "Driver" && (
                    <div>
                      <label>Driver have below permissions</label>
                      <div className="label-permissions mt-3">
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Pickup Detail</button>
                      </div>
                    </div>
                  )}
                  {selectedRoles.role_name === "Helper" && (
                    <div>
                      <label>Helper have below permissions</label>
                      <div className="label-permissions mt-3">
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Scan QR</button>
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Pickup Details</button>
                      </div>
                    </div>
                  )}
                </div>
              )} */}
                        </div>
                    </div>
                    <div className="sp-bottom-sticky">
                        <button className="sp-btn-create" ref={submitButtonRef} onClick={onSubmit}>
                            Create
                        </button>
                        <button className="sp-btn-cancel">Cancel</button>
                    </div>
                </div>
                {/* Attendance Modal */}
                {
                    isAttendanceVisible && (
                        <div
                            id="recycle-popup"
                            tabIndex={-1}
                            className="back_overlay fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full  max-h-full res_tot_div"
                        >
                            <div className="relative w-full max-w-xs max-h-full">
                                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4">   <div className="p-4 md:p-5 text-center">
                                    <div className="round-btn rounded inline-flex alignC justify-center">
                                        <img src={attpopup} alt="" />
                                    </div>
                                    <p className="ft-wt600 ft-16 mb-4 mt-3">Mark as {attendanceStatus === 1 ? "Present" : attendanceStatus === 2 ? "Absent" : "Leave"}</p>
                                    <h3 className="mb-5 font-normal text-sm">
                                        Are you sure you want to update the staff attendance
                                    </h3>
                                    <div className="att-popup">
                                        <button
                                            onClick={handleAttendanceClose}
                                            type="button"
                                            className="att-cancel can_cus_rec border-full ms-3 py-2.5 px-5  text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={() => addAttendance()}
                                            type="button"
                                            className="save_button_cus sp-blue-color text-white ms-3 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 rounded-md ft-13 inline-flex items-center px-5 py-2.5 text-center save_cus_di"
                                        >
                                            Yes, Proceed
                                        </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                {/* Create New Program */}
                {
                    newProgramPopupVisible && (
                        <div className="popup-program-container">
                            <div className="popup-wrap">
                                <h2 className="ft-wt700">Create New Program</h2>
                                <div className="sp-pop-header ">
                                    <div className="form-section-default flexc justifySp fwrap">
                                        <div className="field-label full-field error_mes_cus  pos_stu_cus">
                                            <label>Program Name</label>
                                            <div className="">
                                                <input
                                                    className={`field-value-holder margin_top10p custamize_color_place  extra_bor_clr  sp-field-input ${programeNameError ? 'error' : ''}`}
                                                    // className="sp-field-input"
                                                    type="text"
                                                    placeholder="Enter Program name"
                                                    value={newProgramName}
                                                    onChange={(e) => setNewProgramName(e.target.value)}
                                                />
                                            </div>
                                            {programeNameError && <p className="error pos_abo_stu">{programeNameError}</p>}
                                        </div>
                                        <div className="field-label half-field error_mes_cus extra_pad_drop">
                                            <label>Program Teachers</label>
                                            <div className="field-value-holder margin_top10p dropbutton"
                                                style={{
                                                    width: "100%",
                                                    borderColor: programeTeacherError ? "red" : borderColor.newProgram,
                                                }}
                                            >
                                                <Dropdown


                                                    label={
                                                        <span
                                                            className={`${selectedTeachers ? 'selected-item' : ''}`}
                                                            style={{
                                                                fontSize: "13px",
                                                                justifyContent: "flex-start",
                                                                color: selectedTeachers ? '#1B252F' : '#292929'
                                                            }}
                                                        >
                                                            {selectedTeachers ? selectedTeachers.first_name : "Select Teacher"}</span>}
                                                    style={{ width: '100%' }}
                                                >

                                                    {teachersData.map(item => (
                                                        <Dropdown.Item
                                                            // className={selectedTeachers && selectedTeachers.id === item.id ? "dd_select" : null}
                                                            className={`${selectedTeachers && selectedTeachers.id === item.id ? "selected-item dd_select" : ""
                                                                }`}
                                                            icon={selectedTeachers && selectedTeachers.id === item.id ? HiCheck : null}
                                                            onClick={() => setTeacher(item.id)}
                                                            key={item.id}
                                                        >
                                                            {item.first_name}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown>

                                            </div>
                                            {programeTeacherError && <p className="error pos_abo_stu">{programeTeacherError}</p>}
                                        </div>
                                        <div className="field-label half-field error_mes_cus extra_date_drop">
                                            <label>Class Timing</label>
                                            <div className="flexc justifySp">
                                                <div className="half-field">
                                                    <div className="field-value-holder margin_top10p dropbutton cus_drop_siz" >
                                                        <Dropdown
                                                            label={


                                                                <span
                                                                    className={`${selectedStartTime ? 'selected-item' : ''}`}
                                                                    style={{
                                                                        fontSize: "12px",
                                                                        justifyContent: "flex-start",
                                                                        color: selectedStartTime ? '#1B252F' : '#292929'
                                                                    }}
                                                                >

                                                                    {/* <span style={{ color: 'black', fontSize: '12px' }}> */}



                                                                    <i class="fa-regular fa-clock" style={{ marginRight: '6PX' }}></i>
                                                                    {selectedStartTime ? selectedStartTime.time_name : "Start Time"}</span>}
                                                            style={{
                                                                width: "100%"
                                                            }}
                                                        >
                                                            {timeData.map(item => (
                                                                <Dropdown.Item

                                                                    //className={selectedStartTime && selectedStartTime.id === item.id ? "dd_select" : null}

                                                                    className={`${selectedStartTime && selectedStartTime.id === item.id ? "selected-item dd_select" : ""
                                                                        }`}
                                                                    icon={selectedStartTime && selectedStartTime.id === item.id ? HiCheck : null}
                                                                    onClick={() => setProgramStartTime(item.id)}
                                                                    key={item.id}
                                                                >
                                                                    {item.time_name}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <div className="half-field">
                                                    <div className="field-value-holder margin_top10p dropbutton cus_drop_siz" >
                                                        <Dropdown
                                                            label={


                                                                //  <span style={{ color: 'black', fontSize: '12px' }}>

                                                                <span
                                                                    className={`${selectedEndTime ? 'selected-item' : ''}`}
                                                                    style={{


                                                                        fontSize: "13px",
                                                                        fontWeight: "400",
                                                                        lineHeight: "18.2px",
                                                                        textAlign: "left",

                                                                        justifyContent: "flex-start",
                                                                        color: selectedEndTime ? '#1B252F' : '#292929'
                                                                    }}
                                                                >



                                                                    <i class="fa-regular fa-clock" style={{ marginRight: '6PX' }}>
                                                                    </i>{selectedEndTime ? selectedEndTime.time_name : "End Time"}</span>}
                                                            style={{
                                                                width: "100%"
                                                            }}
                                                        >
                                                            {timeData.map(item => (
                                                                <Dropdown.Item
                                                                    // className={selectedEndTime && selectedEndTime.id === item.id ? "dd_select" : null}
                                                                    className={`${selectedEndTime && selectedEndTime.id === item.id ? "selected-item dd_select" : ""
                                                                        }`}

                                                                    icon={selectedEndTime && selectedEndTime.id === item.id ? HiCheck : null}
                                                                    onClick={() => setProgramEndTime(item.id)}
                                                                    key={item.id}
                                                                >
                                                                    {item.time_name}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            {timeError && <p className="error">{timeError}</p>}
                                        </div>
                                    </div>

                                    <h3 className="ft-wt600 mt-30">Customise Features</h3>
                                    <div className="full-field mb-6">
                                        {Object.keys(features).map((feature) => (
                                            <div className="flex items-center mb-4 custom-checkbox dis_chek_cus mt-5" key={feature}>
                                                <input
                                                    type="checkbox"
                                                    className="w-4 h-4 text-blue-600 rounded"
                                                    checked={features[feature].status}

                                                    disabled={
                                                        (['qrCode', 'attendance', 'food', 'sleep', 'leaves'].includes(feature))
                                                        // features[feature].status=== true
                                                    }
                                                    onChange={() => handleFeatureChange(feature)}
                                                />
                                                <label className="ms-2 text-sm">{features[feature].name}</label>
                                                {['busTracking', 'cctv', 'fees'].includes(feature) && <img src={SplImg} alt="spl" style={{ width: '25px', height: '15px', marginLeft: "5px" }} />}
                                            </div>
                                        ))}
                                    </div>
                                    <h3 className="ft-wt600 mt-30">Additional Informations</h3>
                                    <div className="full-field">
                                        {Object.keys(additionalInfo).map((info) => (
                                            <div className="flex items-center mb-4 custom-checkbox mt-5" key={info}>
                                                <input
                                                    type="checkbox"
                                                    className="w-4 h-4 text-blue-600 rounded"
                                                    checked={additionalInfo[info].status}
                                                    onChange={() => handleAdditionalInfoChange(info)}
                                                />
                                                <label className="ms-2 text-sm">{additionalInfo[info].name}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="sp-bottom-sticky">
                                    <button className="sp-btn-create crt_div" onClick={() => handleCreateProgram()}>Create</button>
                                    <button className="sp-btn-cancel">Cancel</button>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>

        </section>
    );
}
