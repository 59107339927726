import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link, Navigate, Outlet } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../common/common";
import Sidebar from "../../common/layouts/CommonSidebar.jsx";
import Profile from "./Profile.jsx";
import Schoolprofile from "./Schoolprofile.jsx";
import { WindmillSpinner } from 'react-spinner-overlay';
import { Tabs } from "flowbite-react";
import { HiAdjustments, HiClipboardList, HiUserCircle } from "react-icons/hi";
import { MdDashboard } from "react-icons/md";
import CommonSidebarHeader from "../../common/layouts/CommonSidebarHeader.jsx";

export default function Setting() {


    const navigate = useNavigate();

    const [programsLoading, setProgramsLoading] = useState(false);
    const [programsData, setProgramsData] = useState({});
    const fetchProgramsData = async () => {
        setProgramsLoading(false);
        try {
            const postData = {
                school_id: localStorage.getItem("schoolId"),
                // school_id:24
            };
            const { data } = await axios.post(
                baseUrl + "/api/get_programs",
                postData,
                {
                    headers: {
                        "Authorization": localStorage.getItem('access_token')
                    }
                }
            );
            setProgramsData(data.data);
            setProgramsLoading(true);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchProgramsData();
    }, []);
    
    return (
        <section className="setting_page">
            <div className="outer-container">
                <div className="overlay" />
                <div id="left-panel" className="sp-left-panel">
                <CommonSidebarHeader/>
                    <div className="sp-middle-band">
                        <div className="sp-nav y-auto">
                            <div className="name_tag_side">
                                <h4 className="sp-title">Quick Actions</h4>
                                <ul>
                                    <li onClick={() => { navigate('/dashboard'); }}>
                                        <span>
                                            <i className="icon-Dashboard-Outline" />
                                            <p>Dashboard</p>
                                        </span>
                                    </li>
                                    <li
                                        onClick={() => {
                                            navigate("/activity");
                                        }}
                                    >
                                        <span>
                                            <i className="icon-Activity-Outline" />
                                            <p>Activity</p>
                                        </span>
                                    </li>
                                    <li
                                        onClick={() => {
                                            navigate("/calendar");
                                        }}
                                    >
                                        <span>
                                            <i className="icon-Timetable-Outline" />
                                            <p>Calendar</p>
                                        </span>
                                    </li>
                                    <li onClick={() => {
                                        navigate("/staff_list");
                                    }}>
                                        <span>
                                            <i className="icon-Staff-Outline" />
                                            <p>Staff</p>
                                        </span>
                                    </li>
                                </ul>
                                <h4 className="sp-title flexc justifySp">
                                    Programs{" "}
                                    <span id="add-programs" className="sp-plus">
                                        +
                                    </span>
                                </h4>
                                <ul>
                                    {programsLoading &&
                                        programsData &&
                                        programsData.length > 0 ? (
                                        programsData.map((data) => {
                                            return (
                                                <li onClick={() => {
                                                    localStorage.setItem("programId", data.id);
                                                    localStorage.setItem("programName", data.program_name);
                                                    navigate('/student_list');
                                                }}>
                                                    <span>
                                                        <i className="icon-Program-Outline-1" />
                                                        <p>{data.program_name}</p>
                                                    </span>
                                                </li>
                                            );
                                        })
                                    ) : (
                                        <div className="data-loader extracss_load">
                                            <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                                            <p>Loading...</p>
                                        </div>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <Sidebar />
                    </div>
                </div>
                <div className="sp-right-panel">
                    <div className="sp-top-bar flexc justifySp top_bar_cus">
                        <div className="ft-wt600 flexc pad_ic_ri">
                            <p className="program_tit">Settings</p>
                        </div>
                    </div>
                    <div className="tab_pro_sett">
                        <Tabs aria-label="Tabs with underline" variant="underline">
                            <Tabs.Item active title="My Profile">
                                <div className="tab_inside_content">
                                    <Profile />
                                </div>
                            </Tabs.Item>
                            <Tabs.Item title="School Profile">
                                <Schoolprofile />
                            </Tabs.Item>
                            {/* <Tabs.Item title="School Preferences">
                                This is <span className="font-medium text-gray-800 dark:text-white">Settings tab's associated content</span>.
                                Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classes to
                                control the content visibility and styling.
                            </Tabs.Item> */}
                        </Tabs>
                    </div>
                </div>
            </div>
        </section>
    );
}
