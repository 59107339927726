import {useEffect} from "react";
import { useNavigate } from 'react-router-dom';


export default function Signout() {    

    const navigate = useNavigate();
    useEffect(() => {
        localStorage.clear();
        sessionStorage.clear();
        // window.location.href = "/"; 
        navigate("/");  
    })
    
    return(
        <div>...Loading</div>
    );

}