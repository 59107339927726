import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dashboardavator1 from "./images/dashboard-avator1.png";
import dashboardavator2 from "./images/dashboard-avator2.png";
import dashboardavator3 from "./images/dashboard-avator3.png";
import dashboardavator4 from "./images/dashboard-avator4.png";
import avatar3 from "./images/avatar3.png";
import avatar2 from "./images/avatar2.png";
import "../../common/components/js/common.js";
import { baseUrl } from "../../common/common";
import axios from "axios";
import { formatDistanceToNow } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import Loader from "react-js-loader";
import Snackbar from '@mui/material/Snackbar';
import ListItemIcon from '@mui/material/ListItemIcon';
import SuccessIcon from "./images/success.svg";
import ErrorIcon from "./images/error.svg";
import run from "./images/run.png";
import run1 from "./images/run1.png";
import run_green from "./images/run_green.svg";
import run_red from "./images/run_red.svg";
import sleep_timer_icon from "./images/sleep_timer_icon.svg";
import lunch_timer_icon from "./images/lunch_timer_icon.svg";
import SplImg from "./images/spl.png";
import upar from "./images/upar.svg";
import ques from "./images/ques.svg";
import gift from "./images/gift.svg";
import { Dropdown } from "flowbite-react";
import { HiCheck } from "react-icons/hi";
import $ from "jquery";
import Chart from "chart.js/auto";
import Sidebar from "../../common/layouts/CommonSidebar.jsx";
import CommonSidebarHeader from "../../common/layouts/CommonSidebarHeader.jsx";
import { DashboardLogoComponent } from "./Common.jsx";

export default function Dashboard() {

  const [schoolDashboard, setSchoolDashboard] = useState([]);
  const [leaveApplied, setLeaveApplied] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [enquiriesData, setEnquiriesData] = useState([]);
  // 12-09-2024 start
  const [refreshNotification, setRefreshNotification] = useState(false);
  const [appliedLeaveStatus, setAppliedLeaveStatus] = useState(false);
  // 12-09-2024 end
  const chartRef = useRef(null);
  const enquiryRef = useRef(null);
  const dashboardRef = useRef(null);
  // 12-09-2024 start
  const targetElementRef = useRef(null);
  // 12-09-2024 end

  useEffect(() => {
    fetchProgramsData();
    fetchProgramTimeData();
    fetchTeachersData();
    getSchooldDashboard();
    getDashboardLeaveApplied();
    getDashboardchartdata();
    getDashboardEnquirydata();
  }, []);

  useEffect(() => {
    if (chartData.length > 0) {
      renderChart();
    }
    if (enquiriesData.length > 0) {
      renderEnquiriesChart();
    }
  }, [chartData, enquiriesData]);

  // 12-09-2024 start
  useEffect(() => {
    const handleClick = (event) => {
      if (dashboardRef.current && dashboardRef.current.contains(event.target)) {
        setAppliedLeaveStatus(false);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    if (appliedLeaveStatus) {
      if (targetElementRef.current) {
        targetElementRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [appliedLeaveStatus]);

  const handleAppliedLeaveStatus = (status) => {
    setAppliedLeaveStatus(status);
  };
  // 12-09-2024 end

  const renderChart = () => {
    if (chartRef.current !== null) {
      chartRef.current.destroy();
    }

    const ctx = document
      .getElementById("studentAttendanceChart")
      .getContext("2d");

    const colors = ["#95A4FC", "#BAEDBD", "#1C1C1C", "#B1E3FF", "#A8C5DA", "#A1E3CB", "#8C8C8C"];
    {/* 26-8-24 */ }
    const maxValue = schoolDashboard.student_count;

    // Create the chart
    chartRef.current = new Chart(ctx, {
      type: "bar",
      data: {
        labels: chartData.map((data) => data.program_name),
        datasets: [
          {
            label: "Attendance",
            data: chartData.map((data) => data.studentAttendanceCount),
            backgroundColor: colors,
            borderColor: colors,
            borderRadius: 8,
            barThickness: 30,
            borderSkipped: false,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: false, /* 26-8-24 */
            min: 1, /* 26-8-24 */
            max: maxValue, /* 26-8-24 */
            grid: {
              display: false,
            },
            border: {
              display: false
            },
            font: {
              size: 12,
              family: 'Inter',
              weight: 400
            },
            color: "var(--black-80, #1C1C1CCC)",
            ticks: {
              font: {
                size: 12,
                family: 'Inter',
                weight: 400
              },
              color: "var(--black-80, #1C1C1CCC)"
            }
          },
          x: {
            grid: {
              display: false,
            },
            border: {
              display: false
            },
            font: {
              size: 12,
              family: 'Inter',
              weight: 400
            },
            color: "var(--black-80, #1C1C1CCC)",
            ticks: {
              font: {
                size: 12,
                family: 'Inter',
                weight: 400
              },
              color: "var(--black-80, #1C1C1CCC)"
            }
          },
        },
        plugins: {
          legend: {
            display: false,
          }
        }

      },
    });
  };

  const renderEnquiriesChart = () => {
    if (enquiryRef.current !== null) {
      enquiryRef.current.destroy();
    }

    const ctx = document
      .getElementById("newEnquiriesChart")
      .getContext("2d");

    const colors = ["#95A4FC", "#BAEDBD", "#1C1C1C", "#B1E3FF", "#A8C5DA", "#A1E3CB", "#8C8C8C"]

    // Create the chart
    enquiryRef.current = new Chart(ctx, {
      type: "bar",
      data: {
        labels: enquiriesData.map((data) => data.program_name),
        datasets: [
          {
            label: "New Enquiry",
            data: enquiriesData.map((data) => data.studentAttendanceCount),
            backgroundColor: colors,
            borderColor: colors,
            borderRadius: 8,
            barThickness: 30,
            borderSkipped: false,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false,
            },
            border: {
              display: false
            },
            font: {
              size: 12,
              family: 'Inter',
              weight: 400
            },
            color: "var(--black-80, #1C1C1CCC)",
            ticks: {
              font: {
                size: 12,
                family: 'Inter',
                weight: 400
              },
              color: "var(--black-80, #1C1C1CCC)"
            }
          },
          x: {
            grid: {
              display: false,
            },
            border: {
              display: false
            },
            font: {
              size: 12,
              family: 'Inter',
              weight: 400
            },
            color: "var(--black-80, #1C1C1CCC)",
            ticks: {
              font: {
                size: 12,
                family: 'Inter',
                weight: 400
              },
              color: "var(--black-80, #1C1C1CCC)"
            }
          },
        },
        plugins: {
          legend: {
            display: false,
          }
        }
      },
    });
  };

  /* // sakthivel 04-05 JULY 2024 start // */
  const getDashboardchartdata = () => {
    axios({
      method: "post",
      url: baseUrl + "/api/student_attendance_chart",
      data: {
        school_id: localStorage.getItem("schoolId"),
      },
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": localStorage.getItem('access_token')
      },
    }).then((response) => {
      if (response.data.status === true) {
        console.log("4444444444", response.data.program);
        const existingData = response.data.program;
        const newObjects = [
          {
            "school_id": "1",
            "program_id": 1,
            "program_name": "Day Care",
            "studentcount": 100,
            "studentAttendanceCount": 1
          },
          {
            "school_id": "1",
            "program_id": 2,
            "program_name": "Play group",
            "studentcount": 75,
            "studentAttendanceCount": 11
          },
          {
            "school_id": "1",
            "program_id": 5,
            "program_name": "Nursery",
            "studentcount": 50,
            "studentAttendanceCount": 0
          },
          // {
          //   "school_id": "1",
          //   "program_id": 6,
          //   "program_name": "Junior KG",
          //   "studentcount": 53,
          //   "studentAttendanceCount": 0
          // },
          // {
          //   "school_id": "1",
          //   "program_id": 178,
          //   "program_name": "Sasi",
          //   "studentcount": 2,
          //   "studentAttendanceCount": 2
          // },
          // {
          //   "school_id": "1",
          //   "program_id": 7,
          //   "program_name": "Senior KG",
          //   "studentcount": 230,
          //   "studentAttendanceCount": 0
          // },
        ];
        // const updatedData = [...existingData, ...newObjects];
        const updatedData = [...existingData];
        setChartData(updatedData);
      }
    });
  };


  // const getDashboardchartdata = () => {
  //   axios({
  //     method: "post",
  //     url: baseUrl + "/api/student_attendance_chart",
  //     data: {
  //       school_id: localStorage.getItem("schoolId"),
  //     },
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //       "Authorization": localStorage.getItem('access_token')
  //     },
  //   }).then((response) => {
  //     if (response.data.status === true) {
  //       console.log("4444444444", response.data.program);
  //       setChartData(response.data.program);
  //     }
  //   });
  // };

  // const getDashboardEnquirydata = () => {
  //   axios({
  //     method: "post",
  //     url: baseUrl + "/api/student_attendance_chart",
  //     data: {
  //       school_id: localStorage.getItem("schoolId"),
  //     },
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //       "Authorization": localStorage.getItem('access_token')
  //     },
  //   }).then((response) => {
  //     if (response.data.status === true) {
  //       console.log("4444444444", response.data.program);
  //       setEnquiriesData(response.data.program);
  //     }
  //   });
  // };

  const getDashboardEnquirydata = () => {
    axios({
      method: "post",
      url: baseUrl + "/api/student_attendance_chart",
      data: {
        school_id: localStorage.getItem("schoolId"),
      },
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": localStorage.getItem('access_token')
      },
    }).then((response) => {
      if (response.data.status === true) {
        console.log("4444444444", response.data.program);
        const existingData = response.data.program;
        const newObjects = [
          {
            "school_id": "1",
            "program_id": 1,
            "program_name": "Dc",
            "studentcount": 0,
            "studentAttendanceCount": 0
          },
          {
            "school_id": "1",
            "program_id": 2,
            "program_name": "Nursery",
            "studentcount": 0,
            "studentAttendanceCount": 0
          },
          {
            "school_id": "1",
            "program_id": 5,
            "program_name": "JKG",
            "studentcount": 0,
            "studentAttendanceCount": 0
          },
          {
            "school_id": "1",
            "program_id": 6,
            "program_name": "SKG",
            "studentcount": 0,
            "studentAttendanceCount": 0
          },
          {
            "school_id": "1",
            "program_id": 178,
            "program_name": "K1",
            "studentcount": 0,
            "studentAttendanceCount": 0
          },
          {
            "school_id": "1",
            "program_id": 7,
            "program_name": "SKG 2",
            "studentcount": 0,
            "studentAttendanceCount": 0
          },
        ];
        // const updatedData = [...existingData, ...newObjects];
        const updatedData = [...newObjects];
        setEnquiriesData(updatedData);
      }
    });
  };

  /* // sakthivel 04-05 JULY 2024 end // */


  const getSchooldDashboard = () => {
    axios({
      method: "post",
      url: baseUrl + "/api/school_dashboard",
      data: {
        school_id: localStorage.getItem("schoolId"),
      },
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": localStorage.getItem('access_token')
      },
    }).then((response) => {
      if (response.data.status === true) {
        setSchoolDashboard(response.data);
      }
    });
  };

  useEffect(() => { }, [schoolDashboard]);

  const getDashboardLeaveApplied = () => {
    axios({
      method: "post",
      url: baseUrl + "/api/dashboard_leave_applied",
      data: {
        school_id: localStorage.getItem("schoolId"),
      },
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": localStorage.getItem('access_token')
      },
    }).then((response) => {
      if (response.data.status === true) {
        setLeaveApplied(response.data.data);
      }
    });
  };

  const leaveApprove = async (item) => {
    const isComfirm = window.confirm(
      "Are you sure you want to approve this leave?"
    );
    if (isComfirm) {
      console.log("leaveApprove_________", item);
      axios({
        method: "post",
        url: baseUrl + "/api/staff_leave_request_actions",
        data: {
          id: item.id,
          updated_by: localStorage.getItem('loginUserId'),
          leave_status: 4,
          staff_id: item.staff_id,
          status_updated_by: localStorage.getItem('loginUserId'),
        },
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": localStorage.getItem('access_token')
        },
      }).then((response) => {
        if (response.data.status === true) {
          setSnackbarMessage(response.data.message);
          setMessageType('success');
          handleSnackbarOpen();
          getSchooldDashboard();
          getDashboardLeaveApplied();
        } else {
          setSnackbarMessage(response.data.message);
          setMessageType('error');
          handleSnackbarOpen();
        }
      });
    }
  };

  const getFormatedDate = (date, type) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const dateObj = new Date(date);
    if (isNaN(dateObj.getTime())) {
      return "N/A";
    }

    const month = monthNames[dateObj.getMonth()];
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const isToday = dateObj.toDateString() === today.toDateString();
    const isTomorrow = dateObj.toDateString() === tomorrow.toDateString();
    const isYesterday = dateObj.toDateString() === yesterday.toDateString();

    if (type == "program") {
      return `${month} ${day}, ${year}`;
    } else if (type == "holiday") {
      return `${day} ${month} ${year}`;
    } else if (type == "current") {
      return `${month} ${day}, ${year}`;
    } else if (type == "leave") {
      return `${month} ${day} ${year}`;
    } else if (type == "birthday") {
      if (isToday) {
        return "Today";
      } else if (isTomorrow) {
        return "Tomorrow";
      } else if (isYesterday) {
        return "Yesterday";
      } else {
        const month = monthNames[dateObj.getMonth()];
        const day = dateObj.getDate();
        const year = dateObj.getFullYear();
        return `${day} ${month} ${year}`;
      }
    }
  };

  const studentActivityTimeformat = (start, end) => {
    if (!start && !end) {
      return "N/A";
    }
    const options = { hour: "numeric", minute: "numeric" };
    const startDate = new Date(start);
    const endDate = new Date(end);
    const formattedStartTime = startDate.toLocaleTimeString([], options);
    const formattedEndTime = endDate.toLocaleTimeString([], options);
    return `${formattedStartTime} – ${formattedEndTime}`;
  };

  const getTimeDifference = (apiTime) => {
    const utcDate = new Date(apiTime);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localDate = toZonedTime(utcDate, timeZone);
    let timeDifference = formatDistanceToNow(localDate, { addSuffix: true });
    timeDifference = timeDifference.replace(/^about /, "");
    return timeDifference;
  };

  const backgroundClasses = [
    "sp-row3-bt1",
    "sp-row3-bt2",
    "sp-row3-bt3",
    "sp-row3-bt4",
  ];

  // --------------------------------------

  const navigate = useNavigate();
  const [programsData, setProgramsData] = useState({});
  const [programsLoading, setProgramsLoading] = useState(false);

  const fetchProgramsData = async () => {
    setProgramsLoading(false);
    try {
      const postData = {
        school_id: localStorage.getItem('schoolId')
      }
      const { data } = await axios.post(baseUrl + "/api/get_programs", postData, {
        headers: {
          "Authorization": localStorage.getItem('access_token')
        }
      });
      setProgramsData(data.data);
      setProgramsLoading(true);
    } catch (error) {
      console.log(error)
    }
  }

  // ------11-06----------------
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };
  const handleSnackbarOpen = () => {
    setSnackbarState({ ...snackbarState, open: true });
  };
  useEffect(() => { }, [snackbarMessage, messageType]);

  // -------------------------19-06-------------------------

  const handleOpenNewProgramPopupVisible = () => {
    resetFeaturesAndAdditionalInfo();
    setProgramNameError(null);
    setProgramTeacherError(null);
    setTimeError(null);
    setNewProgramName("");
    setTeacher("");
    setProgramStartTime("");
    setProgramEndTime("");
    setNewProgramPopupVisible(true);
  }

  const [newProgramPopupVisible, setNewProgramPopupVisible] = useState(false);
  const [programStartTime, setProgramStartTime] = useState("");
  const [programEndTime, setProgramEndTime] = useState("");
  const [programTeacher, setTeacher] = useState("");
  const [timeData, setTimeData] = useState([]);
  const [teachersData, setTeachersData] = useState([]);
  const selectedStartTime = timeData.find(data => data.id === programStartTime);
  const selectedEndTime = timeData.find(data => data.id === programEndTime);
  const selectedTeachers = teachersData.find(data => data.id === programTeacher);

  const fetchProgramTimeData = async () => {
    axios({
      method: "post",
      url: baseUrl + "/api/get_time",
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": localStorage.getItem('access_token')
      },
    }).then((response) => {
      console.log(response);
      if (response.data.status === true) {
        setTimeData(response.data.data);
        console.log(response);
      }
    });
  }

  const fetchTeachersData = async () => {
    axios({
      method: "post",
      url: baseUrl + "/api/teacher_list",
      data: {
        school_id: localStorage.getItem("schoolId")
      },
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": localStorage.getItem('access_token')
      },
    }).then((response) => {
      console.log(response);
      if (response.data.status === true) {
        setTeachersData(response.data.data);
        console.log(response);
      }
    });
  }

  const [newProgramName, setNewProgramName] = useState('');
  const [features, setFeatures] = useState({
    qrCode: { status: true, name: 'QR Code', value: 2 },
    attendance: { status: true, name: 'Attendance', value: 5 },
    food: { status: true, name: 'Food', value: 3 },
    sleep: { status: true, name: 'Sleep', value: 4 },
    leaves: { status: true, name: 'Leaves', value: 6 },
    busTracking: { status: false, name: 'Bus Tracking', value: 7 },
    cctv: { status: false, name: 'CCTV', value: 8 },
    fees: { status: false, name: 'Fees', value: 9 },
  });
  const [additionalInfo, setAdditionalInfo] = useState({
    inviteParents: {
      status: true,
      name: 'Automatically Invite Parents as soon as student profile created',
      value: 1
    },
    // addParentChat: {
    //   status: true,
    //   name: 'Add Parent in chat as soon as student profile created',
    //   value: 2
    // },
    // createStudentID: {
    //   status: true,
    //   name: 'Automatically create Student ID as soon as profile created',
    //   value: 3
    // },
  });

  const resetFeaturesAndAdditionalInfo = () => {
    setFeatures({
      qrCode: { status: true, name: 'QR Code', value: 2 },
      attendance: { status: true, name: 'Attendance', value: 5 },
      food: { status: true, name: 'Food', value: 3 },
      sleep: { status: true, name: 'Sleep', value: 4 },
      leaves: { status: true, name: 'Leaves', value: 6 },
      busTracking: { status: false, name: 'Bus Tracking', value: 7 },
      cctv: { status: false, name: 'CCTV', value: 8 },
      fees: { status: false, name: 'Fees', value: 9 }
    });
    setAdditionalInfo({
      inviteParents: {
        status: true,
        name: 'Automatically Invite Parents as soon as student profile created',
        value: 1
      },
      // addParentChat: {
      //   status: true,
      //   name: 'Add Parent in chat as soon as student profile created',
      //   value: 2
      // },
      // createStudentID: {
      //   status: true,
      //   name: 'Automatically create Student ID as soon as profile created',
      //   value: 3
      // }
    })
  }
  const handleFeatureChange = (feature) => {
    setFeatures({
      ...features,
      [feature]: {
        ...features[feature],
        status: !features[feature].status,
      },
    });
  };
  const handleAdditionalInfoChange = (info) => {
    setAdditionalInfo({
      ...additionalInfo,
      [info]: {
        ...additionalInfo[info],
        status: !additionalInfo[info].status,
      },
    });
  };

  const [programeNameError, setProgramNameError] = useState(null);
  const [programeTeacherError, setProgramTeacherError] = useState(null);
  const [timeError, setTimeError] = useState(null);
  const validateProgramForm = () => {
    let valid = true;
    if (newProgramName === "") {
      setProgramNameError("Please enter program name");
      valid = false;
    } else {
      setProgramNameError(null);
    }
    if (programTeacher === "") {
      setProgramTeacherError("Please select program teacher");
      valid = false;
    } else {
      setProgramTeacherError(null);
    }
    // if (programStartTime === "" || programEndTime === "") {
    //   setTimeError("Please select start and end time");
    //   valid = false;
    // } else {
    //   setTimeError(null);
    // }
    return valid;
  };

  const handleCreateProgram = () => {

    const selectedFeatureValues = Object.keys(features).filter((key) => features[key].status).map((key) => features[key].value);
    const selectedAdditionalInfoValues = Object.keys(additionalInfo).filter((key) => additionalInfo[key].status).map((key) => additionalInfo[key].value);

    console.log({
      school_id: localStorage.getItem("schoolId"),
      program_name: newProgramName,
      teacher_id: programTeacher,
      from_time_id: programStartTime,
      to_time_id: programEndTime,
      features: selectedFeatureValues.join(","),
      additional_info: selectedAdditionalInfoValues.join(","),
      status: 1,
      created_by: localStorage.getItem("loginUserId")
    });
    if (validateProgramForm()) {
      try {
        axios({
          method: "post",
          url: baseUrl + "/api/programs_add_edit",
          data: {
            school_id: localStorage.getItem("schoolId"),
            program_name: newProgramName,
            teacher_id: programTeacher,
            from_time_id: programStartTime,
            to_time_id: programEndTime,
            features: selectedFeatureValues.join(","),
            additional_info: selectedAdditionalInfoValues.join(","),
            status: 1,
            created_by: localStorage.getItem("loginUserId")
          },
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": localStorage.getItem('access_token')
          },
        }).then((response) => {
          console.log(response);
          if (response.data.status === true) {
            $('.overlay').removeClass('visible');
            $('.popup-program-container').removeClass('visible');
            setNewProgramPopupVisible(false);
            fetchProgramsData();
            setSnackbarMessage(response.data.message);
            setMessageType('success');
            handleSnackbarOpen();
          } else {
            setSnackbarMessage(response.data.message);
            setMessageType('error');
            handleSnackbarOpen();
          }
        });
      } catch (error) {
        console.log(error)
      }
    }

  };

  return (
    <section >
      <Snackbar className="snack_total"
        anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
        open={snackbarState.open}
        onClose={handleSnackbarClose}
        message={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ListItemIcon>
              <img src={messageType === 'success' ? SuccessIcon : ErrorIcon} alt="Success" style={{ width: '24px', height: '24px' }} />
            </ListItemIcon>
            <div className="success_cuc">
              <p> {snackbarMessage} </p>
            </div>
          </div>
        }
        ContentProps={{
          sx: {
            border: "1px solid",
            opacity: "1",
            background: messageType === 'success' ? "#40BE61" : "#FD4954",
            borderColor: messageType === 'success' ? "#40BE61" : "#FD4954",
            width: '415px'
          }
        }}
        autoHideDuration={3000}
      />
      <div className="outer-container dash_page" ref={dashboardRef}>
        <div className="overlay" />
        <div id="left-panel" className="sp-left-panel">
        <CommonSidebarHeader/>
          <div className="sp-middle-band">
            <div className="sp-nav y-auto">
              <div className="name_tag_side">
                <h4 className="sp-title">Quick Actions</h4>
                <ul>
                  <li className="selected">
                    <span>
                      <i className="icon-Dashboard-Outline" />
                      <p>Dashboard</p>
                    </span>
                  </li>
                  <li onClick={() => { navigate('/activity'); }}>
                    <span>
                      <i className="icon-Activity-Outline" />
                      <p>Activity</p>
                    </span>
                  </li>
                  <li onClick={() => { navigate('/calendar'); }}>
                    <span>
                      <i className="icon-Timetable-Outline" />
                      <p>Calendar</p>
                    </span>
                  </li>
                  <li onClick={() => { navigate('/staff_list'); }}>
                    <span>
                      <i className="icon-Staff-Outline" />
                      <p>Staff</p>
                    </span>
                  </li>
                  {/* <li>
                    <span>
                      <i className="icon-Message-Outline" />
                      Messages
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Fees-Outline" />
                      Fees Management
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Enquiries-Outline" />
                      Enquiries
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Invites-Outline" />
                      Invites
                    </span>
                  </li> */}
                </ul>
                <h4 className="sp-title flexc justifySp" onClick={() => handleOpenNewProgramPopupVisible()}>
                  Programs{" "}
                  <span id="add-programs" className="sp-plus">
                    +
                  </span>
                </h4>
                <ul>
                  {programsLoading && programsData && programsData.length > 0 ?
                    programsData.map((data) => {
                      return (
                        <li onClick={() => {
                          localStorage.setItem("programId", data.id);
                          localStorage.setItem("programName", data.program_name);
                          navigate('/student_list');
                        }}>
                          <span>
                            <i className="icon-Program-Outline-1" />
                            <p>{data.program_name}</p>
                          </span>
                        </li>
                      );
                    }) :
                    <div className="data-loader">
                      <Loader
                        type="spinner-cub"
                        bgColor="#4D6EE3"
                        color="#6B6F8C"
                        title="Loading..."
                        size={35}
                      />
                    </div>
                  }
                </ul>
              </div>
            </div>

          </div>
        </div>
        {/* my code */}
        <div className="sp-right-panel">
          <div className="sp-dashboard-container">
            <div className="dashboard-inr">
              <div className="dashboard-top flexc justifySp">
                <p className="ft-wt700">Dashboard</p>
                {/* <button className="ft-wt500 customize_but">Customise</button> */}
              </div>
              {/* 25.07.2024 - Added class for tablet responsive */}
              <div
                className="dashboard-cont flexc justifySp tab-responsive"
                style={{ alignItems: "flex-start" }}
              >
                <div className="dashboard-cont-left">
                  {/* 25.07.2024 - Added class for tablet responsive */}
                  <div className="dash-row1 dash-row flexc justifySp tab-responsive">
                    <div className="dash-row1-box dash-row1-box1">
                      <span className="ft-wt600 ft-13">Student Attendance</span>
                      <div className="dash-box1-btm flexc justifySp">
                        <span className="ft-20 ft-wt700 att-count">
                          {schoolDashboard.student_attendance}/
                          {schoolDashboard.student_count}
                        </span>
                        {/* <span className="dash-row1-range" /> */}
                        <img
                          src={dashboardavator1}
                          width={25}
                          height={25}
                          alt="Student Attendance"
                        />
                      </div>
                    </div>
                    <div className="dash-row1-box dash-row1-box2">
                      <span className="ft-wt600 ft-13">Teacher Attendance</span>
                      <div className="dash-box1-btm flexc justifySp">
                        <span className="ft-20 ft-wt700 att-count">
                          {schoolDashboard.staff_attendance}/
                          {schoolDashboard.staff_count}
                        </span>
                        <img
                          src={dashboardavator2}
                          width={25}
                          height={25}
                          alt="Teacher Attendance"
                        />
                      </div>
                    </div>
                    <div className={`dash-row1-box dash-row1-box3`}>
                      <span className="ft-wt600 ft-13">Leave Applied</span>
                      <div className="dash-box1-btm flexc justifySp">
                        <span className="ft-20 ft-wt700 att-count">
                          {schoolDashboard.leave_applied}
                        </span>
                        <img
                          src={dashboardavator3}
                          width={25}
                          height={25}
                          alt="Leave Applied"
                        />
                      </div>
                    </div>
                    <div className="dash-row1-box dash-row1-box4">
                      <span className="ft-wt600 ft-13">Absents</span>
                      <div className="dash-box1-btm flexc justifySp">
                        <span className="ft-20 ft-wt700 att-count">
                          {schoolDashboard.absents}
                        </span>
                        <img
                          src={dashboardavator4}
                          width={25}
                          height={25}
                          alt="Leave Applied"
                        />
                      </div>
                    </div>
                  </div>
                  {/* 25.07.2024 - Added class for tablet responsive */}
                  <div className="dash-row2 dash-row flexc justifySp tab-responsive">
                    <div className="dash-bar-chart1 dash-bar-chart sp-dashboard">
                      <p className="ft-wt600 ft-13 dash-titles" style={{ marginBottom: "5%" }}>
                        Student Attendance by Class
                      </p>
                      <canvas
                        id="studentAttendanceChart"
                        width="400"
                        height="200"
                      ></canvas>
                    </div>
                    <div className="dash-bar-chart2 dash-bar-chart sp-dashboard">
                      <p className="ft-wt600 ft-13 dash-titles" style={{ marginBottom: "5%" }}>New Enquiries</p>
                      <canvas
                        id="newEnquiriesChart"
                        width="400"
                        height="200"
                      ></canvas>
                      {/* <div className="bar-chart-cont flexc ft-13">
                        <ul>
                          <li>50</li>
                          <li>30</li>
                          <li>20</li>
                          <li>10</li>
                        </ul>
                        <ul className="chart-colum-cont flexc">
                          <li>
                            <span className="chart-colm1" /> DC
                          </li>
                          <li>
                            <span className="chart-colm2" /> Nursury
                          </li>
                          <li>
                            <span className="chart-colm3" /> JKG
                          </li>
                          <li>
                            <span className="chart-colm4" /> SKG
                          </li>
                          <li>
                            <span className="chart-colm5" /> K1
                          </li>
                          <li>
                            <span className="chart-colm6" /> SKG2
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                  <div
                    className="dash-row3 dash-row flexc justifySp programe-card"
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    {schoolDashboard.program?.length > 0 ? (
                      schoolDashboard.program.map((item, index) => {
                        const backgroundClass =
                          backgroundClasses[index % backgroundClasses.length];
                        return (
                          <>
                            <div
                              key={index}
                              className="dash-row3-box sp-dashboard"
                            >
                              <span className={`ft-wt600 program-name ${backgroundClass}`}>
                                {item.program_name}
                              </span>
                              <p className="ft-wt600 ft-13 program-title">{item.title}</p>
                              <span className="flexc sp-row3-dt  ft-13">
                                <i className="icon-Timetable-Outline" />
                                <span className="program-time">{getFormatedDate(item.start_date, "program")}</span>
                              </span>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* 25.07.2024 - Added class for tablet responsive */}
                  <div className="dash-row4 dash-row flexc justifySp tab-responsive">
                    <div className="dash-row4-activity sp-dashboard activity-main-card">
                      <div className="activity-row1 flexc justifySp">
                        <p className="ft-wt600 ft-13 dash-titles">Recent Activity</p>
                        {/* <button className="ft-wt600 font_cus view_cus">View all</button> */}
                      </div>
                      <span className="activity-dt">
                        {getFormatedDate(new Date(), "current")}
                      </span>
                      <div className="activity-row2">
                        {schoolDashboard.activity?.length > 0 ? (
                          schoolDashboard.activity
                            .slice(0, 3)
                            .map((item, index) => {
                              const maxTitleCharacters = 35;
                              const maxContentCharacters = 40;
                              const title =
                                item.post_title.length > maxTitleCharacters
                                  ? item.post_title.substring(
                                    0,
                                    maxTitleCharacters
                                  ) + "..."
                                  : item.post_title;
                              const content =
                                item.post_content.length > maxContentCharacters
                                  ? item.post_content.substring(
                                    0,
                                    maxContentCharacters
                                  ) + "..."
                                  : item.post_content;
                              return (
                                <div
                                  key={index}
                                  className="sp-activities flexc"
                                >
                                  <span className="sp-activ-bt sp-activ-bt1 flexc">
                                    <span className="sp-lpdr icon-sp-present" />
                                  </span>
                                  <div className="sp-activ-cont ft-13 extra_per_div">
                                    <p>
                                      <span className="ft-wt600 activity-title">{title}</span>{" "}
                                      <span className="actiity-content" dangerouslySetInnerHTML={{ __html: content }}></span>
                                    </p>
                                    <span className="sp-activ-hr activity-time">
                                      {getTimeDifference(item.created_on)}
                                    </span>
                                  </div>
                                </div>
                              );
                            })
                        ) : (
                          <div
                            className="ft-13 grid place-items-center"
                            style={{ paddingTop: "50px" }}
                          >
                            <p>No data found</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="dash-row4-activity sp-dashboard activity-main-card">
                      <div className="activity-row1 flexc justifySp">
                        <p className="ft-wt600 ft-13 dash-titles">Students Activity time</p>
                        {/* <button className="ft-wt600 font_cus view_cus">
                          View all
                        </button> */}
                      </div>
                      <div className="sp-activity-row2 flexc justifySp activity-card">
                        <div className="activity-colum flexc justifySp">
                          <img src={run_green} style={{ width: "31%" }} alt="" />
                          <div className="activity-colum-cont">
                            <span>
                              {studentActivityTimeformat(
                                schoolDashboard?.checkinfirst,
                                schoolDashboard?.checkinlast
                              )}
                            </span>
                            <p className="ft-wt600 ft-13">Students Check in</p>
                          </div>
                        </div>
                        <div className="activity-colum flexc  dis_fe_st_pos">
                          {/* <span className="sp-activity-img" /> */}
                          <img src={lunch_timer_icon} style={{ width: "31%" }} alt="" />
                          <div className="activity-colum-cont">
                            <span>
                              {studentActivityTimeformat(
                                schoolDashboard?.foodfirsttime,
                                schoolDashboard?.foodlasttime
                              )}
                            </span>
                            <p className="ft-wt600 ft-13">Lunch Time</p>
                          </div>
                        </div>
                        <div className="activity-colum flexc justifySp">
                          {/* <span className="sp-activity-img" /> */}
                          <img src={run_red} style={{ width: "31%" }} alt="" />
                          <div className="activity-colum-cont">
                            <span>
                              {studentActivityTimeformat(
                                schoolDashboard?.checkoutfirst,
                                schoolDashboard?.checkoutlast
                              )}
                            </span>
                            <p className="ft-wt600 ft-13">Students Check Out</p>
                          </div>
                        </div>
                        <div className="activity-colum flexc  dis_fe_st_pos">
                          {/* <span className="sp-activity-img" /> */}
                          <img src={sleep_timer_icon} style={{ width: "31%" }} alt="" />
                          <div className="activity-colum-cont">
                            <span>
                              {studentActivityTimeformat(
                                schoolDashboard?.sleepfirsttime,
                                schoolDashboard?.sleeplasttime
                              )}
                            </span>
                            <p className="ft-wt600 ft-13">Sleep time</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashboard-cont-right">
                  <div className="dash-right-row1 sp-dashboard">
                    <div className="activity-row1 flexc justifySp">
                      {/* 29-8-2024 */}
                      <p className="ft-wt600 ft-14 dash-titles">
                        Upcoming Birthday
                      </p>
                      {/* <button className="ft-wt600 font_cus view_cus">View all</button> */}
                    </div>
                    <div className="dash-right-activ2 flexc justifySp">
                      <p className="ft-wt600 ft-13 sub-title">Name</p>
                      <p className="ft-wt600 ft-13 sub-title">Date</p>
                    </div>
                    {/* 29-8-2024 */}
                    <div className="upcoming">
                      <div className="dash-right-activ3" style={{ height: '155px' }}>
                        {schoolDashboard.staff_dob?.length > 0 ? (
                          schoolDashboard.staff_dob
                            .map((item, index) => {
                              return (
                                <>
                                  <div
                                    key={index}
                                    className="dash-activ3-row flexc justifySp"
                                  >
                                    <div className="activ3-row1-left flexc">
                                      {item.profile_image !== null ? (
                                        <>
                                          <img
                                            src={
                                              item.profile_image !== null
                                                ? baseUrl + "/api/" + item.profile_image
                                                : avatar3
                                            }
                                            width={29}
                                            height={29}
                                            style={{ borderRadius: "8px" }}
                                          />
                                        </>
                                      ) : (
                                        <>
                                        {/* <DashboardLogoComponent name={item.first_name} bgcolor={item.staff_color??item.std_color}/> */}
                                          <span className="text-xsmall acti_post_av  mr-rt10 " style={{backgroundColor:item.staff_color??item.std_color}}>{item.first_name.slice(0, 1).toUpperCase()}</span>
                                        </>
                                      )}
                                      {/* 11-09-2024 Start*/}
                                      <p className="ft-wt600 ft-13">
                                        <span className="hol-title m-0">{item.first_name} {item.last_name}</span>
                                        <span className="ft-wt400 ft-12">{item.role_name ?? item.program_name}</span>
                                      </p>
                                      {/* 11-09-2024 end*/}
                                    </div>
                                    <span className="ft-wt400 ft-13 upcoming_dte_style">
                                      {getFormatedDate(item.dob_this_year, "birthday")}
                                    </span>
                                  </div>
                                </>
                              );
                            })
                        ) : (
                          <div
                            className="ft-13 grid place-items-center"
                            style={{ padding: "5%" }}
                          >
                            <p>No data found</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="dash-right-row2 sp-dashboard">
                    <div className="activity-row1 flexc justifySp">
                      <p className="ft-wt600 ft-14 dash-titles">Upcoming Holidays</p>
                      {/* <button className="ft-wt600 font_cus view_cus">View all</button> */}
                    </div>
                    <div className="dash-right-activ2 flexc justifySp">
                      <p className="ft-wt600 ft-13 sub-title">Holiday Name</p>
                      <p className="ft-wt600 ft-13 sub-title">Date</p>
                    </div>
                    {/* 29-8-2024 */}
                    <div className="upcoming">
                      <div className="dash-right-activ3" style={{ height: '180px' }}>
                        {schoolDashboard.upcoming_holiday?.length > 0 ? (
                          schoolDashboard.upcoming_holiday
                            .map((item, index) => {
                              return (
                                <>
                                  <div
                                    key={index}
                                    className="dash-activ3-row flexc justifySp"
                                  >
                                    <div className="activ3-row1-left flexc">
                                      <span className="holi-dt-ic flexc">
                                        <i className="icon-Timetable-Outline" />
                                      </span>
                                      {/* 11-09-2024 Start*/}
                                      <p className="ft-wt600 ft-13" style={{ marginTop: "-5px" }}>
                                        <span className="hol-title">{item.title}</span>
                                        <span className="ft-wt400 ft-12">
                                          {item.program_name ?? "All Program"}
                                        </span>
                                      </p>
                                      {/* 11-09-2024 end*/}
                                    </div>
                                    <span className="ft-wt400 ft-12 upcoming_dte_style">
                                      {getFormatedDate(item.start_date, "holiday")}
                                    </span>
                                  </div>
                                </>
                              );
                            })
                        ) : (
                          <div
                            className="ft-13 grid place-items-center"
                            style={{ padding: "5%" }}
                          >
                            <p>No data found</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div ref={targetElementRef} className={`dash-right-row3 sp-dashboard ${appliedLeaveStatus == true ? "leave-widget-highlight" : ""}`}>  {/* 12-09-2024 single line */}
                    <div className="activity-row1 flexc justifySp">
                      <p className="ft-wt600 ft-14 dash-titles">Leave Applied</p>
                      {/* <button className="ft-wt600 font_cus view_cus">View all</button> */}
                    </div>
                    <div className="dash-right-activ2 flexc justifySp">
                      <p className="ft-wt600 ft-13 sub-title">Name</p>
                      {/* <p className="ft-wt600 ft-13">Date</p> */}
                    </div>
                    {/* 29-8-2024 */}
                    <div className="upcoming">
                      <div className="dash-right-activ3" style={{ height: '120px' }}>
                        {leaveApplied?.length > 0 ? (
                          leaveApplied.map((item, index) => {
                            return (
                              <>
                                <div
                                  key={index}
                                  className="dash-activ3-row flexc justifySp"
                                >
                                  <div className="activ3-row1-left flexc">
                                    {item.profile_image === null ? (
                                      <>
                                        <img
                                          src={
                                            item.profile_image !== null
                                              ? baseUrl + "/api/" + item.profile_image
                                              : avatar3
                                          }
                                          // src={avatar3}
                                          width={29}
                                          height={29}
                                          style={{ borderRadius: "8px" }}
                                        />
                                      </>
                                    ) : (
                                      <>
                                       <DashboardLogoComponent name={item.first_name} bgcolor={item.staff_color}/>
                                        {/* <span className="text-xsmall top_name_single mr-rt10">{item.first_name.slice(0, 1).toUpperCase()}</span> */}
                                      </>
                                    )}
                                    <p className="ft-wt600 ft-13">
                                      {item.first_name} {item.last_name}
                                      <span className="ft-wt400 ft-12 ">
                                        {getFormatedDate(
                                          item.start_date,
                                          "leave"
                                        )}{" "}
                                        -{" "}
                                        {getFormatedDate(item.end_date, "leave")}
                                      </span>
                                    </p>
                                  </div>
                                  <button
                                    class="ft-12"
                                    onClick={() => leaveApprove(item)}
                                  >
                                    Approve
                                  </button>
                                </div>
                              </>
                            );
                          })
                        ) : (
                          <div
                            className="ft-13 grid place-items-center"
                            style={{ padding: "5%" }}
                          >
                            <p>No data found</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Create New Program */}
        {
          newProgramPopupVisible && (
            <div className="popup-program-container">
              <div className="popup-wrap">
                <h2 className="ft-wt700">Create New Program</h2>
                <div className="sp-pop-header ">
                  <div className="form-section-default flexc justifySp fwrap">
                    <div className="field-label full-field error_mes_cus  pos_stu_cus">
                      <label>Program Name</label>
                      <div className="">
                        <input
                          className={`field-value-holder margin_top10p custamize_color_place  extra_bor_clr  sp-field-input ${programeNameError ? 'error' : ''}`}
                          // className="sp-field-input"
                          type="text"
                          placeholder="Enter Program name"
                          value={newProgramName}
                          onChange={(e) => setNewProgramName(e.target.value)}
                        />
                      </div>
                      {programeNameError && <p className="error pos_abo_stu">{programeNameError}</p>}
                    </div>
                    <div className="field-label half-field error_mes_cus extra_pad_drop">
                      <label>Program Teachers</label>
                      <div className="field-value-holder margin_top10p dropbutton pos_stu_cus"
                        style={{
                          width: "100%",
                          borderColor: programeTeacherError && "red",
                        }}
                      >
                        <Dropdown
                          label={
                            <span
                              className={`${selectedTeachers ? 'selected-item' : ''}`}
                              style={{
                                fontSize: "13px",
                                justifyContent: "flex-start",
                                color: selectedTeachers ? '#1B252F' : '#292929'
                              }}
                            >
                              {selectedTeachers ? selectedTeachers.first_name : "Select Teacher"}</span>}
                          style={{ width: '100%' }}
                        >
                          {teachersData.map(item => (
                            <Dropdown.Item
                              // className={selectedTeachers && selectedTeachers.id === item.id ? "dd_select" : null}
                              className={`${selectedTeachers && selectedTeachers.id === item.id ? "selected-item dd_select" : ""
                                }`}
                              icon={selectedTeachers && selectedTeachers.id === item.id ? HiCheck : null}
                              onClick={() => setTeacher(item.id)}
                              key={item.id}
                            >
                              {item.first_name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown>

                      </div>
                      {programeTeacherError && <p className="error pos_abo_stu">{programeTeacherError}</p>}
                    </div>
                    <div className="field-label half-field error_mes_cus extra_date_drop">
                      <label>Class Timing</label>
                      <div className="flexc justifySp">
                        <div className="half-field">
                          <div className="field-value-holder margin_top10p dropbutton cus_drop_siz" >
                            <Dropdown
                              label={


                                <span
                                  className={`${selectedStartTime ? 'selected-item' : ''}`}
                                  style={{
                                    fontSize: "12px",
                                    justifyContent: "flex-start",
                                    color: selectedStartTime ? '#1B252F' : '#292929'
                                  }}
                                >

                                  {/* <span style={{ color: 'black', fontSize: '12px' }}> */}



                                  <i class="fa-regular fa-clock" style={{ marginRight: '6PX' }}></i>
                                  {selectedStartTime ? selectedStartTime.time_name : "Start Time"}</span>}
                              style={{
                                width: "100%"
                              }}
                            >
                              {timeData.map(item => (
                                <Dropdown.Item

                                  //className={selectedStartTime && selectedStartTime.id === item.id ? "dd_select" : null}

                                  className={`${selectedStartTime && selectedStartTime.id === item.id ? "selected-item dd_select" : ""
                                    }`}
                                  icon={selectedStartTime && selectedStartTime.id === item.id ? HiCheck : null}
                                  onClick={() => setProgramStartTime(item.id)}
                                  key={item.id}
                                >
                                  {item.time_name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown>
                          </div>
                        </div>
                        <div className="half-field">
                          <div className="field-value-holder margin_top10p dropbutton cus_drop_siz" >
                            <Dropdown
                              label={


                                //  <span style={{ color: 'black', fontSize: '12px' }}>

                                <span
                                  className={`${selectedEndTime ? 'selected-item' : ''}`}
                                  style={{


                                    fontSize: "13px",
                                    fontWeight: "400",
                                    lineHeight: "18.2px",
                                    textAlign: "left",

                                    justifyContent: "flex-start",
                                    color: selectedEndTime ? '#1B252F' : '#292929'
                                  }}
                                >



                                  <i class="fa-regular fa-clock" style={{ marginRight: '6PX' }}>
                                  </i>{selectedEndTime ? selectedEndTime.time_name : "End Time"}</span>}
                              style={{
                                width: "100%"
                              }}
                            >
                              {timeData.map(item => (
                                <Dropdown.Item
                                  // className={selectedEndTime && selectedEndTime.id === item.id ? "dd_select" : null}
                                  className={`${selectedEndTime && selectedEndTime.id === item.id ? "selected-item dd_select" : ""
                                    }`}

                                  icon={selectedEndTime && selectedEndTime.id === item.id ? HiCheck : null}
                                  onClick={() => setProgramEndTime(item.id)}
                                  key={item.id}
                                >
                                  {item.time_name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                      {timeError && <p className="error">{timeError}</p>}
                    </div>
                  </div>

                  <h3 className="ft-wt600 mt-30">Customise Features</h3>
                  <div className="full-field mb-6">
                    {Object.keys(features).map((feature) => (
                      <div className="flex items-center mb-4 custom-checkbox dis_chek_cus mt-5" key={feature}>
                        <input
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 rounded"
                          checked={features[feature].status}

                          disabled={
                            (['qrCode', 'attendance', 'food', 'sleep', 'leaves'].includes(feature))
                            // features[feature].status=== true
                          }
                          onChange={() => handleFeatureChange(feature)}
                        />
                        <label className="ms-2 text-sm">{features[feature].name}</label>
                        {['busTracking', 'cctv', 'fees'].includes(feature) && <img src={SplImg} alt="spl" style={{ width: '25px', height: '15px', marginLeft: "5px" }} />}
                      </div>
                    ))}
                  </div>
                  <h3 className="ft-wt600 mt-30">Additional Informations</h3>
                  <div className="full-field">
                    {Object.keys(additionalInfo).map((info) => (
                      <div className="flex items-center mb-4 custom-checkbox mt-5" key={info}>
                        <input
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 rounded"
                          checked={additionalInfo[info].status}
                          onChange={() => handleAdditionalInfoChange(info)}
                        />
                        <label className="ms-2 text-sm">{additionalInfo[info].name}</label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="sp-bottom-sticky">
                  <button className="sp-btn-create crt_div" onClick={() => handleCreateProgram()}>Create</button>
                  <button className="sp-btn-cancel">Cancel</button>
                </div>
              </div>
            </div>
          )
        }
      </div>
      {/* 12-09-2024 start */}
      <Sidebar
        setAppliedLeaveStatus={handleAppliedLeaveStatus} />
      {/* 12-09-2024 end */}
    </section>
  );
}
