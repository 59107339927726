
const alternatingColors = ["#E58080", "#FC9612", "#24B16D", "#3D86F2", "#8E80E5", "#6175E9"];

export function LogoComponent({name,index,bgColor}) {
    return <span className="text-xsmall logo-component"
        // style={{ backgroundColor: alternatingColors[index % 6] }} >
        style={{ backgroundColor: bgColor }} >
        {name.slice(0, 1).toUpperCase()}
    </span>
}

export function PreviewLogoComponent({name,index,style,bgColor,type}) {
    return <span className={`text-xsmall ${style}`}
        // style={{ backgroundColor: alternatingColors[index % 6] }} >
        style={{ backgroundColor:type==="parent"?alternatingColors[index % 6]:bgColor}} >
        {name.slice(0, 1).toUpperCase()}
    </span>
}

export function TeacherLogoComponent({name,index,style,bgColor}) {
    return <span className={`text-xsmall ${style}`}
        // style={{ backgroundColor: alternatingColors[index % 6] }} >
        style={{ backgroundColor:bgColor}} >
        {name.slice(0, 1).toUpperCase()}
    </span>
}




